






























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { Watch } from 'vue-property-decorator';
import Endpoint from '@/utils/endpoint';

import { AuthService } from '../../services/authService';
import SolicitationId from '@/@types/solicitationId';
import FeedbackInterface from '@/@types/feedback';

import ErrorResponse from '@/@types/ErrorResponse';

import qs from 'qs';

const namespace = 'add';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({})
export default class modalAddStatement extends Vue {
  @Getter('getSolicitationId', { namespace: 'auth' })
  public solicitation!: SolicitationId;

  @Getter('getModalAddStatement', { namespace: 'auth' })
  public modalAddStatement!: boolean;
  @Action('setModalAddStatement', { namespace: 'auth' })
  public setModalAddStatement!: any;

  @Getter('getFeedback', { namespace: 'auth' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'auth' }) public setFeedback: any;

  @Getter('getUserLanguage', { namespace: 'auth' }) public userLanguage!: any;
  @Action('setUserLanguage', { namespace: 'auth' }) public setUserLanguage: any;

  @Watch('currentFile')
  public getVoucherName() {
    this.setVoucherFileName();
  }

  loading = false;
  currentFile: Blob = new Blob();
  vouchername = '';

  private endpoint = new Endpoint();
  authService = new AuthService();

  public setPaymentVoucher() {
    const formData = new FormData();

    formData.append('file', this.currentFile);

    return formData;
  }

  public mounted() {
    this.currentFile = new Blob();
  }

  public setVoucherFileName() {
    // @ts-ignore
    const fileInput = document.querySelector('#payment-voucher')?.value;
    const fileName = fileInput.split(/(\\|\/)/g).pop();

    this.vouchername = fileName;
  }

  async addStatement() {
    const formData =
      this.currentFile.size !== 0 ? this.setPaymentVoucher() : null;
    const token = await this.authService.getTokenForMenuAsync();
    try {
      this.loading = true;
      await this.$http.post(
        this.endpoint.solicitation + `/${this.solicitation}/pay`,
        formData,
        {
          headers: {
            Authorization: token,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      this.loading = false;
      this.closeModal();

      this.setFeedback({
        feedback: true,
        color: 'green',
        text:
          this.userLanguage === 'en'
            ? 'Attached voucher'
            : 'Comprovante anexado',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);

      this.$router.push({ path: '/requests' });
    } catch (error) {
      const errorData = error as ErrorResponse;
      this.loading = false;
      console.error(error);
      this.closeModal();

      this.setFeedback({
        feedback: true,
        color: 'red',
        text:
          errorData.response.status !== 400 ||
          errorData.response.data ===
            'Object reference not set to an instance of an object'
            ? 'Ocorreu um erro'
            : `${errorData.response.data}`,
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    }
    this.vouchername = '';
  }

  closeModal(): void {
    this.setModalAddStatement(false);
  }
}
