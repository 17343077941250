import Header from "@/@types/header";
export default class SolcitationsHeader {
  public static header: Header[] = [
    {
      text: "Sienge",
      align: "start",
      sortable: false,
      value: "siengeValue",
      class: "item-table-header item-table-header-solicitations",
    },
    {
      text: "Id",
      align: "start",
      sortable: false,
      value: "id",
      class: "item-table-header item-table-header-solicitations",
    },
    {
      text: "Payment Company",
      align: "start",
      sortable: true,
      value: "companyName",
      class: "item-table-header item-table-header-solicitations",
    },
    {
      text: "Issuing",
      align: "start",
      sortable: true,
      value: "emissionDate",
      class: "item-table-header item-table-header-solicitations",
    },
    {
      text: "Expiration",
      align: "start",
      sortable: true,
      value: "expirationDate",
      class: "item-table-header item-table-header-solicitations",
    },
    {
      text: "Creditor",
      align: "start",
      sortable: true,
      value: "creditorName",
      class: "item-table-header item-table-header-solicitations",
    },
    {
      text: "Requester",
      align: "start",
      sortable: true,
      value: "requesterName",
      class: "item-table-header item-table-header-solicitations",
    },
    {
      text: "Amount",
      align: "start",
      sortable: true,
      value: "value",
      class: "item-table-header item-table-header-solicitations",
    },
    {
      text: "Source",
      align: "center",
      sortable: false,
      value: "source",
      class: "item-table-header item-table-header-solicitations",
    },
    {
      text: "Status",
      align: "start",
      sortable: true,
      value: "status",
      class: "item-table-header item-table-header-solicitations",
    },
  ];
}
