export default interface companiesNew {
    name: string;
    cnpj: string;
    corporativeId: null;
    fundAdministratorId: number;
    enabled: boolean;
    requiresSecondApprover: boolean;
    requiredApprovers: number;
}

const companiesNewInitialValue: companiesNew = { 
    name: '',
    cnpj: '',
    corporativeId: null,
    fundAdministratorId: 0,
    enabled: false,
    requiresSecondApprover: false,
    requiredApprovers: 1
}

export { companiesNewInitialValue };
