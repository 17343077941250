import axios from 'axios';

export const setErrorText = (error: unknown): string => {
  if (!axios.isAxiosError(error))
    return 'Unexpected error encountered';
  const errors = error.response?.data.errors;
  if (!errors || errors.length === 0)
    return '';
  
  let resultError = '';
  for (const error of errors as string[]) {            
    resultError += parseBackendErrorString(error) + '\n';
  }
  return resultError;
}

export const parseBackendErrorList = (errorList: string[]): string => {
  let resultError = '';
  for (const error of errorList) {            
    resultError += error + '\n';
  }
  return resultError;
}

const parseBackendErrorString = (error: string): string => {
  if (error.includes('|')) {
    const errorData = error.split('|');
    if (errorData.length === 3)
      return errorData[2];        
    
      return errorData[1];
  }
  return error;
}