export default interface fundAdministrator {
  id: number;
  name: string;
  shortName: string;
  identifier: string;
  contactEmail: string;
  externalAdm: boolean;
  businessUnity: string; 
  mailTemplatePt: string;
  mailGroupLeste: string;
  mailTemplateEn: string;
}

const fundAdministratorInitialValue: fundAdministrator = {
  id: 0,
  name: '',
  shortName: '',
  identifier: '',
  contactEmail: '',
  externalAdm: true,
  businessUnity: '',
  mailGroupLeste: '',
  mailTemplatePt: '',
  mailTemplateEn: '',
};

export { fundAdministratorInitialValue };
