

























































































































































































































import ErrorResponse from '@/@types/ErrorResponse';
import Company from '@/@types/companies';
import FeedbackInterface from '@/@types/feedback';
import FundAdministrator, {
fundAdministratorInitialValue,
} from '@/@types/fundAdministrator';
import Header from '@/@types/header';
import FundAdministratorHeader from '@/components/headers/fundAdministrator-header';
import FundAdministratorHeaderPT from '@/components/headers/pt-br/fundAdministrator-header-pt';
import Endpoint from '@/utils/endpoint';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AuthService } from '../../services/authService';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({})
export default class Companies extends Vue {
  @Prop(String) currentLanguage: string = 'en';
  @Prop(String) readonly id: string | undefined;

  @Getter('getUserLanguage', { namespace: 'auth' }) userLanguage!: any;
  @Action('setUserLanguage', { namespace: 'auth' }) setUserLanguage: any;

  @Getter('getFeedback', { namespace: 'auth' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'auth' }) public setFeedback: any;

  @Getter('getCompaniesData', { namespace: 'auth' }) companiesData!: any;
  @Action('setCompaniesData', { namespace: 'auth' }) setCompaniesData: any;

  headers: Header[] = FundAdministratorHeader.header; //idioma
  headersPT: Header[] = FundAdministratorHeaderPT.header; //idioma
  administratorListCompanies: FundAdministrator[] = [];
  fundId: FundAdministrator = fundAdministratorInitialValue;
  search = null;
  loading = false;
  loadingData = false;
  isEditCompanies = false;
  nameCompanies = '';
  shortNameFundAdministrator = '';
  emailGroupFundPayment = '';
  identifierCompanies = '';
  siengeId: number | null = null;
  fundAdministratorSelected: FundAdministrator = fundAdministratorInitialValue;
  requiresSecondApprover = false;
  requiredApprovers = 1;
  private endpoint = new Endpoint();
  authService = new AuthService();

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  async mounted() {
    this.loading = true;
    await this.getFundAdministrator();
    if (this.$route.name === 'EditCompanies') {
      this.setCompaniesItems(this.companiesData);
      this.isEditCompanies = true;
    }
    this.loading = false;
  }  

  public async createCompany() {
    if (!this.form.validate()) {
      return;
    }    
    this.loadingData = true;
    const params = {
      name: this.nameCompanies,
      cnpj: this.identifierCompanies,
      corporativeId: null,
      fundAdministratorId: this.fundAdministratorSelected.id,
      enabled: true,
      requiresSecondApprover: this.requiresSecondApprover,
      requiredApprovers: this.requiredApprovers
    };
    try {
      const token = await this.authService.getTokenForMenuAsync();
      this.$http.post(this.endpoint.companiesNew, params, {
        headers: {
          Authorization: token,
        },
      });
    } catch (error) {
      const errorData = error as ErrorResponse;
      console.log('Error no put do companies');
      this.setFeedback({
        feedback: true,
        color: 'red',
        text:
          errorData.response.status === 400
            ? `${errorData.response.data}`
            : 'Ocorreu um erro',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 4000);
    } finally {
      this.loadingData = false;
      this.back();
    }
  }

  public async editCompany() {    
    if (!this.form.validate()) {
      return;
    }    
    this.loadingData = true;
    const params = {
      id: this.companiesData.id,
      name: this.nameCompanies,
      cnpj: this.identifierCompanies,
      corporativeId: null,
      mailGroupFundPayment:
        this.emailGroupFundPayment !== '' ? this.emailGroupFundPayment : null,
      fundAdministratorId: this.fundAdministratorSelected?.id ?? 0,
      enabled: true,
      requiresSecondApprover: this.requiresSecondApprover,
      requiredApprovers: this.requiredApprovers
    };
    try {
      const token = await this.authService.getTokenForMenuAsync();
      await this.$http.put(this.endpoint.companiesNew, params, {
        headers: {
          Authorization: token,
        },
      });
      this.setFeedback({
        feedback: true,
        color: 'green',
        text: this.userLanguage == 'en' ?
          'Company updated successfully'
          : 'Empresa editada com sucesso',
      });
    } catch (error) {
      const errorData = error as ErrorResponse;      
      this.setFeedback({
        feedback: true,
        color: 'red',
        text:
          errorData.response.status === 400
            ? `${errorData.response.data}`
            : 'Ocorreu um erro',
      });
      
    } finally {
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 8000);
      this.loadingData = false;
      this.back();
    }
  }

  public async setSavePostOrPutCompanies() {
    if (this.isEditCompanies) {
      await this.editCompany();
    } else {
      await this.createCompany();
    }
  }

  setCompaniesItems(item: Company) {
    this.loading = true;
    this.nameCompanies = item.name;
    this.identifierCompanies = item.cnpj;
    this.fundAdministratorSelected = this.administratorListCompanies.find(
      x => x.id === item.fundAdministratorId
    ) as FundAdministrator;
    this.emailGroupFundPayment = item.mailGroupFundPayment;
    this.requiresSecondApprover = item.requiresSecondApprover;
    this.requiredApprovers = item.requiredApprovers;
    this.siengeId = item.siengeId;
    this.loading = false;    
  }

  public isSiengeCompany(): boolean {
    const isSienge = this.siengeId !== null;     
    return isSienge;
  }

  public async getFundAdministrator() {
    try {
      const token = await this.authService.getTokenForMenuAsync();
      const response = await this.$http.get(this.endpoint.fundAdministrators, {
        headers: { Authorization: token },
      });
      this.administratorListCompanies = response;
    } catch (error) {
      console.log(this.administratorListCompanies);
    }
  }

  back() {
    this.$router.replace({
      path: '/companies',
    });
  }

  getFundAdministratorSelectText(fundAdministrator: FundAdministrator){
      return `${fundAdministrator.name} - ${fundAdministrator.shortName}`;
  }
}
