export default class NewCreditor {
  siengeValue: number = 0;
  data: CreditorData = creditorDataInitialvalue;
}

interface CreditorData {
  account: string;
  agency: string;
  agencyDigit: string;
  accountDigit: string;
  bank: string;
  cpfCnpj: string;
  id: number;
  name: string;
  disabled?: boolean;
  siengeValue: number;
}

const creditorDataInitialvalue: CreditorData = {
  account: '',
  agency: '',
  agencyDigit: '',
  accountDigit: '',
  bank: '',
  cpfCnpj: '',
  id: 0,
  name: '',
  disabled: false,
  siengeValue: 0,
};
