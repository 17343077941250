




















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import Endpoint from '@/utils/endpoint';

import { AuthService } from '../../services/authService';
import SolicitationId from '@/@types/solicitationId';
import FeedbackInterface from '@/@types/feedback';

import ErrorResponse from '@/@types/ErrorResponse';

import qs from 'qs';

const namespace = 'add';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({})
export default class modalDeny extends Vue {
  @Getter('getSolicitationId', { namespace: 'auth' })
  public solicitation!: SolicitationId;

  @Getter('getModalPending', { namespace: 'auth' })
  public modalPending!: boolean;
  @Action('setModalPending', { namespace: 'auth' })
  public setModalPending!: any;

  @Getter('getStatusPending', { namespace: 'auth' })
  public statusPending!: boolean;
  @Action('setStatusPending', { namespace: 'auth' })
  public setStatusPending!: any;

  @Getter('getFeedback', { namespace: 'auth' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'auth' }) public setFeedback: any;

  @Getter('getUserLanguage', { namespace: 'auth' }) public userLanguage!: any;
  @Action('setUserLanguage', { namespace: 'auth' }) public setUserLanguage: any;

  @Prop(Boolean) loading: boolean | undefined;

  currentFile: Blob = new Blob();
  messagePending = '';
  loadingPending = false;

  private endpoint = new Endpoint();
  authService = new AuthService();

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  async pendingSolicitation() {
    if (!this.form.validate()) {
      return;
    }
    if (this.$route.name === 'EditRequest') {
      this.$emit('pendingSolicitation', this.messagePending);
      return;
    }
    const token = await this.authService.getTokenForMenuAsync();
    try {
      this.loadingPending = true;
      const params = {
        message: this.messagePending,
      };
      await this.$http.put(
        this.endpoint.setPendency + `/${this.solicitation}`,
        params,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      this.loadingPending = false;
      this.closeModal();

      this.setFeedback({
        feedback: true,
        color: 'green',
        text:
          this.userLanguage === 'en'
            ? 'Payment confirmed'
            : 'Pagamento confirmado',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);

      this.$router.push({ path: '/requests' });
    } catch (error) {
      const errorData = error as ErrorResponse;
      this.loadingPending = false;
      console.error(error);
      this.closeModal();

      this.setFeedback({
        feedback: true,
        color: 'red',
        text:
          errorData.response.status !== 400 ||
          errorData.response.data ===
            'Object reference not set to an instance of an object'
            ? 'Ocorreu um erro'
            : `${errorData.response.data}`,
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    }
  }

  closeModal(): void {
    this.setModalPending(false);
  }
}
