import Vue from 'vue';
import Vuex from 'vuex';
import auth from '../vuex/auth';
import statement from '../vuex/statement';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    statement,
  },
});
