export default interface CreditorSelected {
  account: string;
  agency: string;
  agencyDigit: string;
  accountDigit: string;
  bank: string;
  cpfCnpj: string;
  id: number;
  name: string;
  disabled?: boolean;
}

const creditorSelectedInitalValue: CreditorSelected = {
  name: "",
  cpfCnpj: "",
  bank: "",
  agency: "",
  account: "",
  agencyDigit: "",
  accountDigit: "",
  id: 0,
};

export { creditorSelectedInitalValue };
