














































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { Watch, Prop } from 'vue-property-decorator';
import Endpoint from '../../utils/endpoint';
import Header from '@/@types/header';
import CreditorHeader from '@/components/headers/creditors-header';
import CreditorHeaderPT from '@/components/headers/pt-br/creditors-header-pt';
import creditor from '@/@types/creditors';

import { AuthService } from '@/services/authService';

@Component({})
export default class Requests extends Vue {
  @Prop(String) currentLanguage: string = 'en';

  @Getter('getUserLanguage', { namespace: 'auth' }) public userLanguage!: any;
  @Action('setUserLanguage', { namespace: 'auth' }) public setUserLanguage: any;

  @Getter('getModalNewCreditor', { namespace: 'auth' })
  public modalNewCreditor!: boolean;
  @Action('setModalNewCreditor', { namespace: 'auth' })
  public setModalNewCreditor!: any;

  @Getter('getCreditorData', { namespace: 'auth' }) creditorData!: any;
  @Action('setCreditorData', { namespace: 'auth' }) setCreditorData: any;

  headers: Header[] = CreditorHeader.header; //idioma
  headersPT: Header[] = CreditorHeaderPT.header; //idioma
  items: creditor[] = [];
  search = null;
  loading = false;
  selectedItem = false;

  private endpoint = new Endpoint();
  authService = new AuthService();

  public async getCreditores() {
    const token = await this.authService.getTokenForMenuAsync();
    this.loading = true;
    const response = await this.$http.get(this.endpoint.creditor, {
      headers: { Authorization: token },
    });
    const items = response;
    this.items = items;
    this.loading = false;
  }

  public openNewCreditorModal() {
    this.setModalNewCreditor(true);
  }

  public mounted() {
    this.getCreditores();
  }

  public editCompaniesFundAdministrator(item: creditor) {
    this.setCreditorData(item);
    this.$router.push({
      name: `EditCreditors`,
      params: { id: item.id.toString() },
    });
  }

  public formatDataCpfCnpj(item: string) {
    if (!item?.includes('.')) {
      var cpfValido = /^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}))$/;
      if (cpfValido.test(item) == false) {
        item = item?.replace(/\D/g, ''); //Remove tudo o que não é dígito

        if (item?.length == 11) {
          item = item?.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
          item = item?.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
          //de novo (para o segundo bloco de números)
          const cpf = item?.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos
          return cpf;
        }
      }

      if (item?.length == 14) {
        const cnpj = item?.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          '$1.$2.$3/$4-$5'
        );
        return cnpj;
      }
    } else {
      return item;
    }
  }

  public selectItem(item: boolean) {
    this.selectedItem = item;
  }

  public unSelectItem(item: object) {
    this.selectedItem = false;
  }
}
