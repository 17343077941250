const grantType = process.env.VUE_APP_GRANT_TYPE;
const clientSecret = process.env.VUE_APP_CLIENT_SECRET;
const clientId = process.env.VUE_APP_CLIENT_ID;
const scope = process.env.VUE_APP_SCOPE;

interface CredentialModel {
  grantType: string;
  clientSecret: string;
  clientId: string;
  scope: string;
}
//
export default class Credential {
  public credential: CredentialModel = {
    grantType,
    clientSecret,
    clientId,
    scope,
  };
}
