





























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import User from "@/@types/auth/user";
import FinancialBudget from "@/@types/financialPlain";
import { JsonFeedback as JsonFeedbackInterface } from "@/@types/jsonFeedback";
import CostCenters from "@/@types/paymentRequest/costCenters";
import { PaymentStatusNumber } from "@/@types/paymentRequest/solicitation";
import SolicitationArchives from "@/@types/solicitation-archives";
import SolicitationId from "@/@types/solicitationId";
import SolicitationInterface from "@/@types/solicitations";
import SpecificPayment from "@/@types/specificPayment";
import StatementPaymentInterface, {
  statementPaymentInitialValue,
} from "@/@types/statement/statementPayment";
import JsonFeedback from "@/components/actions/JsonFeedback.vue";
import Endpoint from "@/utils/endpoint";
import formatDate from "@/utils/formatDate";
import formatMoney from "@/utils/formatMoney";
import { AxiosInstance } from "axios";
import moment from "moment";
import qs from "qs";
import VueSkeletonLoader from "skeleton-loader-vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { AuthService } from "../../services/authService";

const ALL_STATUS = 0;
const REQUESTED = 1;
const APPROVED = 2;
const DENIED = 3;
const EXPORTED = 4;
const EXPORTED_ERROR = 5;
const DRAFT = 6;
const CANCELLED = 7;
const PAID = 8;
const EMERGENCY_PENDING = 9;

@Component({
  components: { VueSkeletonLoader, JsonFeedback },
})
export default class InnerRequest extends Vue {
  @Getter("getSolicitationId", { namespace: "auth" })
  public solicitation!: SolicitationId;
  @Action("setSolicitationId", { namespace: "auth" })
  public setSolicitationId!: any;

  @Getter("getModalApprove", { namespace: "auth" })
  public modalApprove!: boolean;
  @Action("setModalApprove", { namespace: "auth" })
  public setModalApprove!: any;

  @Action("setModalDeny", { namespace: "auth" }) public setModalDeny!: any;
  @Getter("getModalDeny", { namespace: "auth" }) public modalDeny!: boolean;

  @Getter("getModalPay", { namespace: "auth" }) public modalPay!: boolean;
  @Action("setModalPay", { namespace: "auth" }) public setModalPay!: any;

  @Getter("getModalExported", { namespace: "auth" })
  public modalExported!: boolean;
  @Action("setModalExported", { namespace: "auth" })
  public setModalExported!: any;

  @Getter("getModalPending", { namespace: "auth" })
  public modalPending!: boolean;
  @Action("setModalPending", { namespace: "auth" })
  public setModalPending!: any;

  @Getter("getStatusPending", { namespace: "auth" })
  public statusPending!: boolean;
  @Action("setStatusPending", { namespace: "auth" })
  public setStatusPending!: any;

  @Getter("getModalAddStatement", { namespace: "auth" })
  public modalAddStatement!: boolean;
  @Action("setModalAddStatement", { namespace: "auth" })
  public setModalAddStatement!: any;

  @Getter("getModalExtendExpiration", { namespace: "auth" })
  public modalExtendExpiration!: boolean;
  @Action("setModalExtendExpiration", { namespace: "auth" })
  public setModalExtendExpiration!: any;

  @Getter("getModalCancel", { namespace: "auth" }) public modalCancel!: boolean;
  @Action("setModalCancel", { namespace: "auth" }) public setModalCancel!: any;

  @Action("setModalBankApproval", { namespace: "auth" })
  public setModalBankApproval!: any;

  @Getter("getUserLanguage", { namespace: "auth" }) userLanguage!: "en" | "pt";
  @Action("setUserLanguage", { namespace: "auth" }) setUserLanguage: any;

  @Getter("getStatementPayment", { namespace: "statement" })
  statementPayment!: StatementPaymentInterface;
  @Action("setStatementPayment", { namespace: "statement" })
  public setStatementPayment!: (
    statementPayment: StatementPaymentInterface,
  ) => void;

  @Action("getStatementPaymentByRequestId", { namespace: "statement" })
  public getStatementPaymentByRequestId!: (data: {
    paymentRequestId: number;
    http: AxiosInstance;
  }) => Promise<StatementPaymentInterface>;
  @Action("generateReceiptForPayment", { namespace: "statement" })
  public generateReceiptForPayment!: (data: {
    paymentRequestId: number;
    http: AxiosInstance;
  }) => Promise<string>;

  @Getter("getJsonFeedback", { namespace: "auth" })
  public jsonFeedback!: JsonFeedbackInterface;
  @Action("setJsonFeedback", { namespace: "auth" }) public setJsonFeedback: any;

  @Prop(Number) readonly id: number | undefined;

  @Getter("getUser", { namespace: "auth" }) user!: User;

  showFilters = false;
  downloadErrorFile = false;

  statusItems = ["Todos os status"];

  valor = "";

  errorMessage = "error";

  request = {} as SpecificPayment;
  showSkeleton = true;
  showDuplicate = false;
  showAprove = false;
  showPayed = false;
  paydFile: SolicitationArchives = new SolicitationArchives();
  filesData: SolicitationArchives[] = [];
  base64: string = "";
  handleArchiveIsDownloaded = "";
  handleBlockDownload = false;
  financialBudget = [];
  costCenters: CostCenters[] = [];
  downloadNameUrlPendency = "";

  formatDate = formatDate;
  formatMoney = formatMoney;
  approvers: { name: string; id: number; hasApproved: boolean }[] = [];

  allApproversHasApproverd: boolean = false;

  public verifyAllApprovers() {
    this.allApproversHasApproverd = this.request.approvers.every(
      ({ hasApproved }) => {
        return hasApproved;
      },
    );
  }

  statusBySettings: any = {
    hasApproved: {
      false: { bgColor: "#f2aa35", icon: "mdi-close-circle" },
      true: { bgColor: "grey-5", icon: "mdi-check-circle" },
    },
    isEmergency: {
      false: { bgColor: "#FF3D00", icon: "mdi-car-brake-alert" },
      true: { bgColor: "#FF3D00", icon: "mdi-car-brake-alert" },
    },
    isCompliance: {
      false: { bgColor: "#F57F17", icon: "mdi-shield-check-outline" },
      true: { bgColor: "#F57F17", icon: "mdi-shield-check-outline" },
    },
    statusId: {
      3: { bgColor: "#D32F2F", icon: "mdi-close-circle" },
      2: { bgColor: "#12a339", icon: "mdi-check-circle" },
      1: { bgColor: "#f2aa35", icon: "mdi-alert-circle-outline" },
    },
  };

  sourceBy: any = {
    Vendor: {
      img: "Vendor_Logo",
      label: "Vendor",
    },
    Wire: {
      img: "IlliquidsLogo",
      label: "Wire",
    },
    Finti: {
      img: "FintiLogo",
      label: "Finti",
    },
  };

  public getUrlFor(imgName: string): any {
    var images = require.context("../../assets", false, /\.svg$/);
    return images("./" + imgName + ".svg");
  }
  private endpoint = new Endpoint();
  authService = new AuthService();
  public setStatusClass(item: number) {
    switch (item) {
      case 1:
        return "requested";
      case 2:
        return "aproved";
      case 3:
        return "denied";
      case 4:
        return "exported";
      case 5:
        return "fault";
      case 6:
        return "draft";
      case 7:
        return "draft";
      case 8:
        return "payed";
      case 9:
        return "fault";
      case 10:
        return "pending";
      case 12:
        return "bankApproval";
      case 13:
        return "bankApproval";
      default:
        return "draft";
    }
  }
  public statusItemsData = {
    en: {
      1: "Requested",
      2: "Approved",
      3: "Denied",
      4: "Exported",
      5: "Error",
      6: "Draft",
      7: "Canceled",
      8: "Paid",
      9: "Emergency Pending",
      10: "Pending",
      12: "Bank Approval",
      13: "Compliance Approval",
    },
    pt: {
      1: "Solicitada",
      2: "Aprovada",
      3: "Negada",
      4: "Exportada",
      5: "Erro",
      6: "Rascunho",
      7: "Cancelada",
      8: "Paga",
      9: "Pendente Emergencial",
      10: "Pendência",
      12: "Aprovação Bancária",
      13: "Aprovação Compliance",
    },
  };

  types: any = {
    txt: { icon: "mdi-text-box", color: "blue-grey lighten-1" },
    pdf: { icon: "mdi-file-pdf-box", color: "red darken-3" },
    docx: { icon: "mdi-file-word", color: "cyan darken-2" },
    xlsx: { icon: "mdi-file-excel", color: "green darken-1" },
    csv: { icon: "mdi-file-excel", color: "green darken-1" },
    pptx: { icon: "mdi-file-word", color: "green darken-1" },
    key: { icon: "mdi-file-key", color: "yellow accent-2" },
    png: { icon: "mdi-file-png-box", color: "blue" },
    jpeg: { icon: "mdi-file-jpg-box", color: "blue" },
    default: { icon: "mdi-file-image", color: "light-blue " },
  };

  mappedFiles(file: any) {
    const type =
      this.types[file.fileName.split(".")[1]] || this.types["default"];

    return {
      name: file.fileName,
      icon: type.icon,
      color: type.color,
    };

    // return file.map((obj: any) => {
    //   const icon = this.types[obj.name.split(".")[1]] || this.types["default"];
    //   console.log("icon", icon);
    //   // return {
    //   //   name: obj.fileName,
    //   //   icon,
    //   // };
    // });
  }
  async getRequestByid() {
    this.showSkeleton = true;
    try {
      const token = await this.authService.getTokenForMenuAsync();
      const response = await this.$http.get(
        this.endpoint.solicitation + `/${this.id}`,
        {
          headers: { Authorization: token },
        },
      );
      this.request = response;
      this.costCenters = response.costCenters;

      if (response.statement.fileName != null) {
        await this.getPaymentFile(
          response.statement.fileName,
          response.statement.urlFile,
        );
      }

      for (const x of response.attachments) {
        this.returnNewSolicitationArchive(x.fileName, x.urlFile);
      }
    } catch (error) {
      console.error(error);
    }
    this.showSkeleton = false;
  }

  public async returnNewSolicitationArchive(fileName: string, urlFile: string) {
    const solicitationArquive = new SolicitationArchives();
    solicitationArquive.fileName = fileName;
    solicitationArquive.urlFile = urlFile;
    this.filesData.push(solicitationArquive);
  }

  public async downloadFile(urlFile: string, fileName: string) {
    this.handleArchiveIsDownloaded = fileName;
    this.handleBlockDownload = true;
    const response = await this.$http.get(this.endpoint.downloadFiles, {
      params: {
        urlFile: urlFile,
      },
      paramsSerializer: (params) => qs.stringify(params),
    });
    this.handleArchiveIsDownloaded = "";
    this.handleBlockDownload = false;
    const base64Value = response as string;
    const linkSource = "data:application/pdf;base64," + base64Value;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  public async generateReceipt(
    paymentRequestId: number,
    bankAuthentication: string,
  ) {
    this.handleBlockDownload = true;
    const data = {
      paymentRequestId: paymentRequestId,
      http: this.$http,
    };
    const response = await this.generateReceiptForPayment(data);
    this.handleBlockDownload = false;
    const base64Value = response;
    const linkSource = "data:application/pdf;base64," + base64Value;
    console.log("generated...");
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = `receipt-${bankAuthentication}.pdf`;
    downloadLink.click();
  }

  public async getPaymentFile(fileName: string, urlFile: string) {
    try {
      const solicitationArquive = new SolicitationArchives();
      solicitationArquive.fileName = fileName;
      solicitationArquive.urlFile = urlFile;
      // const response = await this.$http.get(this.endpoint.downloadFiles, {
      //   params: {
      //     urlFile: urlFile,
      //   },
      //   paramsSerializer: params => qs.stringify(params),
      // });
      // solicitationArquive.base64 = response as string;
      this.paydFile = solicitationArquive;
      // this.filesData = solicitationArquive;
    } catch (error) {
      this.downloadErrorFile = true;
    }
  }

  public getNameAttachmentUrl(item: string) {
    if (item !== null) {
      const response = item.split("/");
      const nameAttachment = response.splice(-1).toString();
      const nameUrl = nameAttachment.substring(
        this.id!.toString().length + 1,
        item.length,
      );
      this.downloadNameUrlPendency = nameUrl;
      return nameUrl;
    }
  }

  public async downloadFilePendency(urlFile: string, fileName: string) {
    this.handleArchiveIsDownloaded = urlFile;
    this.handleBlockDownload = true;
    const response = await this.$http.get(this.endpoint.downloadFiles, {
      params: {
        urlFile: urlFile,
      },
      paramsSerializer: (params) => qs.stringify(params),
    });
    this.handleArchiveIsDownloaded = "";
    this.handleBlockDownload = false;
    const base64Value = response as string;
    const linkSource = "data:application/*;base64," + base64Value;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  async mounted() {
    try {
      await this.getRequestByid();
      const paymentRequest = this.request as any;
      const data = {
        paymentRequestId: paymentRequest.id,
        http: this.$http,
      };
      const statementPayment = await this.getStatementPaymentByRequestId(data);
      this.setStatementPayment(statementPayment);
    } catch (e) {
      this.setStatementPayment(statementPaymentInitialValue);
    }
    this.verifyAllApprovers();
  }

  setStatusData(item: PaymentStatusNumber): string | undefined {
    return this.statusItemsData[this.userLanguage][item];
  }

  showActions(status: number): any {
    if (status === 5 || status === 6 || status === 7 || status === 8) {
      this.showDuplicate = true;
    }
    if ((status = 4)) {
      this.showPayed = true;
    }
    if ((status = 1)) {
      this.showAprove = true;
    }
  }

  setCurrency(value: any) {
    if (value === "R$   " || value === "BRL  ") {
      return "Real";
    }
    if (
      value === "$    " ||
      value === "usa  " ||
      value === "uad  " ||
      value === "USD  " ||
      value === "USD$ " ||
      value === "US   " ||
      value === "U$   " ||
      value === "US$  "
    ) {
      return "Dólar";
    }
    if (
      value === "C$   " ||
      value === "cad$ " ||
      value === "CAD  " ||
      value === "CAD$ " ||
      value === "c$   " ||
      value === "cad  "
    ) {
      return "Canadian Dollar";
    }
    if (value === "£    " || value === "GBP  " || value === "GDP  ") {
      return "Libra";
    }
    if (value === "EUR  ") {
      return "Euro";
    }
  }

  showLogs() {
    this.setJsonFeedback({
      show: !this.jsonFeedback.show,
      color: "#013767",
      text: this.statementPayment.paymentJson,
    });
  }
  public formatMoneyByCurrency(
    value: number,
    currency: string,
  ): string | undefined {
    if (currency === "USD  ") {
      const formater = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });

      return formater.format(value);
    }

    if (currency === "BRL  ") {
      const formater = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      });

      return formater.format(value);
    }

    if (currency === "EUR  ") {
      const formater = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "EUR",
      });

      return formater.format(value);
    }

    if (currency === "GBP  ") {
      const formater = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "GBP",
      });

      return formater.format(value);
    }

    if (currency === "CAD  ") {
      const formater = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "CAD",
      });

      return formater.format(value);
    }
  }

  public handleFormatDate(date: string) {
    const dateFormatted = moment(date).format("DD/MMMM/YYYY");
    const dateSeparated = dateFormatted.split("/");
    const monthFormatted = dateSeparated[1]?.substring(0, 3);
    const dateResult =
      dateSeparated[0] + "/" + monthFormatted + "/" + dateSeparated[2];

    return dateResult;
  }

  public async getFinancialBudgets() {
    const token = await this.authService.getTokenForMenuAsync();
    const financialBudgets = (await this.$http.get(
      this.endpoint.financialPlain,
      {
        headers: { Authorization: token },
      },
    )) as FinancialBudget[];
  }

  public copy() {
    this.$router.push({ path: `/request/copy/${this.id}` });
  }

  public async denySolicitation() {
    this.setModalDeny(true);
    this.setSolicitationId(this.id);
    setTimeout(async () => {
      await this.getRequestByid();
    }, 8000);
  }

  public async cancelSolicitation() {
    this.setModalCancel(true);
    this.setSolicitationId(this.id);
  }

  public async exportedSolicitation() {
    this.setModalExported(true);
    this.setSolicitationId(this.id);
  }

  public async approveSolicitation() {
    this.setModalApprove(true);
    this.setSolicitationId(this.id);
  }

  public async paySolicitation() {
    this.setModalPay(true);
    this.setSolicitationId(this.id);
  }

  public async bankApprovalSolicitation() {
    this.setModalBankApproval(true);
    this.setSolicitationId(this.id);
  }

  public pendingSolicitation() {
    this.setModalPending(true);
    this.setSolicitationId(this.id);
  }

  public async addStatement() {
    this.setModalAddStatement(true);
    this.setSolicitationId(this.id);
  }

  public async extendSolicitation() {
    this.setModalExtendExpiration(true);
    this.setSolicitationId(this.id);
    // setTimeout(async() => {
    //   await this.getSolicitations();
    // }, 8000)
  }

  public userHasNotApproved(): boolean {
    if (this.user.name === this.request.approverName)
      return !this.request.approverHasApproved;
    if (this.user.name === this.request.secondApproverName)
      return !this.request.secondApproverHasApproved;
    const findUserAsApprover = this.request.approvers.find(
      (approver) =>
        approver.name === this.user.name ||
        approver.email.toLowerCase() === this.user.email.toLowerCase(),
    );
    if (findUserAsApprover) return !findUserAsApprover.hasApproved;
    return true;
  }

  public goBack() {
    this.$router.replace({
      path: "/requests",
    });
  }

  public editRequest(request: SolicitationInterface) {
    this.$router.push({ path: `/request/edit/${request.id}` });
  }
}
