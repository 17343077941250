export default interface Access {
  access: string;
  accessType: number;
}

const accessInitialValue: Access = {
  access: '',
  accessType: 0,
};

export { accessInitialValue };
