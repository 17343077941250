var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0",attrs:{"color":"#F3F6F9","height":"100%"}},[_c('div',{staticClass:"d-flex align-center back clickable ml-2 pt-3",on:{"click":_vm.back}},[_c('v-icon',{attrs:{"color":"#013767","size":"18"}},[_vm._v("mdi-chevron-left")]),_c('span',[_vm._v(_vm._s(_vm.$t('newSolicitation.back')))])],1),_c('v-card-title',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',{staticClass:"mb-3"},[(!_vm.isEditCompanies)?_c('span',{staticClass:"companies-title mr-3"},[_vm._v(" "+_vm._s(_vm.$t('newCompany.newTitle'))+" ")]):_c('span',{staticClass:"companies-title mr-3"},[_vm._v(" "+_vm._s(_vm.$t('newCompany.editTitle'))+" ")])]),_c('v-card',{staticClass:"elevation-0"},[_c('v-form',{ref:"form"},[_c('v-row',{staticClass:"ml-2 mr-7"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('div',[_c('label',{staticClass:"field-label",attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t('newFundAdministrator.name'))+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-0",attrs:{"size":"14","color":"#505865","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('newSolicitation.required')))])])],1),_c('v-text-field',{attrs:{"id":"name","outlined":"","dense":"","rules":[
                    _vm.nameCompanies !== '' || _vm.$t('newSolicitation.required') ],"hide-details":"auto","loading":_vm.loading,"disabled":_vm.loading || _vm.isSiengeCompany()},model:{value:(_vm.nameCompanies),callback:function ($$v) {_vm.nameCompanies=$$v},expression:"nameCompanies"}})],1)]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('div',[_c('label',{staticClass:"field-label",attrs:{"for":"identifier"}},[_vm._v(_vm._s(_vm.$t('newFundAdministrator.identifier'))+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-0",attrs:{"size":"14","color":"#505865","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('newSolicitation.required')))])])],1),_c('v-text-field',{staticClass:"mr-4",attrs:{"id":"identifier","outlined":"","dense":"","hide-details":"auto","loading":_vm.loading,"disabled":_vm.loading || _vm.isSiengeCompany()},model:{value:(_vm.identifierCompanies),callback:function ($$v) {_vm.identifierCompanies=$$v},expression:"identifierCompanies"}})],1)])],1),_c('v-row',{staticClass:"ml-2 mr-7"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('div',[_c('label',{staticClass:"field-label",attrs:{"for":"fundAdministrator"}},[_vm._v(_vm._s(_vm.$t('newCompany.fundAdministratorId'))+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-0",attrs:{"size":"14","color":"#505865","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('newSolicitation.required')))])])],1),_c('v-autocomplete',{staticClass:"mr-4",attrs:{"id":"fundAdministrator","outlined":"","dense":"","items":_vm.administratorListCompanies,"item-text":_vm.getFundAdministratorSelectText,"item-value":"id","return-object":"","hide-details":"auto","loading":_vm.loading,"disabled":_vm.loading},model:{value:(_vm.fundAdministratorSelected),callback:function ($$v) {_vm.fundAdministratorSelected=$$v},expression:"fundAdministratorSelected"}})],1)]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"3","lg":"3","xl":"3"}},[_c('label',{staticClass:"field-label",attrs:{"for":"requiredApprovers"}},[_vm._v(_vm._s(_vm.$t('newCompany.requiredApprovers'))+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-0",attrs:{"size":"14","color":"#505865","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('newSolicitation.required')))])])],1),_c('v-text-field',{staticClass:"mr-4",attrs:{"id":"requiredApprovers","outlined":"","dense":"","hide-details":"auto","loading":_vm.loading,"single-line":"","type":"number","disabled":_vm.loading},model:{value:(_vm.requiredApprovers),callback:function ($$v) {_vm.requiredApprovers=$$v},expression:"requiredApprovers"}})],1)],1),_c('v-card-actions',{staticClass:"ml-2 my-5"},[_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"action-btn mr-3 px-10",attrs:{"large":"","outlined":"","color":"#BCBCBC"},on:{"click":_vm.back}},[_vm._v(" "+_vm._s(_vm.$t('newFundAdministrator.cancelFundAdministrator'))+" ")]),_c('v-btn',{staticClass:"action-btn px-12",attrs:{"dark":"","large":"","loading":_vm.loadingData,"color":"#023868"},on:{"click":_vm.setSavePostOrPutCompanies}},[_vm._v(" "+_vm._s(_vm.$t('newFundAdministrator.saveFundAdministrator'))+" ")])],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }