import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Login from '../views/Login.vue';
import Solicitation from '../views/requests/Solicitation.vue';
import newRequests from '../views/requests/newRequest.vue';
import editRequests from '../views/requests/editRequest.vue';
import viewRequests from '../views/requests/viewRequest.vue';
import Creditors from '../views/creditors/Creditors.vue';
import NewCreditors from '../views/creditors/NewCreditors.vue';
import EditCreditors from '../views/creditors/NewCreditors.vue';
import CostCenter from '../views/costCenters/CostCenter.vue';
import Companies from '../views/companies/Companies.vue';
import NewCompanies from '../views/companies/NewCompany.vue';
import Users from '../views/users/Users.vue';
import InnerUsers from '../views/users/InnerUsers.vue';
import FinancialPlain from '../views/financialBudgets/FinancialPlain.vue';
import FundAdministrator from '../views/fundAdministrator/FundAdministrator.vue';
import NewFundAdministrator from '../views/fundAdministrator/NewFundAdministrator.vue';
import ViewFundAdministrator from '../views/fundAdministrator/ViewFundAdministrator.vue';
import Required from '@/utils/required';

const required = new Required();

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
  },
  {
    path: '/creditors',
    name: 'Creditors',
    component: Creditors,
  },
  {
    path: '/creditors/new',
    name: 'NewCreditors',
    component: NewCreditors,
  },
  {
    path: '/creditors/edit/:id',
    name: 'EditCreditors',
    component: EditCreditors,
  },
  {
    path: '/companies',
    name: 'Companies',
    component: Companies,
  },
  {
    path: '/companies/new',
    name: 'NewCompanies',
    component: NewCompanies,
  },
  {
    path: '/companies/edit/:id',
    name: 'EditCompanies',
    component: NewCompanies,
  },
  {
    path: '/cost',
    name: 'CostCenter',
    component: CostCenter,
  },
  {
    path: '/financial',
    name: 'FinancialPlain',
    component: FinancialPlain,
  },
  {
    path: '/administrator',
    name: 'FundAdministrator',
    component: FundAdministrator,
  },
  {
    path: '/administrator/new',
    name: 'NewFundAdministrator',
    component: NewFundAdministrator,
  },
  {
    path: '/administrator/edit/:id',
    name: 'EditFundAdministrator',
    component: NewFundAdministrator,
    props: true,
  },
  {
    path: '/administrator/view/:id',
    name: 'ViewFundAdministrator',
    component: ViewFundAdministrator,
    props: true,
  },
  {
    path: '/',
    name: 'Payment Request',
    component: Solicitation,
    props: true,
  },
  {
    path: '/requests',
    name: 'Payment Request',
    component: Solicitation,
    props: true,
  },
  {
    path: '/request/new',
    name: 'NewRequest',
    component: newRequests,
  },
  {
    path: '/request/edit/:id',
    name: 'EditRequest',
    component: editRequests,
    props: true,
  },
  {
    path: '/request/copy/:id',
    name: 'CopyRequest',
    component: editRequests,
    props: true,
  },
  {
    path: '/request/:id',
    name: 'viewRequest',
    component: viewRequests,
    props: true,
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
  },
  {
    path: '/users/:id',
    name: 'InnerUsers',
    component: InnerUsers,
    props: true,
  },
  {
    path: '/system',
    name: 'System',
  },
  {
    path: '/HERFAM',
    name: 'HERFAM',
  },
  {
    path: '/REALSTATE',
    name: 'REALSTATE',
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
