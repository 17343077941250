import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import Endpoint from '@/utils/endpoint';
import Credential from '@/utils/credential';

const endpoint = new Endpoint();
const credentials = new Credential();

export class TokenStorage {
  private static readonly LOCAL_STORAGE_TOKEN = 'token';
  private static readonly LOCAL_STORAGE_REFRESH_TOKEN = 'refresh_token';

  public static isAuthenticated(): boolean {
    return this.getToken() !== null;
  }

  public static getAuthentication(): AxiosRequestConfig {
    const tken = this.getToken();
    return {
      headers: { Authorization: 'Bearer ' + this.getToken() },
    };
  }

  public static getNewToken(): Promise<string> {
    const data = {
      refresh_token: this.getRefreshToken(),
      grant_type: 'refresh_token',
      client_id: credentials.credential.clientId,
      client_secret: credentials.credential.clientSecret,
    };

    return new Promise((resolve: any, reject: any) => {
      axios
        .post(endpoint.login, qs.stringify(data), {
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          },
        })
        .then(response => {
          this.storeToken(response.data.token);
          this.storeRefreshToken(response.data.refresh_token);

          resolve(response.data.token);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  public static storeToken(token: string): void {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token);
  }

  public static storeRefreshToken(refreshToken: string): void {
    localStorage.setItem(
      TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN,
      refreshToken
    );
  }

  public static clear(): void {
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }

  private static getRefreshToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }

  public static getToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN);
  }
}
