export default interface Company {
  id: number;
  name: string;
  cnpj: string;
  siengeId: number;
  corporativeId: string;
  fundAdministratorId: number;
  fundAdministratorName: string;
  fundAdministratorExternalAdm: boolean;
  mailGroupFundPayment: string;
  enabled: boolean;
  requiresSecondApprover: boolean;
  requiredApprovers: number;
}

const companyInitialValue: Company = {
  id: 0,
  name: '',
  cnpj: '',
  siengeId: 0,
  corporativeId: '',
  fundAdministratorId: 0,
  fundAdministratorName: '',
  fundAdministratorExternalAdm: true,
  mailGroupFundPayment: '',
  enabled: false,
  requiresSecondApprover: false,
  requiredApprovers: 1
};

export { companyInitialValue };
