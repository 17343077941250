export default interface creditors {
  id: number;
  name: string;
  cpfCnpj: string;
  account: string;
  agency: string;
  agencyDigit: string;
  accountDigit: string;
  bank: string;
  disabled?: boolean;
  siengeValue: number;
}

const creditorInicitalValue: creditors = {
  id: 0,
  name: '',
  cpfCnpj: '',
  account: '',
  agency: '',
  agencyDigit: '',
  accountDigit: '',
  bank: '',
  disabled: false,
  siengeValue: 0,
};

export { creditorInicitalValue };
