import CostCenterSelected, {
  constCenterSelectedInitialValue,
} from './costCenterSelected';
import FinancialPlainSelected, {
  financialPlainSelectedInitialValue,
} from './financialPlainSelected';

export default class CostCentersPayment {
  costCenter: CostCenterSelected = constCenterSelectedInitialValue;
  costCenterId: number = 0;
  costCenterSelected: CostCenterSelected = constCenterSelectedInitialValue;
  financialBudget: FinancialPlainSelected = financialPlainSelectedInitialValue;
  financialBudgetId: number = 0;
  financialBudgetSelected: FinancialPlainSelected = financialPlainSelectedInitialValue;
  value: string = '';
}
