import Area from '@/@types/auth/areas';
import CurrentRoute from '@/@types/auth/currentRoute';
import User from '@/@types/auth/user';
import CompaniesNew from '@/@types/companiesNew';
import Creditor from '@/@types/creditors';
import Feedback from '@/@types/feedback';
import FundAdministrator from '@/@types/fundAdministrator';
import { JsonFeedback } from '@/@types/jsonFeedback';
import NewCreditor from '@/@types/newCreditor';
import SolicitationId from '@/@types/solicitationId';
import Endpoint from '@/utils/endpoint';
import axios from 'axios';
import { AuthService } from '../../services/authService';
import Auth from '../../utils/auth';
import { UserState } from './types';

const endpoint = new Endpoint();
const authService = new AuthService();

export default {
  getUser(state: UserState): User {
    return state.user;
  },
  getAreas(state: UserState): Area[] {
    return state.areas !== null ? state.areas : [];
  },
  getIslogged(state: UserState): boolean {
    return state.user != null ? true : false;
  },
  getErrorStatus(state: UserState): boolean {
    return state.error;
  },
  getCurrentRoute(state: UserState): CurrentRoute {
    return state.currentRoute;
  },
  canCreate(state: UserState): boolean {
    if (state.areas != null) {
      return Auth.verifyPermission(
        state.areas,
        state.currentRoute.name,
        'Create'
      );
    } else {
      // const resp = axios
      //   .get(
      //     `${endpoint.auth.getMenus}/${process.env.VUE_APP_ID}
      // /${state.user.email}`
      //   )
      const resp = axios
        .get(`${endpoint.auth.getMenus}/${state.user.email}`)
        .then(function(resp) {
          return Auth.verifyPermission(
            resp.data.data,
            state.currentRoute.name,
            'Create'
          );
        })
        .catch(e => console.log(e));
    }
    return false;
  },
  canUpdate(state: UserState): boolean {
    if (state.areas != null) {
      return Auth.verifyPermission(
        state.areas,
        state.currentRoute.name,
        'Update'
      );
    }
    return false;
  },
  canRead(state: UserState): boolean {
    if (state.areas != null) {
      return Auth.verifyPermission(
        state.areas,
        state.currentRoute.name,
        'List'
      );
    } else {
      // const resp = axios
      //   .get(
      //     `${endpoint.auth.getMenus}/${process.env.VUE_APP_ID}
      // /${state.user.email}`
      //   )
      const resp = axios
        .get(`${endpoint.auth.getMenus}/${state.user.email}`)
        .then(function(resp) {
          return Auth.verifyPermission(
            resp.data.data,
            state.currentRoute.name,
            'List'
          );
        })
        .catch(e => console.log(e));
    }
    return false;
  },
  canDelete(state: UserState): boolean {
    if (state.areas != null) {
      return Auth.verifyPermission(
        state.areas,
        state.currentRoute.name,
        'Delete'
      );
    }

    return false;
  },

  getSolicitationId(state: UserState): SolicitationId {
    return state.solicitationid;
  },

  getModalDeny(state: UserState): boolean {
    return state.modalDeny;
  },
  getModalCancel(state: UserState): boolean {
    return state.modalCancel;
  },
  getModalApprove(state: UserState): boolean {
    return state.modalApprove;
  },
  getModalPay(state: UserState): boolean {
    return state.modalPay;
  },
  getModalAddStatement(state: UserState): boolean {
    return state.modalAddStatement;
  },
  getModalExtendExpiration(state: UserState): boolean {
    return state.modalExtendExpiration;
  },
  getModalEmergencyApproval(state: UserState): boolean {
    return state.modalEmergencyApproval;
  },
  getModalBankApproval(state: UserState): boolean {
    return state.modalBankApproval;
  },
  getFeedback(state: UserState): Feedback {
    return state.feedback;
  },
  getUserLanguage(state: UserState): String {
    return state.userLanguage;
  },
  getModalNewCreditor(state: UserState): boolean {
    return state.modalNewCreditor;
  },
  getModalConfirmNewCreditor(state: UserState): boolean {
    return state.modalConfirmNewCreditor;
  },
  getNewCreditorsSienge(state: UserState): NewCreditor {
    return state.newCreditorsSienge;
  },
  getFundAdministratorData(state: UserState): FundAdministrator {
    return state.fundAdministratorData;
  },
  getCompaniesData(state: UserState): CompaniesNew {
    return state.companiesData;
  },
  getModalPending(state: UserState): boolean {
    return state.modalPending;
  },
  getStatusPending(state: UserState): number {
    return state.statusPending;
  },
  getModalApproverId(state: UserState): boolean {
    return state.modalApproverId;
  },
  getModalImportCSV(state: UserState): boolean {
    return state.modalImportRequest;
  },
  getModalImportError(state: UserState): boolean {
    return state.modalImportError;
  },
  getModalAttachmentError(state: UserState): boolean {
    return state.modalAttachmentError;
  },
  getModalExported(state: UserState): boolean {
    return state.modalExported;
  },
  getCreditorData(state: UserState): Creditor {
    return state.creditorsData;
  },
  getJsonFeedback(state: UserState): JsonFeedback {
    return state.jsonFeedback;
  },
};
