//import router from '../router';
import { PluginObject, VueConstructor } from 'vue';
import axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosRequestConfig,
  AxiosTransformer,
} from 'axios';
import { TokenStorage } from '@/utils/tokenStorage';
import router from '@/router';

const lesteKey = process.env.VUE_APP_URL_BASE_KEY;

export default class Http implements PluginObject<void> {
  public readonly instance: AxiosInstance;

  public constructor() {
    this.instance = axios.create({
      headers: {
        'lesteKey': lesteKey
      }
    });

    this._initializeRequestInterceptor();
    this._initializeResponseInterceptor();
  }

  private _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      this._handleRequest,
      this._handleError
    );
  };

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError
    );
  };

  private _handleRequest = (config: AxiosRequestConfig) => {
    const token = TokenStorage.getToken();    
    if (token != null) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    
    return config;
  };

  private _handleResponse = ({ data }: AxiosResponse) => data;

  protected _handleError = (error: any) => {
    if (error.response.status == 401) {
      return new Promise((resolve, rejectt) => {
        window.location.href = '/login';
        rejectt(error);
      });
    }
    return new Promise((_, reject) => reject(error));
  };

  public install(Vue: VueConstructor) {
    Vue.prototype.$http = this.instance;
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $http: AxiosInstance;
  }
}
