

































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { Watch, Prop } from 'vue-property-decorator';
import Endpoint from '@/utils/endpoint';
import Header from '@/@types/header';
import FundAdministratorHeader from '@/components/headers/fundAdministrator-header';
import FundAdministratorHeaderPT from '@/components/headers/pt-br/fundAdministrator-header-pt';
import FundAdministrator, {
  fundAdministratorInitialValue,
} from '@/@types/fundAdministrator';
import FeedbackInterface from '@/@types/feedback';
import Company from '@/@types/companies';
import { AuthService } from '../../services/authService';
import ErrorResponse from '@/@types/ErrorResponse';
import Creditor from '@/@types/creditors';
import { TheMask } from 'vue-the-mask';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({ components: { TheMask } })
export default class Companies extends Vue {
  @Prop(String) currentLanguage: string = 'en';
  @Prop(String) readonly id!: string;

  @Getter('getUserLanguage', { namespace: 'auth' }) userLanguage!: any;
  @Action('setUserLanguage', { namespace: 'auth' }) setUserLanguage: any;

  @Getter('getFeedback', { namespace: 'auth' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'auth' }) public setFeedback: any;

  @Getter('getCreditorData', { namespace: 'auth' }) creditorData!: any;
  @Action('setCreditorData', { namespace: 'auth' }) setCreditorData: any;

  headers: Header[] = FundAdministratorHeader.header; //idioma
  headersPT: Header[] = FundAdministratorHeaderPT.header; //idioma
  administratorListCompanies: FundAdministrator[] = [];
  fundId: FundAdministrator = fundAdministratorInitialValue;
  search = null;
  loading = false;
  loadingData = false;
  isEditCreditors = false;
  nameCreditor = '';
  shortNameFundAdministrator = '';
  cpfCnpjCreditor = '';
  cpf = '';
  cnpj = '';
  fundAdministratorSelected: FundAdministrator = fundAdministratorInitialValue;

  private endpoint = new Endpoint();
  authService = new AuthService();

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  async mounted() {
    if (this.$route.name === 'EditCreditors') {
      this.loading = true;
      await this.setCreditorsItems(this.creditorData);
      this.isEditCreditors = true;
    }
  }

  public async saveCreditor() {
    if (!this.form.validate()) {
      return;
    }

    if (
      this.cpfCnpjCreditor.length !== 14 &&
      this.cpfCnpjCreditor.length < 18
    ) {
      this.setFeedback({
        feedback: true,
        color: 'red',
        text:
          this.userLanguage === 'en' ? 'CPF/CPNJ invalid' : 'CPF/CPNJ inválido',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 4000);
      return;
    }

    this.loadingData = true;
    const id = this.isEditCreditors ? this.creditorData.id : 0;
    const params = {
      id: id,
      name: this.nameCreditor,
      identifier: this.removeCpfCnpjMask(),
    };
    try {
      const token = await this.authService.getTokenForMenuAsync();
      if (this.isEditCreditors) {
        this.$http.post(this.endpoint.creditorCreateUpdate, params, {
          headers: {
            Authorization: token,
          },
        });
      } else {
        this.$http.post(this.endpoint.creditorCreateUpdate, params, {
          headers: {
            Authorization: token,
          },
        });
      }

      this.setFeedback({
        feedback: true,
        color: 'green',
        text: this.userLanguage === 'en' ? 'Creditor saved' : 'Credor salvo',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 4000);
    } catch (error) {
      const errorData = error as ErrorResponse;
      console.log('Error no put do companies');
      this.setFeedback({
        feedback: true,
        color: 'red',
        text:
          errorData.response.status === 400
            ? `${errorData.response.data}`
            : 'Ocorreu um erro',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 4000);
    } finally {
      this.loadingData = false;
      this.back();
    }
  }

  setCreditorsItems(item: Creditor) {
    this.loading = true;
    this.nameCreditor = item.name;
    this.cpfCnpjCreditor = item.cpfCnpj;
    this.loading = false;
  }

  back() {
    this.$router.replace({
      path: '/creditors',
    });
  }

  removeCpfCnpjMask() {
    if (this.cpfCnpjCreditor.length <= 14) {
      const cpf = this.cpfCnpjCreditor.split('.').join('');
      const cpfCnpj = cpf.split('-').join('');
      this.cpf = cpfCnpj;
      return cpfCnpj;
    }

    if (this.cpfCnpjCreditor.length > 14) {
      const cnpj = this.cpfCnpjCreditor.split('.').join('');
      const cnpjData = cnpj.split('/').join('');
      const cnpjCpf = cnpjData.split('-').join('');
      this.cnpj = cnpjCpf;
      return cnpjCpf;
    }
  }
}
