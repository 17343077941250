import Header from '@/@types/header';

export default class CostCenterHeader {
  public static header: Header[] = [
    {
      text: 'Id',
      align: 'start',
      sortable: false,
      value: 'id',
      class: 'item-table-header item-table-header-cost-center',
    },
    {
      text: 'Name',
      align: 'start',
      sortable: true,
      value: 'name',
      class: 'item-table-header item-table-header-cost-center',
    },
    {
      text: 'Company',
      align: 'start',
      sortable: true,
      value: 'companyName',
      class: 'item-table-header item-table-header-cost-center',
    },
    {
      text: 'Sienge',
      align: 'start',
      sortable: false,
      value: 'siengeValue',
      class: 'item-table-header item-table-header-cost-center',
    },
    {
      text: 'Enabled',
      align: 'start',
      sortable: false,
      value: 'enabled',
      class: 'item-table-header item-table-header-cost-center',
    },
  ];
}
