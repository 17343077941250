export default class Auth {
  static verifyPermission(
    areas: Array<any>,
    menu: string | null,
    action: string
  ): boolean {
    if (menu) {
      return areas.some(x => {
        return (
          x.title === menu &&
          x.menus.some(
            (y: { permissions: { action: string; active: any }[] }) => {
              return y.permissions.some(
                (z: { action: string; active: any }) =>
                  z.action === action && z.active
              );
            }
          )
        );
      });
    } else {
      return false;
    }
  }
}
