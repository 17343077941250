






















import { JsonFeedback as JsonFeedbackInterface } from '@/@types/jsonFeedback';
import Vue from 'vue';
import Component from 'vue-class-component';
import VueJsonPretty from 'vue-json-pretty';
import { Action, Getter } from 'vuex-class';


@Component({
  components: { VueJsonPretty },
})
export default class JsonFeedback extends Vue {
  @Getter('getJsonFeedback', { namespace: 'auth' })
  public jsonFeedback!: JsonFeedbackInterface;
  @Action('setJsonFeedback', { namespace: 'auth' }) setJsonFeedback: any;

}

