




























































































































































import Endpoint from '@/utils/endpoint';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import Companies from '@/@types/companies';
import FeedbackInterface from '@/@types/feedback';
import UserAccess from '@/@types/userAccess';
import { AuthService } from '../../services/authService';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

interface EmailLanguage {
  text: string;
  value: string;
}

@Component({})
export default class InnerUsers extends Vue {
  @Getter('getFeedback', { namespace: 'auth' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'auth' }) public setFeedback: any;

  @Getter('getUserLanguage', { namespace: 'auth' }) userLanguage!: any;
  @Action('setUserLanguage', { namespace: 'auth' }) setUserLanguage: any;

  @Prop(Number) readonly id: number | undefined;

  private endpoint = new Endpoint();
  authService = new AuthService();

  users = {};
  name = '';
  userId = 0;
  isFinancialUser = false;
  isEmergencialApprover = false;
  isComplianceApprover = false;
  isAdmin = false;

  emailLanguage: EmailLanguage[] = [
    { text: 'Inglês', value: 'en' },
    { text: 'Português', value: 'pt' },
  ];
  emailLanguageSelected: EmailLanguage = { text: 'Inglês', value: 'en' };
  userCompanies = [];
  companySelected = { name: '', id: 0, cnpj: '' };
  accessItems = [
    { access: 'Visualizador', accessType: 1 },
    { access: 'Solicitante', accessType: 2 },
    { access: 'Aprovador', accessType: 3 },
  ];
  accessTypeSelected = { access: '', accessType: 0 };
  userAccess: UserAccess[] = [];
  loadingEdit = false;

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  public async getUserById(token: string | null) {
    try {
      const response = await this.$http.get(
        this.endpoint.users + `/${this.id}`,
        {
          headers: { Authorization: token },
        }
      );
      this.users = response;
      this.userId = response.id;
      this.name = response.name;
      this.isFinancialUser = response.isFinanceUser;
      this.isEmergencialApprover = response.isEmergencyApprover;
      this.isComplianceApprover = response.isComplianceApprover;
      this.emailLanguageSelected = this.emailLanguage.find(
        (language: EmailLanguage) =>
          language.value === response.approvalEmailLanguage
      ) as EmailLanguage;
      this.userAccess = response.userCompanies.map((item: UserAccess) => {
        return {
          accessTypeSelected: item.accessType,
          companySelected: this.userCompanies.find(
            (x: Companies) => x.id === item.companyId
          ),
        };
      });
    } 
    catch (error) {
      console.log('Ocorreu um erro ao listar detalhes do usuário');
      console.error(error);
    }
  }

  public async getCompanies(token: string | null) {
    const response = await this.$http.get(this.endpoint.companyAll, {
      headers: { Authorization: token },
    });
    this.userCompanies = response;
  }

  public async saveUserData() {
    try {
      const token = await this.authService.getTokenForMenuAsync();
      const params = {
        id: this.userId,
        name: this.name,
        isEmergencyApprover: this.isEmergencialApprover,
        isFinanceUser: this.isFinancialUser,
        isComplianceApprover: this.isComplianceApprover,
        approvalEmailLanguage: this.emailLanguageSelected.value,
        userCompanies: this.userAccess.map((item: UserAccess) => ({
          companyId: item.companySelected.id,
          companyName: item.companySelected.name,
          accessType: item.accessTypeSelected.accessType,
        })),
      };

      this.loadingEdit = true;
      await this.$http.put(this.endpoint.users, params, {
        headers: { Authorization: token },
      });
      this.setFeedback({
        feedback: true,
        color: 'green',
        text: this.userLanguage === 'en' ? 'Edited user' : 'Usuário editado',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
      this.back();
    } catch (error) {
      console.log('Ocorreu um erro ao editar usuário');
      console.log(error);
      this.setFeedback({
        feedback: true,
        color: 'red',
        text:
          error.response.status === 400
            ? `${error.response.data}`
            : 'Ocorreu um erro',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    }
    this.loadingEdit = false;
  }

  public addNewAccess() {
    this.userAccess.push(new UserAccess());
  }

  public removeAccess(item: UserAccess) {
    this.userAccess.splice(this.userAccess.indexOf(item), 1);
  }

  public async mounted() {
    const token = await this.authService.getTokenForMenuAsync();
    await this.getCompanies(token);
    this.getUserById(token);
    // this.addNewAccess();
  }

  public back() {
    this.$router.replace({
      path: '/users',
    });
  }
}
