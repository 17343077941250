














































import Endpoint from '@/utils/endpoint';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';

import FeedbackInterface from '@/@types/feedback';
import SolicitationId from '@/@types/solicitationId';
import { AuthService } from '../../services/authService';

import ErrorResponse from '@/@types/ErrorResponse';
import { ApprovalResponse } from '@/@types/paymentRequest/approvalResponse';
import { parseBackendErrorList } from '@/utils/errorHandler';

const namespace = 'add';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({})
export default class modalDeny extends Vue {
  @Getter('getSolicitationId', { namespace: 'auth' })
  public solicitation!: SolicitationId;

  @Getter('getModalApprove', { namespace: 'auth' })
  public modalApprove!: boolean;
  @Action('setModalApprove', { namespace: 'auth' })
  public setModalApprove!: any;

  @Getter('getFeedback', { namespace: 'auth' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'auth' }) public setFeedback: any;

  @Getter('getUserLanguage', { namespace: 'auth' }) userLanguage!: any;
  @Action('setUserLanguage', { namespace: 'auth' }) setUserLanguage: any;

  loading = false;

  private endpoint = new Endpoint();
  authService = new AuthService();

  async approveSolicitation() {
    const token = await this.authService.getTokenForMenuAsync();

    try {
      this.loading = true;
      const data = await this.$http.put<ApprovalResponse>(
        this.endpoint.approveSolicitation + `/${this.solicitation}`,
        token,
        {
          headers: { Authorization: token },
        }
      );      
      this.loading = false;
      this.closeModal();
      if (data.errors.length > 0){
        this.setFeedback({
          feedback: true,
          color: 'red',
          text: parseBackendErrorList(data.errors)
        });
      }
      else {
        this.setFeedback({
          feedback: true,
          color: 'green',
          text:
            this.userLanguage === 'en'
              ? 'Request approved'
              : 'Solicitação aprovada',
        });
      }
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);

      this.$router.push({ path: '/requests' });
    } catch (error) {
      const errorData = error as ErrorResponse;
      this.loading = false;
      console.error(error);
      this.closeModal();

      this.setFeedback({
        feedback: true,
        color: 'red',
        text:
          errorData.response.status === 400
            ? `${errorData.response.data}`
            : 'Ocorreu um erro',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    }
  }

  closeModal(): void {
    this.setModalApprove(false);
  }
}
