import { AxiosInstance } from 'axios';
import { AuthService } from '../services/authService';

export default class SolicitationsAction {
  public authService = new AuthService();

  public async getSolicitationsData(
    api: AxiosInstance,
    url: string,
    loading: boolean
  ) {
    const token = await this.authService.getTokenForMenuAsync();
    loading = true;
    const response = await api.get(url, {
      headers: { Authorization: token },
    });
    loading = false;
    return response;
  }

  public setCurrency(value: string) {
    if (value === 'R$   ' || value === 'BRL  ') {
      return 'Real';
    }
    if (
      value === '$    ' ||
      value === 'usa  ' ||
      value === 'uad  ' ||
      value === 'USD  ' ||
      value === 'USD$ ' ||
      value === 'US   ' ||
      value === 'U$   ' ||
      value === 'US$  '
    ) {
      return 'Dólar';
    }
    if (value === '£    ' || value === 'GBP  ' || value === 'GDP  ') {
      return 'Libra';
    }
    if (value === 'EUR  ') {
      return 'Euro';
    }
    if (
      value === 'c$   ' ||
      value === 'C$   ' ||
      value === 'cad$ ' ||      
      value === 'CAD$ ' ||   
      value === 'can$ ' ||   
      value === 'Can$ ' ||   
      value === 'CAN$ ' ||      
      value === 'CAD  ' ||
      value === 'cad  '
    ) {
      return 'Canadian Dollar';
    }
  }

  setStatusData(item: number, language: string) {
    if (item === 1) {
      return language === 'en' ? 'Requested' : 'Solicitada';
    }
    if (item === 2) {
      return language === 'en' ? 'Approved' : 'Aprovada';
    }
    if (item === 3) {
      return language === 'en' ? 'Denied' : 'Negada';
    }
    if (item === 4) {
      return language === 'en' ? 'Exported' : 'Exportada';
    }
    if (item === 5) {
      return language === 'en' ? 'Error' : 'Erro';
    }
    if (item === 6) {
      return language === 'en' ? 'Draft' : 'Rascunho';
    }
    if (item === 7) {
      return language === 'en' ? 'Canceled' : 'Cancelada';
    }
    if (item === 8) {
      return language === 'en' ? 'Paid' : 'Paga';
    }
    if (item === 9) {
      return language === 'en' ? 'Emergency Pending' : 'Pendente Emergencial';
    }
  }
}
