import StatementPayment from '@/@types/statement/statementPayment';
import Endpoint from '@/utils/endpoint';
import { AxiosInstance } from 'axios';
import { ActionContext } from 'vuex';
import { AuthService } from '../../services/authService';
import { StatementPaymentStateInterface } from './types';

export default {
  setStatementPayment(
    store: ActionContext<StatementPaymentStateInterface, any>,
    statementPayment: StatementPayment
  ): void {
    store.commit('setStatementPayment', statementPayment);
  },

  async getStatementPaymentByRequestId(
    store: ActionContext<StatementPaymentStateInterface, any>,
    data: { paymentRequestId: number, http: AxiosInstance } 
  ): Promise<StatementPayment> {
    const endpoint = new Endpoint();
    const authService = new AuthService();
    const url = endpoint.getStatementPayment;
    const token = await authService.getTokenForMenuAsync();

    try {
      const response = await data.http.get(
        `${url}/${data.paymentRequestId}`, 
        {
          headers: { Authorization: token },
        }
      );
      let statementPayment = response.statementPayment as StatementPayment;
      if (statementPayment.bankAuthentication.startsWith("MANUAL"))
        statementPayment.bankAuthentication = "MANUAL";
      return statementPayment;
    } catch (e) {
      const { response } = e as any;
      return null as any;
    }
  },

  async generateReceiptForPayment(
    store: ActionContext<StatementPaymentStateInterface, any>,
    data: { paymentRequestId: number, http: AxiosInstance } 
  ): Promise<string> {
    const endpoint = new Endpoint();
    const authService = new AuthService();
    const url = endpoint.generateReceipt;
    const token = await authService.getTokenForMenuAsync();

    try {
      const response = await data.http.get(
        `${url}/${data.paymentRequestId}`, 
        {
          headers: { Authorization: token },
        }
      );
      return response as string;
    } catch (e) {
      const { response } = e as any;
      console.log(`Error ${response}`);
      return null as any;
    }
  }
};
