
























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';

import Header from '@/@types/header';
import Users from '@/@types/users';
import usersHeaderPT from '@/components/headers/pt-br/users-headers-pt';
import usersHeader from '@/components/headers/users-headers';

import { AuthService } from '../../services/authService';
import Endpoint from '../../utils/endpoint';

@Component({})
export default class Requests extends Vue {
  @Getter("getUserLanguage", { namespace: "auth" }) userLanguage!: any;
  @Action("setUserLanguage", { namespace: "auth" }) setUserLanguage: any;

  headers: Header[] = usersHeader.header;
  headersPT: Header[] = usersHeaderPT.header;
  items = [];
  loading = false;
  search = "";

  private endpoint = new Endpoint();
  public authService = new AuthService();

  public async getUsers() {
    const token = await this.authService.getTokenForMenuAsync();
    this.loading = true;
    try {
      const response = await this.$http.get(this.endpoint.users, {
        headers: { Authorization: token },
      });
      this.items = response;
    } catch (error) {
      console.log("Ocorreu um erro ao listar usuários");
      console.log(error);
    }
    this.loading = false;
  }

  public setAccessCount(access: number) {
    if (access > 0) {
      return this.userLanguage === "en"
        ? access + " access"
        : access + " acessos";
    } else {
      return this.userLanguage === "en" ? "No access" : "Nenhum acesso";
    }
  }

  public goToInner(item: Users) {
    this.$router.push({ path: `/users/${item.id}` });
  }

  public mounted() {
    this.getUsers();
  }
}
