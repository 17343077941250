






















































































































import Company from '@/@types/companies';
import Header from '@/@types/header';
import CompanyHeader from '@/components/headers/company-header';
import CompanyHeaderPT from '@/components/headers/pt-br/company-header-pt';
import Endpoint from '@/utils/endpoint';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AuthService } from '../../services/authService';

@Component({})
export default class Companies extends Vue {
  @Prop(String) currentLanguage: string = 'en';

  @Getter('getUserLanguage', { namespace: 'auth' }) userLanguage!: any;
  @Action('setUserLanguage', { namespace: 'auth' }) setUserLanguage: any;

  @Getter('getCompaniesData', { namespace: 'auth' }) companiesData!: any;
  @Action('setCompaniesData', { namespace: 'auth' }) setCompaniesData: any;

  headers: Header[] = CompanyHeader.header; //idioma
  headersPT: Header[] = CompanyHeaderPT.header; //idioma
  items: Company[] = [];
  search = null;
  loading = false;
  selectedItem = false;

  private endpoint = new Endpoint();
  authService = new AuthService();

  async getCompanies() {
    const token = await this.authService.getTokenForMenuAsync();
    this.loading = true;
    try {
      const response = await this.$http.get(this.endpoint.company, {
        headers: { Authorization: token },
      });
      const items = response;
      this.items = items;

    }
    catch {
      this.items = [];
    }
    this.loading = false;
  }

  public editCompaniesFundAdministrator(item: Company) {
    this.setCompaniesData(item);
    this.$router.push({
      name: `EditCompanies`,
      params: { id: item.id.toString() },
    });
  }

  public selectItem(item: boolean) {
    this.selectedItem = item;
  }

  public unSelectItem() {
    this.selectedItem = false;
  }

  mounted() {
    this.getCompanies();
  }
}
