import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    creditor: {
      title: "Creditors",
      newCreditor: "New creditor",
      exportPDF: "Export PDF",
      exportCSV: "Export CSV",
    },
    newCreditor: {
      newTitle: "Creditor - New",
      editTitle: "Creditor - Edition",
      identifier: "CPF/CNPJ",
    },
    newCreditorSienge: {
      newCreditorBySienge: "New Creditor By Sienge",
      credorTitle: "Add Creditors",
      siengeCredor: "Creditors",
      siengeTitle: "Code Sienge",
      credorSaved: "Creditor By Sienge Saved",
      credorHeaderField: "Código Sienge Credor",
      credorAddSienge: "Add new creditor",
      credorCancel: "Cancel",
      credorAdd: "Add",
      credorConfirm: "Confirm",
      name: "Name",
      bank: "Bank",
      agency: "Agency",
      account: "Account",
      digit: "Digit",
      noCredorFound: "No Credor Found",
    },
    companie: {
      title: "Companies",
      newCompanies: "New Company",
      exportPDF: "Export PDF",
      exportCSV: "Export CSV",
      edit: "Edit",
    },
    newCompany: {
      newTitle: "New Company",
      editTitle: "Edit Company",
      fundAdministratorId: "Fund Administrator",
      emailGroup: "Email Group Fund Payment",
      secondApprover: "Requires Second Approver",
      requiredApprovers: "Required Approvers",
    },
    costCenter: {
      title: "Cost center",
      exportPDF: "Export PDF",
      exportCSV: "Export CSV",
    },
    solicitation: {
      headers: [
        { title: "Sienge" },
        { title: "Id" },
        { title: "Payment Company" },
        { title: "Issuing" },
        { title: "Expiration" },
        { title: "Creditor" },
        { title: "Requester" },
        { title: "Value" },
        { title: "Status" },
        { title: "source" },
      ],
      title: "Payment Request",
      requestBtn: "New Payment Request",
      approverBtn: "Approve Batch",
      filterBtn: "Filters",
      reloadPage: "Reload page",
      importCSV: "Import Payment",
      downloadCSV: "Export CSV",
      downloadTemplate: "Download Template",
      until: "Until:",
      source: "Source",
      since: "Since:",
    },
    newSolicitation: {
      back: "Back",
      add: "Add",
      addPDF: "Add File",
      required: "Required field",
      inclusion: "Created",
      newTitle: "Payment Request - New",
      editTitle: "Payment Requets - Edition",
      viewTitle: "Payment Request",
      titlePendency: "Pendency",
      companyTitle: "Company",
      company: "Payment Company",
      companyCnpj: "Company CNPJ",
      nfe: "Company NFe",
      nfeCnpj: "NFe CNPJ",
      creditor: "Creditor",
      creditorCnpj: "Creditor CNPJ",
      documentTitle: "Document",
      document: "Document Type",
      documentNumber: "Document Number",
      paymentForm: "Payment Method",
      paymentType: "Payment Type",
      barCode: "Bar Code",
      contributor: "Contributor",
      contributorCnpj: "Contributor CNPJ",
      revenue: "Revenue Code",
      reference: "Reference",
      bank: "Bank",
      agency: "Agency",
      account: "Account",
      digit: "Digit",
      valueTitle: "Value",
      grossValue: "Total Amount",
      currency: "Currency",
      emission: "Issue Date",
      expiration: "Expiration Date",
      observation: "Comments",
      errorMessage: "Error Message",
      voucherTitle: "Payment Statement",
      voucher: "Email Notification",
      solicitation: "Recursive Request",
      costCenterTitle: "Cost Center",
      downloadCostCenterTemplate: "Download Template",
      importCostCenter: "Import Cost Center",
      cost: "Cost Center",
      financial: "Financial Plan",
      value: "Amount",
      approval: "Approval",
      requester: "Requester",
      sector: "Sector",
      approver: "Approver",
      approvers: "Approvers",
      attachments: "Attachments",
      limit: "maximum 5 attachments",
      paymentVoucher: "Payment statement",
      emptyPaymentVoucher: "Payment statement not attached",
      errorDownloadVoucher: "Error downloading payment statement",
      cancelBtn: "Cancel",
      saveDraftBtn: "Save as draft",
      saveBtn: "Request",
      saveResolve: "Resolve",
      savePendency: "Save Pendency",
      needsComplianceApproval: "Needs Compliance Approval",
      secondApprover: "Approver",
      Approvermode: "Mode",
      SequentialDescription:
        "Every approver selected has to approve in the order they were chosen",
      ParallelDescription:
        "Every approver selected has to approve, in any order",
      AnyDescription:
        "The least amount of the company required approvers has to approve, in any order",
      SequentialLabel: "Sequential",
      ParallelLabel: "Parallel",
      AnyLabel: "Any",
      userEmergency: {
        title: "Emergency Approver",
        true: "Yes",
        false: "Not",
      },
      userCompliance: {
        title: "Compliance Approver",
        true: "Yes",
        false: "Not",
      },
      status: {
        1: "Requested",
        2: "Approved",
        3: "Denied",
        4: "Exported",
        5: "Error",
        6: "Draft",
        7: "Canceled",
        8: "Paid",
        9: "Emergency Pending",
        10: "Pending",
        12: "Bank Approval",
        13: "Compliance Approval",
      },
    },
    newFundAdministrator: {
      back: "Back",
      newTitle: "Fund Administrator - New",
      editTitle: "Fund Administrator - Edition",
      name: "Name",
      shortName: "Short Name",
      identifier: "Identifier",
      email: "Contact E-mail",
      mailGroupLeste: "Email Group Leste",
      externalAdm: "External Administration",
      businessUnity: "Business Unity (BU)",
      templatePT: "Template E-mail PT",
      templateEN: "Template E-mail EN",
      requiredTemplate: "Fill at least one template Email",
      saveFundAdministrator: "Save",
      cancelFundAdministrator: "Cancel",
    },
    solicitationStatus: {
      allStatus: "All status",
      requested: "Requested",
      approved: "Approved",
      denied: "Denied",
      exported: "Exported",
      error: "Exported error",
      draft: "Draft",
      canceled: "Canceled",
      paid: "Paid",
      emergency: "Pending Emergency",
      allStatusSource: "All sources",
    },
    user: {
      back: "Back",
      title: "Users",
      exportPDF: "Export PDF",
      exportCSV: "Export CSV",
      editTitle: "Edit users",
      userName: "Name:",
      financialUser: "Financial user",
      emergencyApprover: "Emergency approver",
      complianceApprover: "Compliance approver",
      emailLanguage: "Approval language email:",
      accessTitle: "Access",
      add: "Add new access",
      saveBtn: "Save",
      cancelBtn: "Cancel",
    },
    financialPlain: {
      title: "Financial Plan",
      exportPDF: "Export PDF",
      exportCSV: "Export CSV",
    },
    fundAdministrator: {
      title: "Fund Administrator",
      btn: "New Fund Administrator",
    },
    emergencyModal: {
      title: "Emergency Approval",
      button: "Confirm",
    },
    modalAttachment: {
      title: "Upload File Error",
      closeBtn: "Close",
    },
    cancelModal: {
      title: "Do you want to cancel this request?",
      closeBtn: "No",
      cancelBtn: "Cancel",
    },
    denyModal: {
      title: "Do you want to deny this request?",
      closeBtn: "Cancel",
      denyBtn: "Deny",
    },
    approveModal: {
      title: "Do you want to approve this request?",
      closeBtn: "Cancel",
      approveBtn: "Approve",
    },
    bankApprovalModal: {
      title: "Confirm operation?",
      closeBtn: "Cancel",
      approveBtn: "Confirm",
    },
    costCenterModal: {
      title: "Confirm Export?",
      saveBtn: "Export",
    },
    approveModalId: {
      title: "Do you confirm approving these requests?",
      manyRequests: "selected requests",
      oneRequest: "selected request",
      closeBtn: "Cancel",
      approveBtn: "Approve",
    },
    modalImport: {
      titleSuccess: "Spreadsheet imported successfully!",
      titleLoading: "Importing...",
      closeBtnCancelAxios: "Cancelar importação",
      closeBtnSuccess: "Close",
    },
    modalImportError: {
      titleError:
        "Some requests were not imported due to the following errors:",
    },
    payModal: {
      title: "Confirm payment?",
      addAttachment: "Add payment statement",
      closeBtn: "Cancel",
      payBtn: "Confirm",
    },
    pendingModal: {
      title: "Pendency",
      message: "Pendency reason",
      closeBtn: "Cancel",
      pendencyBtn: "Confirm",
    },
    addStatementModal: {
      title: "Do you want to add a payment statement?",
      addAttachment: "Add payment statement",
      closeBtn: "Cancel",
      addStatementBtn: "Confirm",
    },
    extendPaymentExpirationModal: {
      title: "Extend payment expiration",
      setExpirationDate: "Select a new expiration date",
      closeBtn: "Cancel",
      confirmExpirationDate: "Confirm",
    },
  },
  pt: {
    creditor: {
      title: "Credores",
      newCreditor: "Novo credor",
      exportPDF: "Exportar PDF",
      exportCSV: "Exportar CSV",
    },
    newCreditor: {
      newTitle: "Credor - Novo",
      editTitle: "Credor - Edição",
      identifier: "CPF/CNPJ",
    },
    newCreditorSienge: {
      newCreditorBySienge: "Novo Credor por Sienge",
      credorTitle: "Adicionar Credores",
      siengeCredor: "Credor",
      siengeTitle: "Cód. Sienge",
      credorHeaderField: "Sienge Creditor Code",
      credorSaved: "Credor por Sienge Salvo",
      credorAddSienge: "Adicionar novo credor",
      credorCancel: "Cancelar",
      credorAdd: "Adicionar",
      credorConfirm: "Confirmar",
      name: "Nome",
      bank: "Banco",
      agency: "Agência",
      account: "Conta",
      digit: "Dígito",
      noCredorFound: "Nenhum Credor Encontrado",
    },
    companie: {
      title: "Empresas",
      newCompanies: "Nova Empresa",
      exportPDF: "Exportar PDF",
      exportCSV: "Exportar CSV",
      edit: "Editar",
    },
    newCompany: {
      newTitle: "Nova Empresa",
      editTitle: "Editar Empresa",
      fundAdministratorId: "Administrador de Fundos",
      emailGroup: "Chave e-mail Leste",
      secondApprover: "Requer Segundo Aprovador",
      requiredApprovers: "Aprovadores Requeridos",
    },
    costCenter: {
      title: "Centro de custos",
      exportPDF: "Exportar PDF",
      exportCSV: "Exportar CSV",
    },
    solicitation: {
      headers: [
        { title: "Sienge" },
        { title: "Id" },
        { title: "Empresa do Pagamento" },
        { title: "Inclusão" },
        { title: "Vencimento" },
        { title: "Credor" },
        { title: "Solicitante" },
        { title: "Valor" },
        { title: "Fonte" },
        { title: "Status" },
      ],
      title: "Solicitações",
      requestBtn: "Nova Solicitação",
      approverBtn: "Aprovar Em Lote",
      filterBtn: "Filtros",
      reloadPage: "Atualizar página",
      importCSV: "Importar Solicitação",
      downloadCSV: "Exportar CSV",
      downloadTemplate: "Baixar Template",
      until: "Até:",
      since: "De:",
      source: "Fonte",
    },
    newSolicitation: {
      back: "Voltar",
      add: "Adicionar",
      addPDF: "Adicionar arquivo",
      required: "Campo obrigatório",
      inclusion: "Inclusão",
      newTitle: "Solicitação de Pagamentos - Nova",
      editTitle: "Solicitação de Pagamentos - Edição",
      viewTitle: "Solicitação de Pagamentos",
      titlePendency: "Pendência",
      companyTitle: "Empresa",
      company: "Empresa Pgto",
      companyCnpj: "CNPJ Empresa",
      nfe: "Empresa NFe",
      nfeCnpj: "CNPJ NFe",
      creditor: "Credor",
      creditorCnpj: "CNPJ Credor",
      documentTitle: "Documento",
      document: "Tipo de Documento",
      documentNumber: "Nº do documento",
      paymentForm: "Forma de pagamento",
      paymentType: "Tipo de pagamento",
      barCode: "Código de Barra",
      contributor: "Contribuinte",
      contributorCnpj: "CNPJ Contribuinte",
      revenue: "Código da Receita",
      reference: "Referência",
      bank: "Banco",
      agency: "Agência",
      account: "Conta",
      digit: "Dígito",
      valueTitle: "Valor",
      grossValue: "Valor Bruto",
      currency: "Moeda",
      emission: "Data de Emissão",
      expiration: "Data de Vencimento",
      observation: "Observações",
      errorMessage: "Mensagem de erro",
      voucherTitle: "Comprovante",
      voucher: "E-mail Comprovante",
      solicitation: "Solicitação Recursiva",
      costCenterTitle: "Centro de Custo",
      downloadCostCenterTemplate: "Baixar Template",
      importCostCenter: "Importar Centro de Custo",
      cost: "Centro de Custo",
      financial: "Plano Financeiro",
      value: "Valor",
      approval: "Aprovação",
      requester: "Solicitante",
      sector: "Setor",
      approver: "Aprovador",
      approvers: "Aprovadores",
      attachments: "Anexos",
      limit: "máximo 5 anexos",
      paymentVoucher: "Comprovante",
      emptyPaymentVoucher: "Nenhum comprovante anexado",
      errorDownloadVoucher: "Erro ao fazer download do comprovante",
      cancelBtn: "Cancelar",
      saveDraftBtn: "Salvar como rascunho",
      saveBtn: "Solicitar",
      saveResolve: "Resolver",
      savePendency: "Salvar Pendência",
      needsComplianceApproval: "Requer Approvação Compliance",
      secondApprover: "Aprovador",
      Approvermode: "Modo",
      SequentialDescription:
        "Todo aprovador selecionado deve aprovar na ordem que foi escolhido",
      ParallelDescription:
        "Todo aprovador selecionado deve aprovar, em qualquer ordem",
      AnyDescription:
        "O mínimo necessário de aprovadores requeridos pela empresa deve aprovar, em qualquer ordem",
      SequentialLabel: "Sequencial",
      ParallelLabel: "Paralela",
      AnyLabel: "Nenhuma",
      userEmergency: {
        title: "Aprovador emergencial",
        true: "Sim",
        false: "Não",
      },
      userCompliance: {
        title: "Aprovador Compliance",
        true: "Sim",
        false: "Não",
      },
      status: {
        1: "Solicitada",
        2: "Aprovada",
        3: "Negada",
        4: "Exportada",
        5: "Erro",
        6: "Rascunho",
        7: "Cancelada",
        8: "Paga",
        9: "Pendente Emergencial",
        10: "Pendência",
        12: "Aprovação Bancária",
        13: "Aprovação Compliance",
      },
    },
    newFundAdministrator: {
      back: "Voltar",
      newTitle: "Administrador de Fundo - Novo",
      editTitle: "Administrador de Fundo - Edição",
      name: "Nome",
      shortName: "Nome Curto",
      identifier: "Identificador",
      email: "Email de Contato",
      mailGroupLeste: "Email Grupo Leste",
      templatePT: "Modelo de Email PT",
      templateEN: "Modelo de Email EN",
      requiredTemplate: "Preencha pelo menos um modelo de e-mail",
      saveFundAdministrator: "Salvar",
      cancelFundAdministrator: "Cancelar",
    },
    solicitationStatus: {
      allStatusSource: "Todas as fontes",
      allStatus: "All status",
      requested: "Requested",
      approved: "Approved",
      denied: "Denied",
      exported: "Exported",
      error: "Exported error",
      draft: "Draft",
      canceled: "Canceled",
      paid: "Paid",
      emergency: "Pending Emergency",
    },
    user: {
      back: "Voltar",
      title: "Usuários",
      exportPDF: "Exportar PDF",
      exportCSV: "Exportar CSV",
      editTitle: "Editar usuário",
      userName: "Nome:",
      financialUser: "usuário financeiro",
      emergencyApprover: "Aprovador emergencial",
      complianceApprover: "Aprovador compliance",
      emailLanguage: "Língua e-mail aprovação:",
      accessTitle: "Acessos",
      add: "Adicionar novo acesso",
      saveBtn: "Salvar",
      cancelBtn: "Cancelar",
    },
    financialPlain: {
      title: "Plano Financeiro",
      exportPDF: "Exportar PDF",
      exportCSV: "Exportar CSV",
    },
    fundAdministrator: {
      title: "Administrador de Fundos",
      btn: "Novo Administrador de Fundos",
    },
    emergencyModal: {
      title: "Aprovação Emergencial",
      button: "Confirmar",
    },
    modalAttachment: {
      title: "Erro de upload de arquivo",
      closeBtn: "Fechar",
    },
    cancelModal: {
      title: "Deseja cancelar essa solicitação?",
      closeBtn: "Não",
      cancelBtn: "Cancelar",
    },
    denyModal: {
      title: "Deseja negar essa solicitação?",
      closeBtn: "Cancelar",
      denyBtn: "Negar",
    },
    approveModal: {
      title: "Deseja aprovar essa solicitação?",
      closeBtn: "Cancelar",
      approveBtn: "Aprovar",
    },
    bankApprovalModal: {
      title: "Confirmar operação?",
      closeBtn: "Cancelar",
      approveBtn: "Confirmar",
    },
    costCenterModal: {
      title: "Confirmar Exportação?",
      saveBtn: "Exportar",
    },
    approveModalId: {
      title: "Confirma aprovar essas solicitações?",
      manyRequests: "solicitações selecionadas",
      oneRequest: "solicitação selecionada",
      closeBtn: "Cancelar",
      approveBtn: "Aprovar",
    },
    modalImport: {
      titleSuccess: "Planilha importada com sucesso!",
      titleLoading: "Importando......",
      closeBtnCancelAxios: "Cancel Import",
      closeBtnSuccess: "Fechar",
    },
    modalImportError: {
      titleError:
        "Algumas solicitações não foram importadas pelos seguintes erros:",
    },
    payModal: {
      title: "Confirmar pagamento?",
      addAttachment: "Adicionar comprovante",
      closeBtn: "Cancelar",
      payBtn: "Confirmar",
    },
    pendingModal: {
      title: "Pendência",
      message: "Motivo da pendência",
      closeBtn: "Cancelar",
      pendencyBtn: "Confirmar",
    },
    addStatementModal: {
      title: "Deseja adicionar um comprovante?",
      addAttachment: "Adicionar comprovante",
      closeBtn: "Cancelar",
      addStatementBtn: "Confirmar",
    },
    extendPaymentExpirationModal: {
      title: "Extender data de vencimento do pagamento",
      setExpirationDate: "Selecionar nova data de vencimento",
      closeBtn: "Cancelar",
      confirmExpirationDate: "Confirmar",
    },
  },
};

const i18n = new VueI18n({
  locale: "en", // set locale
  fallbackLocale: ["pt", "en"], // set fallback locale
  formatFallbackMessages: true,
  silentFallbackWarn: true,
  messages, // set locale messages
});

export default new VueI18n({
  messages,
});
