











































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { Watch, Prop } from 'vue-property-decorator';
import Endpoint from '@/utils/endpoint';

import { AuthService } from '../../services/authService';
import SolicitationId from '@/@types/solicitationId';
import FeedbackInterface from '@/@types/feedback';
import Header from '@/@types/header';
import ModalErrorPt from '@/components/headers/pt-br/modalError-headers';
import ModalError from '@/components/headers/modalError - header';

import ErrorImport from '@/@types/errorsImport';

// import SweetalertIcon from 'vue-sweetalert-icons';

import qs from 'qs';

const namespace = 'add';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({ components: {} })
export default class modalImportRequest extends Vue {
  public headersPT: Header[] = ModalErrorPt.header; //idioma
  public headers: Header[] = ModalError.header; //idioma

  @Getter('getSolicitationId', { namespace: 'auth' })
  public solicitation!: SolicitationId;

  @Getter('getModalImportError', { namespace: 'auth' })
  public modalImportError!: boolean;
  @Action('setModalImportError', { namespace: 'auth' })
  public setModalImportError!: any;

  @Getter('getFeedback', { namespace: 'auth' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'auth' }) public setFeedback: any;

  @Getter('getUserLanguage', { namespace: 'auth' }) public userLanguage!: any;
  @Action('setUserLanguage', { namespace: 'auth' }) public setUserLanguage: any;

  @Prop(Array) importList?: any[];
  @Prop(Array) errorsImport?: ErrorImport[];

  @Prop(Boolean) IsACostCenter?: boolean;

  @Watch('closeModal')
  handleCloseModal() {
    this.importList = [];
  }

  loading = false;
  vouchername = '';

  items = [
    {
      linha: '0',
      campo: 'CompanyNfe',
      error: 'Campo não preenchido',
    },
    {
      linha: '2',
      campo: 'PaymentMethod',
      error: 'PaymentMethod não preenchido',
    },
    {
      linha: '4',
      campo: 'Comments',
      error: 'Comments não preenchido',
    },
  ];

  private endpoint = new Endpoint();
  authService = new AuthService();

  public getNameAttachmentUrl(item: string) {
    if (item !== null) {
      const checkIsItString = item.includes('Error was:');
      if (checkIsItString) {
        const response = item.split(':');
        const nameAttachment = response.splice(-1).toString();
        return nameAttachment;
      } else {
        return item;
      }
    }
  }

  closeModal(): void {
    this.setModalImportError(false);
  }
}
