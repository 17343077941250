export default interface feedback {
  feedback: boolean;
  color: string | null;
  text: string | null;
}

const feedbackInitialValue: feedback = {
  feedback: false,
  color: '',
  text: '',
};

export { feedbackInitialValue };
