

















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import Endpoint from '@/utils/endpoint';

import { AuthService } from '../../services/authService';

const namespace = 'add';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({})
export default class modalEmergencyApproval extends Vue {
  @Getter('getModalEmergencyApproval', { namespace: 'auth' })
  public modalEmergencyApproval!: boolean;
  @Action('setModalEmergencyApproval', { namespace: 'auth' })
  public setModalEmergencyApproval!: any;

  @Getter('getUserLanguage', { namespace: 'auth' }) public userLanguage!: any;
  @Action('setUserLanguage', { namespace: 'auth' }) public setUserLanguage: any;

  loading = false;
  remainingDays = '';

  private endpoint = new Endpoint();
  authService = new AuthService();

  public async getEmergencialDate() {
    const response = await this.$http.get(this.endpoint.emergencialDays);
    this.remainingDays = response;
  }

  closeModal(): void {
    this.setModalEmergencyApproval(false);
  }

  public mounted() {
    this.getEmergencialDate();
  }
}
