export default interface UsersCompanies {
  companyId: number;
  companyName: string;
  accessType: number;
}

const usersCompaniesInitialValue: UsersCompanies = {
  companyId: 0,
  companyName: '',
  accessType: 0,
};

export { usersCompaniesInitialValue };
