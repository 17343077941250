















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import ErrorResponse from "@/@types/ErrorResponse";
import SelectApprover, { EmptyApprover } from "@/@types/approver";
import User from "@/@types/auth/user";
import Company, { companyInitialValue } from "@/@types/companies";
import CostCenter from "@/@types/costCenter";
import CostCenterImport from "@/@types/costCenterImport";
import CreditorSelected, {
  creditorSelectedInitalValue,
} from "@/@types/creditorSelected";
import Creditor from "@/@types/creditors";
import ErrorImport from "@/@types/errorsImport";
import FeedbackInterface from "@/@types/feedback";
import FinancialBudget from "@/@types/financialPlain";
import RequestPayload from "@/@types/payload";
import costCenter from "@/@types/paymentRequest/costCenter";
import CostCenters from "@/@types/paymentRequest/costCenters";
import CostCentersPayment from "@/@types/paymentRequest/costCentersPayment";
import DocumentType from "@/@types/paymentRequest/documentType";
import financialPlain from "@/@types/paymentRequest/financialPlain";
import FinancialPlain from "@/@types/paymentRequest/financialPlainSelected";
import PaymentMethod from "@/@types/paymentRequest/paymentMethod";
import SolicitationArchives from "@/@types/solicitation-archives";
import ModalAttachmentError from "@/components/requestActions/modalAttachmentError.vue";
import ModalImportError from "@/components/requestActions/modalImportError.vue";
import ModalImportRequest from "@/components/requestActions/modalImportRequest.vue";
import ModalPending from "@/components/requestActions/modalPending.vue";
import Endpoint from "@/utils/endpoint";
import SolicitationsAction from "@/utils/solicitationActions";
import moment from "moment";
import { Money } from "v-money";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { mask } from "vue-the-mask";
import { Action, Getter } from "vuex-class";
import { AuthService } from "../../services/authService";
import axios from "axios";

// interface CostCenter {
//   costCenter: string;
//   financialBudget: string;
//   value: number;
// }

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({
  directives: { mask },
  components: {
    Money,
    ModalPending,
    ModalAttachmentError,
    ModalImportError,
    ModalImportRequest,
  },
})
export default class InnerRequest extends Vue {
  @Getter("getUser", { namespace: "auth" }) public user!: User;
  @Action("setUser", { namespace: "auth" }) public setUser: any;

  @Getter("getFeedback", { namespace: "auth" })
  public feedback!: FeedbackInterface;
  @Action("setFeedback", { namespace: "auth" }) public setFeedback: any;

  @Getter("getModalEmergencyApproval", { namespace: "auth" })
  public modalEmergencyApproval!: boolean;
  @Action("setModalEmergencyApproval", { namespace: "auth" })
  public setModalEmergencyApproval!: any;

  @Getter("getModalAttachmentError", { namespace: "auth" })
  public modalAttachmentError!: boolean;
  @Action("setModalAttachmentError", { namespace: "auth" })
  public setModalAttachmentError!: any;

  @Getter("getStatusPending", { namespace: "auth" })
  public statusPending!: boolean;
  @Action("setStatusPending", { namespace: "auth" })
  public setStatusPending!: any;

  @Getter("getModalPending", { namespace: "auth" })
  public modalPending!: boolean;
  @Action("setModalPending", { namespace: "auth" })
  public setModalPending!: any;

  @Getter("getModalImportError", { namespace: "auth" })
  public modalImportError!: boolean;
  @Action("setModalImportError", { namespace: "auth" })
  public setModalImportError!: any;

  @Getter("getModalImportCSV", { namespace: "auth" })
  public modalImportCSV!: boolean;
  @Action("setModalImportCSV", { namespace: "auth" })
  public setModalImportCSV!: any;

  @Getter("getUserLanguage", { namespace: "auth" }) userLanguage!: any;
  @Action("setUserLanguage", { namespace: "auth" }) setUserLanguage: any;

  @Prop({ default: 0 }) id!: number;

  @Watch("expirationDate")
  public verifyData() {
    this.verifyEmergencialPayment();
  }

  @Watch("currentFile")
  public setFile() {
    this.setFileUploadName();
    if (this.currentFile.size > 0) {
      this.showAttachmentsError = false;
    }
  }

  @Watch("grossValue")
  public setFirstValue() {
    if (this.costCentersPayment.length == 1)
      this.costCentersPayment[0].value = this.grossValue;
  }

  @Watch("costCenterXLS")
  public handleCostCenterXLS() {
    if (this.costCenterXLS.size !== 0) {
      this.postCostCenterImport();
    }
  }

  requiredRules: Array<any> = [
    (v: any): boolean | string => {
      if (v) {
        if (typeof v === "string" && v.trim().length === 0)
          return "Required field";
        return true;
      }
      if (v === false) return true;
      if (v === 0) return true;

      return "Required Field";
    },
  ];

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  showAttachmentsError = false;

  loadingDraft = false;
  loadingPendency = false;
  loadingSave = false;
  loadingCopy = false;
  loadingApprover = false;
  loadingEdit = false;
  loadingImport = false;
  Observation: CostCenters[] = [];
  request = {};

  company: Company[] = [];
  companySelected: Company = companyInitialValue;
  companySelectedFundAdministrator: Company = companyInitialValue;
  searchCompany = null;
  companyNFe = [];
  companyNFeSelected = { name: "", cnpj: "", id: 0 };
  creditor: Creditor[] = [];
  creditorSelected: CreditorSelected = creditorSelectedInitalValue;
  document: DocumentType[] = [];
  documentSelected = { name: "", siengeValue: "", abbreviation: "", id: 0 };
  paymentForm: PaymentMethod[] = [];
  paymentFormSelected = { name: "", siengeValue: "", id: 0 };
  documentNumber = "";
  grossValue = "";
  currencyItems = ["GBP", "BRL", "EUR", "USD", "CAD"];
  currency = "USD";
  paymentType = [];
  paymentTypeSelected = {
    name: "",
    siengeValue: "",
    id: null,
  };
  bankName = "";
  bankAgency = "";
  bankAccount = "";
  bankDigit = "";
  barCode = "";
  contributor = { name: "", cpfCnpj: "", id: 0 };
  revenueCode = "";
  emission = false;
  emissionDate = "";
  expiration = false;
  expirationDate = "";
  observations = "";
  voucherEmail = "";
  recursiveRequest = [
    { text: "Sim", value: true },
    { text: "Não", value: false },
  ];
  recursiveRequestSelected = { text: "Não", value: false };
  costCenter: CostCenter[] = [];
  costCenterSelected = {
    name: "",
    siengeValue: "",
    companyName: "",
    enabled: false,
    id: 0,
  };
  financialBudget: FinancialPlain[] = [];
  financialBudgetSelected = { name: "", siengeValue: "", id: 0 };
  value = "";

  costCentersPayment: CostCentersPayment[] = [];
  costCenterImportData: CostCenterImport[] = [];
  costCenterDataError: string[] = [];
  errorsImport: ErrorImport[] = [];
  IsACostCenter = false;
  data = 0;
  costCenterXLS: Blob = new Blob();
  requester = { name: "", id: 0 };
  sector = "";
  origin = "";
  approver: SelectApprover[] = [];
  approverSelected: number[] = [];
  secondApproverSelected = EmptyApprover;
  modes: { id: number; type: string }[] = [];
  modeSelected = 3;
  complianceApproval = false;
  bank = "";
  account = "";
  agency = "";
  digit = "";
  complementValue = 0;

  paymentSelected = "";
  referenceDate = "";
  reference = false;
  currentFile: Blob = new Blob();
  attachments: Blob[] = [];
  attachmentsName: any[] = [];
  base64: string = "";
  deleteArchives: any[] = [];

  messageError = "";

  real = {
    decimal: ",",
    thousands: ".",
    precision: 2,
  };

  dolar = {
    decimal: ".",
    thousands: ",",
    precision: 2,
  };

  libra = {
    decimal: ".",
    thousands: ",",
    precision: 2,
  };

  euro = {
    decimal: ".",
    thousands: ",",
    precision: 2,
  };

  canadianDollar = {
    decimal: ".",
    thousands: ",",
    precision: 2,
  };

  isCopy = false;
  isEdit = false;
  statusPendingViewBtn = true;
  toRequest() {
    this.$router.replace({
      path: "/requests",
    });
  }

  private endpoint = new Endpoint();
  public authService = new AuthService();
  public solicitationsAction = new SolicitationsAction();

  public async saveRequest() {
    if (!this.form.validate()) {
      if (this.currentFile.size === 0 && this.attachmentsName.length === 0) {
        this.showAttachmentsError = true;
      }
      return;
    }

    const emissionDate = moment(this.emissionDate).format("YYYY-MM-DD");
    const expirationDate = moment(this.expirationDate).format("YYYY-MM-DD");
    const referenceDate =
      this.referenceDate === "" || this.referenceDate === null
        ? null
        : moment(this.referenceDate).format("YYYY-MM-DD");
    const paymentType =
      this.paymentTypeSelected.id === 0 ? null : this.paymentTypeSelected.id;
    const taxPayer = this.contributor.id === 0 ? null : this.contributor.id;
    const id = this.isCopy ? 0 : this.id;

    const formData = new FormData();

    this.attachments.forEach((x) => formData.append("file", x));

    const params: RequestPayload = {
      id: id,
      companyPaymentId: this.companySelected.id,
      companyNfe: this.companySelected.name,
      companyNfeCnpj: this.companySelected.cnpj,
      creditorId: this.creditorSelected.id,
      documentTypeId: this.documentSelected.id,
      paymentMethodId: this.paymentFormSelected.id,
      paymentTypeId: paymentType,
      documentNumber: this.documentNumber,
      grossValue: this.grossValue.toString(),
      currency: this.currency,
      emissionDate: emissionDate,
      expirationDate: expirationDate,
      observation: this.observations,
      thirdPartEmail: this.voucherEmail,
      recursive: false,
      costCenters: [] = this.costCentersPayment.map((item) => ({
        costCenterId: item.costCenterSelected.id,
        financialBudgetId: item.financialBudgetSelected.id,
        value: item.value.toString(),
      })),
      status: 6,
      taxPayerId: taxPayer,
      taxPayerCnpj: this.contributor.cpfCnpj,
      federalRevenueCode: this.revenueCode,
      darfReferenceDate: referenceDate,
      editableRow: this.barCode,
      bank: this.creditorSelected.bank,
      agency: this.creditorSelected.agency,
      account: this.creditorSelected.account,
      agencyDigit: this.creditorSelected.agencyDigit,
      accountDigit: this.creditorSelected.accountDigit,
      approvalModeId: this.modeSelected,
      approverId: 0,
      approversId: this.approverSelected,
      requesterId: this.requester.id,
      complianceApprovalRequired: this.complianceApproval,
      secondApproverId: undefined,
    };
    this.removeUploadedFiles();
    if (this.companySelected.requiresSecondApprover)
      params.secondApproverId = this.secondApproverSelected.id;

    if (this.currentFile.size !== 0 || this.attachmentsName.length > 0) {
      try {
        this.loadingSave = true;
        const token = await this.authService.getTokenForMenuAsync();
        const response = await this.$http.post(
          this.endpoint.solicitation,
          params,
          {
            headers: {
              Authorization: token,
            },
          },
        );
        this.sendFiles(response, false);
      } catch (error) {
        const errorData = error as ErrorResponse;
        this.loadingSave = false;
        this.setFeedback({
          feedback: true,
          color: "red",
          text:
            errorData.response.status === 400
              ? `${errorData.response.data}`
              : "Ocorreu um erro",
        });
        setTimeout((): void => {
          this.setFeedback({
            feedback: false,
          });
        }, 4000);
      }
    } else {
      this.showAttachmentsError = true;
    }
  }
  types: any = {
    txt: { icon: "mdi-text-box", color: "blue-grey lighten-1" },
    pdf: { icon: "mdi-file-pdf-box", color: "red darken-3" },
    docx: { icon: "mdi-file-word", color: "cyan darken-2" },
    xlsx: { icon: "mdi-file-excel", color: "green darken-1" },
    csv: { icon: "mdi-file-excel", color: "green darken-1" },
    pptx: { icon: "mdi-file-word", color: "green darken-1" },
    key: { icon: "mdi-file-key", color: "yellow accent-2" },
    png: { icon: "mdi-file-png-box", color: "blue" },
    jpeg: { icon: "mdi-file-jpg-box", color: "blue" },
    default: { icon: "mdi-file-image", color: "light-blue " },
  };

  mappedFiles(file: any) {
    const type = this.types[file.name.split(".")[1]] || this.types["default"];

    return {
      name: file.name,
      icon: type.icon,
      color: type.color,
    };
  }

  public verifyEmergencialPayment() {
    const expirationDate = moment(this.expirationDate).format("YYYY-MM-DD");
    const emergenceDays = 8; //TODO GetEmergenceDays from token
    const limitEmergenceDate = moment()
      .add(emergenceDays, "days")
      .format("YYYY-MM-DD");

    if (
      expirationDate < limitEmergenceDate &&
      this.companySelected.fundAdministratorName === null
    ) {
      this.setModalEmergencyApproval(true);
    }
  }

  public setDocumentTypeForFundAdministrator() {
    const especificDocumentType = this.document.find(
      (item) => item.name === "INTERNO",
    ) as DocumentType;

    const especificPaymentMethod = this.paymentForm.find(
      (item) => item.id === 36,
    ) as PaymentMethod;
    if (
      this.companySelected.fundAdministratorName !== "" &&
      this.companySelected.fundAdministratorExternalAdm
    ) {
      this.documentSelected = especificDocumentType;
      this.paymentFormSelected = especificPaymentMethod;
    }
  }

  public handleBlockDocumentType() {
    const especificDocumentType = this.document.find(
      (item) => item.name === "INTERNO",
    ) as PaymentMethod;

    especificDocumentType["disabled"] = true;
  }

  public handleBlockPaymentMethod() {
    const especificPaymentMethod = this.paymentForm.find(
      (item) => item.id === 36,
    ) as PaymentMethod;

    especificPaymentMethod["disabled"] = true;
  }

  public async saveDraft() {
    const emissionDate = moment(this.emissionDate).format("YYYY-MM-DD");
    const expirationDate = moment(this.expirationDate).format("YYYY-MM-DD");
    // console.log('anteeees: ', this.referenceDate);
    const referenceDate =
      this.referenceDate === "" || this.referenceDate === null
        ? null
        : moment(this.referenceDate).format("YYYY-MM-DD");
    const paymentType =
      this.paymentTypeSelected.id === 0 ? null : this.paymentTypeSelected.id;
    const taxPayer = this.contributor.id === 0 ? null : this.contributor.id;
    const id = this.isCopy ? 0 : this.id;

    const params: RequestPayload = {
      id: id,
      companyPaymentId: this.companySelected.id,
      companyNfe: this.companySelected.name,
      companyNfeCnpj: this.companySelected.cnpj,
      creditorId: this.creditorSelected.id,
      documentTypeId: this.documentSelected.id,
      paymentMethodId: this.paymentFormSelected.id,
      paymentTypeId: paymentType,
      documentNumber: this.documentNumber,
      grossValue: this.grossValue.toString(),
      currency: this.currency,
      emissionDate: emissionDate,
      expirationDate: expirationDate,
      observation: this.observations,
      thirdPartEmail: this.voucherEmail,
      recursive: false,
      costCenters: [] = this.costCentersPayment.map(function(item) {
        return {
          costCenterId: item.costCenterSelected.id,
          financialBudgetId: item.financialBudgetSelected.id,
          value: item.value.toString(),
        };
      }),
      status: 6,
      taxPayerId: taxPayer,
      taxPayerCnpj: this.contributor.cpfCnpj,
      federalRevenueCode: this.revenueCode,
      darfReferenceDate: referenceDate,
      editableRow: this.barCode,
      bank: this.creditorSelected.bank,
      agency: this.creditorSelected.agency,
      account: this.creditorSelected.account,
      agencyDigit: this.creditorSelected.agencyDigit,
      accountDigit: this.creditorSelected.accountDigit,
      approverId: 0,
      approversId: this.approverSelected,
      requesterId: this.requester.id,
      complianceApprovalRequired: this.complianceApproval,
      secondApproverId: undefined,
      approvalModeId: this.modeSelected,
    };

    this.removeUploadedFiles();
    if (this.companySelected.requiresSecondApprover)
      params.secondApproverId = this.secondApproverSelected.id;
    try {
      this.loadingDraft = true;
      const token = await this.authService.getTokenForMenuAsync();
      const response = this.isCopy
        ? await this.$http.post(this.endpoint.saveDraft, params, {
            headers: { Authorization: token },
          })
        : await this.$http.put(this.endpoint.saveDraft, params, {
            headers: { Authorization: token },
          });
      this.sendFiles(response, true);
    } catch (error) {
      const errorData = error as ErrorResponse;
      this.loadingDraft = false;
      console.error(error);
      this.setFeedback({
        feedback: true,
        color: "red",
        text:
          errorData.response.status === 400
            ? `${errorData.response.data}`
            : "Ocorreu um erro",
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    }
  }

  public async savePendency(message: string) {
    const emissionDate = moment(this.emissionDate).format("YYYY-MM-DD");
    const expirationDate = moment(this.expirationDate).format("YYYY-MM-DD");

    const referenceDate =
      this.referenceDate === "" || this.referenceDate === null
        ? null
        : moment(this.referenceDate).format("YYYY-MM-DD");
    const paymentType =
      this.paymentTypeSelected.id === 0 ? null : this.paymentTypeSelected.id;
    const taxPayer = this.contributor.id === 0 ? null : this.contributor.id;
    const id = this.isCopy ? 0 : this.id;

    const params: RequestPayload = {
      id: id,
      companyPaymentId: this.companySelected.id,
      companyNfe: this.companySelected.name,
      companyNfeCnpj: this.companySelected.cnpj,
      creditorId: this.creditorSelected.id,
      documentTypeId: this.documentSelected.id,
      paymentMethodId: this.paymentFormSelected.id,
      paymentTypeId: paymentType,
      documentNumber: this.documentNumber,
      grossValue: this.grossValue.toString(),
      currency: this.currency,
      emissionDate: emissionDate,
      expirationDate: expirationDate,
      observation: this.observations,
      thirdPartEmail: this.voucherEmail,
      recursive: false,
      costCenters: [] = this.costCentersPayment.map(function(item) {
        return {
          costCenterId: item.costCenterSelected.id,
          financialBudgetId: item.financialBudgetSelected.id,
          value: item.value.toString(),
        };
      }),
      status: 10,
      taxPayerId: taxPayer,
      taxPayerCnpj: this.contributor.cpfCnpj,
      federalRevenueCode: this.revenueCode,
      darfReferenceDate: referenceDate,
      editableRow: this.barCode,
      bank: this.creditorSelected.bank,
      agency: this.creditorSelected.agency,
      account: this.creditorSelected.account,
      agencyDigit: this.creditorSelected.agencyDigit,
      accountDigit: this.creditorSelected.accountDigit,
      approverId: 0,
      approversId: this.approverSelected,
      requesterId: this.requester.id,
      message: message,
      complianceApprovalRequired: this.complianceApproval,
      secondApproverId: undefined,
      approvalModeId: this.modeSelected,
    };

    this.removeUploadedFiles();
    if (this.companySelected.requiresSecondApprover)
      params.secondApproverId = this.secondApproverSelected.id;
    try {
      this.loadingPendency = true;
      const token = await this.authService.getTokenForMenuAsync();
      const response = await this.$http.put(this.endpoint.saveDraft, params, {
        headers: { Authorization: token },
      });
      this.sendFilesResolve(response, message);
    } catch (error) {
      const errorData = error as ErrorResponse;
      this.loadingPendency = false;
      this.closeModalPending();
      console.error(error);
      this.setFeedback({
        feedback: true,
        color: "red",
        text:
          errorData.response.status === 400
            ? `${errorData.response.data}`
            : "Ocorreu um erro",
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    }
  }

  public openModalPending() {
    this.setModalPending(true);
  }

  public closeModalPending() {
    this.setModalPending(false);
  }

  public async saveResolve() {
    const emissionDate = moment(this.emissionDate).format("YYYY-MM-DD");
    const expirationDate = moment(this.expirationDate).format("YYYY-MM-DD");

    const referenceDate =
      this.referenceDate === "" || this.referenceDate === null
        ? null
        : moment(this.referenceDate).format("YYYY-MM-DD");
    const paymentType =
      this.paymentTypeSelected.id === 0 ? null : this.paymentTypeSelected.id;
    const taxPayer = this.contributor.id === 0 ? null : this.contributor.id;
    const id = this.isCopy ? 0 : this.id;

    const params: RequestPayload = {
      id: id,
      companyPaymentId: this.companySelected.id,
      companyNfe: this.companySelected.name,
      companyNfeCnpj: this.companySelected.cnpj,
      creditorId: this.creditorSelected.id,
      documentTypeId: this.documentSelected.id,
      paymentMethodId: this.paymentFormSelected.id,
      paymentTypeId: paymentType,
      documentNumber: this.documentNumber,
      grossValue: this.grossValue.toString(),
      currency: this.currency,
      emissionDate: emissionDate,
      expirationDate: expirationDate,
      observation: this.observations,
      thirdPartEmail: this.voucherEmail,
      recursive: false,
      costCenters: [] = this.costCentersPayment.map(function(item) {
        return {
          costCenterId: item.costCenterSelected.id,
          financialBudgetId: item.financialBudgetSelected.id,
          value: item.value.toString(),
        };
      }),
      status: 10,
      taxPayerId: taxPayer,
      taxPayerCnpj: this.contributor.cpfCnpj,
      federalRevenueCode: this.revenueCode,
      darfReferenceDate: referenceDate,
      editableRow: this.barCode,
      bank: this.creditorSelected.bank,
      agency: this.creditorSelected.agency,
      account: this.creditorSelected.account,
      agencyDigit: this.creditorSelected.agencyDigit,
      accountDigit: this.creditorSelected.accountDigit,
      approverId: 0,
      approversId: this.approverSelected,
      requesterId: this.requester.id,
      complianceApprovalRequired: this.complianceApproval,
      secondApproverId: undefined,
      approvalModeId: this.modeSelected,
    };

    this.removeUploadedFiles();
    if (this.companySelected.requiresSecondApprover)
      params.secondApproverId = this.secondApproverSelected.id;
    try {
      this.loadingDraft = true;
      const token = await this.authService.getTokenForMenuAsync();
      const response = await this.$http.put(this.endpoint.resolve, params, {
        headers: { Authorization: token },
      });
      this.sendFilesResolve(response, "");
    } catch (error) {
      const errorData = error as ErrorResponse;
      this.loadingDraft = false;
      this.statusPendingViewBtn = false;
      console.error(error);
      this.setFeedback({
        feedback: true,
        color: "red",
        text:
          errorData.response.status === 400
            ? `${errorData.response.data}`
            : "Ocorreu um erro",
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    }
  }

  public getArchive() {
    if (this.currentFile.size === 0) {
      return;
    } else {
      this.attachments.push(this.currentFile);
    }
  }

  public setFilesToUpload() {
    if (this.currentFile.size !== 0) {
      const formData = new FormData();
      this.attachments.forEach((x) => formData.append("file", x));

      return formData;
    } else {
      return;
    }
  }

  public setFileUploadName() {
    // @ts-ignore
    const fileInput = document.querySelector("#new-attachment").value;
    const fileName = fileInput.split(/(\\|\/)/g).pop();

    this.attachmentsName.push({ name: fileName, isLocal: true });
  }

  public async sendFilesResolve(id: number, message: string) {
    const formData = this.setFilesToUpload();
    try {
      const response = await this.$http.post(
        this.endpoint.uploadFile + "?id=" + id,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      this.setFeedback({
        feedback: true,
        color: "green",
        text:
          message === ""
            ? this.userLanguage === "en"
              ? "Pendency resolved"
              : "Pendência resolvida"
            : this.userLanguage === "en"
            ? "Pendency saved"
            : "Pendência enviada",
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);

      this.toRequest();
      this.loadingSave = false;
      this.loadingPendency = false;
      if (this.loadingPendency === false) {
        this.closeModalPending();
      }
      this.loadingDraft = false;
      this.statusPendingViewBtn = false;
    } catch (error) {
      const errorData = error as ErrorResponse;
      this.loadingSave = false;
      this.loadingPendency = false;
      if (this.loadingPendency === false) {
        this.closeModalPending();
      }
      this.loadingDraft = false;
      this.statusPendingViewBtn = false;
      console.error(error);
      if (errorData.response.status === 400) {
        this.messageError = errorData.response.data;
        this.setModalAttachmentError(true);
      } else {
        this.setFeedback({
          feedback: true,
          color: "red",
          text: "Ocorreu um erro",
        });
        setTimeout((): void => {
          this.setFeedback({
            feedback: false,
          });
        }, 5000);
      }
    }
  }

  public async sendFiles(id: number, isDraft: boolean) {
    const formData = this.setFilesToUpload();
    try {
      const response = await this.$http.post(
        this.endpoint.uploadFile + "?id=" + id,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      this.setFeedback({
        feedback: true,
        color: "green",
        text: isDraft
          ? this.userLanguage === "en"
            ? "Draft saved"
            : "Rascunho salvo"
          : this.userLanguage === "en"
          ? "Request sent"
          : "Solicitação enviada",
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);

      this.toRequest();
      this.loadingSave = false;
      this.loadingDraft = false;
    } catch (error) {
      const errorData = error as ErrorResponse;
      this.loadingSave = false;
      this.loadingDraft = false;
      console.error(error);
      if (errorData.response.status === 400) {
        this.messageError = errorData.response.data;
        this.setModalAttachmentError(true);
      } else {
        this.setFeedback({
          feedback: true,
          color: "red",
          text: "Ocorreu um erro",
        });
        setTimeout((): void => {
          this.setFeedback({
            feedback: false,
          });
        }, 5000);
      }
    }
  }

  public handleBlockCreditor() {
    const paymentFoundCreditors = this.creditor.filter(
      (item) => item.siengeValue === null,
    ) as Creditor[];
    if (
      this.companySelected.siengeId !== null &&
      this.companySelected.siengeId !== undefined
    ) {
      paymentFoundCreditors.forEach((item) => (item["disabled"] = true));
      const currentCreditor = paymentFoundCreditors.find(
        (item) => item.name === this.creditorSelected.name,
      );
      if (currentCreditor !== undefined) {
        this.creditorSelected = {
          name: "",
          cpfCnpj: "",
          bank: "",
          agency: "",
          account: "",
          agencyDigit: "",
          accountDigit: "",
          id: 0,
        };
      }
    } else {
      paymentFoundCreditors.forEach((item) => (item["disabled"] = false));
    }
  }

  public removeAttachments(item: Blob, index: any) {
    if (item.hasOwnProperty("isLocal")) {
      this.attachments.splice(index, 1);
      this.attachmentsName.splice(this.attachmentsName.indexOf(item), 1);
    } else {
      this.attachmentsName.splice(this.attachmentsName.indexOf(item), 1);
      this.setUploadedFilesToDelete(item);
    }
  }

  public setUploadedFilesToDelete(item: Blob) {
    // @ts-ignore
    this.deleteArchives.push(item.path);
  }

  public async removeUploadedFiles() {
    try {
      if (this.deleteArchives.length > 0) {
        const response = await this.$http.delete(this.endpoint.deleteFiles, {
          data: {
            paymentRequestId: this.id,
            urlFiles: this.deleteArchives,
          },
        });
      }
    } catch (error) {
      console.log("Ocorreu um erro ao remover arquivos");
    }
  }

  async getRequestById() {
    this.isEdit = true;
    const token = await this.authService.getTokenForMenuAsync();
    const response = await this.$http.get(
      this.endpoint.solicitation + `/${this.id}`,
      {
        headers: { Authorization: token },
      },
    );
    const gettedCompany = this.company.find(
      (item) => item.id === response.companyId,
    ) as Company;

    this.request = response;
    this.id = response.id;
    if (gettedCompany) {
      this.companySelected = {
        name: gettedCompany.name,
        cnpj: gettedCompany.cnpj,
        id: gettedCompany.id,
        siengeId: gettedCompany.siengeId,
        corporativeId: gettedCompany.corporativeId,
        fundAdministratorId: gettedCompany.fundAdministratorId,
        fundAdministratorName: gettedCompany.fundAdministratorName,
        enabled: gettedCompany.enabled,
        requiresSecondApprover: gettedCompany.requiresSecondApprover,
      } as Company;
    }
    await this.getCostCenters(this.companySelected.id);
    await this.getApprovers(this.companySelected.id);

    this.approverSelected = response.approvers.map((a: SelectApprover) => a.id);
    if (gettedCompany.requiresSecondApprover) {
      this.secondApproverSelected = {
        approverName: response.secondApproverName,
        departmentName: "",
        id: response.secondApproverId,
      };
    }
    this.loadingApprover = false;
    this.modeSelected = response.approvalModeId;
    this.companyNFeSelected.name = response.companyNfeName;
    this.companyNFeSelected.cnpj = response.companyNfeCNPJ;
    this.complianceApproval = response.complianceApprovalRequired;
    this.creditorSelected = {
      name: response.creditorName,
      cpfCnpj: response.creditorCpfCnpj,
      id: response.creditorId,
      bank: response.bank,
      agency: response.agency,
      account: response.account,
      agencyDigit: response.agencyDigit,
      accountDigit: response.accountDigit,
    };
    this.documentSelected = {
      name: response.documetTypeName,
      siengeValue: "",
      abbreviation: "",
      id: response.documentTypeId,
    };
    this.documentNumber = response.documentNumber;
    this.paymentFormSelected = {
      name: response.paymentMethodName,
      siengeValue: "",
      id: response.paymentMethodId,
    };
    (this.grossValue = response.grossValue),
      (this.emissionDate = response.emissionDate);
    this.expirationDate = response.expirationDate;
    this.observations = response.observations;
    this.voucherEmail = response.thirdPartEmail;

    this.costCenterSelected = {
      name: response.costCenters.costCenterName,
      siengeValue: "",
      companyName: "",
      enabled: false,
      id: response.costCenterId,
    };
    this.costCentersPayment = response.costCenters.map(
      (item: CostCentersPayment) => {
        return {
          costCenterSelected: this.costCenter.find(
            (x: costCenter) => x.id === item.costCenterId,
          ),
          financialBudgetSelected: this.financialBudget.find(
            (x: financialPlain) => x.id === item.financialBudgetId,
          ),
          value: item.value,
        };
      },
    );

    this.attachmentsName = response.attachments.map(
      (x: SolicitationArchives) => ({ name: x.fileName, path: x.urlFile }),
    );
    this.setCurrency(response.currency);
    this.requester = { name: response.requesterName, id: response.requesterId };
    this.revenueCode = response.federalRevenueCode;
    this.referenceDate = response.darfReference;
    this.contributor = {
      name: response.taxPayerName,
      cpfCnpj: response.taxPayerCnpj,
      id: response.taxPayerId,
    };
    this.referenceDate = response.darfReferenceDate;
    this.barCode = response.editableRow;
    this.paymentTypeSelected = {
      name: response.paymentTypeName,
      id: response.paymentTypeId,
      siengeValue: response.paymentTypeSiengeValue,
    };
    this.loadingCopy = false;
    this.loadingEdit = false;
    this.setCompanyFundAdministrator();
    this.handleBlockPaymentMethod();
    this.handleBlockDocumentType();
    this.applyCompanySelectedWatcher();
  }

  public downloadCSVTemplate() {
    var anchor = document.createElement("a");
    anchor.setAttribute("href", "/files/Template-import-cost-centers.xlsx");
    anchor.setAttribute("download", "");
    document.body.appendChild(anchor);
    anchor.click();
    (anchor.parentNode as HTMLAnchorElement).removeChild(anchor);
  }

  public async postCostCenterImport() {
    try {
      this.loadingImport = true;
      this.setModalImportCSV(true);
      const formData = this.setFilesToUploadCostCenter();
      const token = await this.authService.getTokenForMenuAsync();
      const response = await this.$http.post(
        this.endpoint.costCenterImport +
          `/${this.companySelected.id}/fill-cost-centers`,
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        },
      );
      this.costCenterImportData = response;
      this.costCentersPayment = [];
      for (var i = 0; i < this.costCenterImportData.length; i++) {
        if (!this.costCenterImportData[i].success) {
          this.setErrorsImport(this.costCenterImportData[i]);
        } else {
          this.add();
          this.costCentersPayment.map((item, index) => {
            item.costCenterSelected = this.costCenter.find(
              (x) => x.id === this.costCenterImportData[index].costCenterId,
            ) as CostCenter;
            item.financialBudgetSelected = this.financialBudget.find(
              (x: FinancialPlain) =>
                x.id === this.costCenterImportData[index].financialBudgetId,
            ) as FinancialBudget;
            item.value = this.costCenterImportData[index].value;
          });
        }
      }

      this.loadingImport = false;

      if (this.errorsImport.length > 0) {
        this.IsACostCenter = true;
        this.setModalImportError(true);
      }
    } catch (error) {
      this.loadingImport = false;
      console.log("Error cost ", error);
    } finally {
      this.data++;
    }

    const totalAmmount = parseFloat(this.grossValue);
    if (totalAmmount == 0) {
      var teste = 0;
      this.costCentersPayment.forEach(
        (x) => (teste += parseFloat(x.value.replace(",", ""))),
      );
      this.grossValue = parseFloat(teste.toString()).toFixed(2);
    }
  }

  public setFilesToUploadCostCenter() {
    const formData = new FormData();

    formData.append("file", this.costCenterXLS);

    return formData;
  }

  public setErrorsImport(item: CostCenterImport) {
    this.errorsImport = [
      ...this.errorsImport,
      ...item.errors.map((error) => ({
        position: item.documentLine,
        error,
      })),
    ];
  }

  public applyCompanySelectedWatcher() {
    this.$watch("companySelected", async (newVal, oldVal) => {
      this.getCostCenters(this.companySelected.id);
      this.setCompanyFundAdministrator();
      this.creditorSelected = {
        name: "",
        cpfCnpj: "",
        bank: "",
        agency: "",
        account: "",
        agencyDigit: "",
        accountDigit: "",
        id: 0,
      };
      this.loadingApprover = true;
      this.approverSelected = [];
      this.secondApproverSelected = EmptyApprover;
      await this.getApprovers(this.companySelected.id);
      this.loadingApprover = false;
      this.handleBlockCreditor();
    });
  }

  async copyRequest() {
    this.isCopy = true;
    // this.loadingCopy = true;
    const token = await this.authService.getTokenForMenuAsync();
    const response = await this.$http.get(
      this.endpoint.copySolicitation + `/${this.id}`,
      {
        headers: { Authorization: token },
      },
    );

    this.requester.name = this.user.name;
    const gettedCompany = this.company.find(
      (item) => item.id === response.companyPayment.id,
    ) as Company;
    if (gettedCompany) {
      this.companySelected = {
        name: gettedCompany.name,
        cnpj: gettedCompany.cnpj,
        id: gettedCompany.id,
        siengeId: gettedCompany.siengeId,
        corporativeId: gettedCompany.corporativeId,
        fundAdministratorId: gettedCompany.fundAdministratorId,
        fundAdministratorName: gettedCompany.fundAdministratorName,
        enabled: gettedCompany.enabled,
        requiresSecondApprover: gettedCompany.requiresSecondApprover,
      } as Company;
    }
    this.companyNFeSelected = {
      name: response.companyNfe.name,
      cnpj: response.companyNfe.cpnj,
      id: response.companyNfe.id,
    };
    this.creditorSelected = {
      name: response.creditor.name,
      cpfCnpj: response.creditor.cpfCnpj,
      id: response.creditor.id,
      bank: response.creditor.bank,
      agency: response.creditor.agency,
      account: response.creditor.account,
      agencyDigit: response.creditor.agencyDigit,
      accountDigit: response.creditor.accountDigit,
    };

    this.documentSelected = {
      name: response.documetTypeName,
      siengeValue: "",
      abbreviation: "",
      id: response.documentTypeId,
    };
    // this.documentNumber = response.documentNumber;

    this.paymentFormSelected = {
      name: response.paymentMethod.name,
      siengeValue: response.paymentMethod.siengeValue,
      id: response.paymentMethod.id,
    };

    response.paymentType
      ? (this.paymentTypeSelected = {
          name: response.paymentType.name,
          siengeValue: response.paymentType.siengeValue,
          id: response.paymentType.id,
        })
      : null;

    this.observations = response.comments;
    this.contributor = {
      name: "",
      cpfCnpj: response.taxPayerCnpj,
      id: response.taxPayerId,
    };
    this.revenueCode = response.federalRevenueCode;
    this.referenceDate = response.darfReference;
    await this.getApprovers(this.companySelected.id);
    this.loadingCopy = false;
    this.approverSelected = response.approversId;
    if (response.approverId) {
      const findSecondApprover = this.approver.find(
        (a) => a.id === response.secondApproverId,
      );
      this.secondApproverSelected = findSecondApprover ?? EmptyApprover;
    }
    this.loadingApprover = false;
    this.getCostCenters(this.companySelected.id);
    this.setCompanyFundAdministrator();
    this.handleBlockPaymentMethod();
    this.handleBlockDocumentType();
    this.applyCompanySelectedWatcher();
    this.add();
  }

  public handleFormatDate(date: string) {
    const dateFormatted = moment(date).format("DD/MMMM/YYYY");
    const dateSeparated = dateFormatted.split("/");
    const monthFormatted = dateSeparated[1]?.substring(0, 3);
    const dateResult =
      dateSeparated[0] + "/" + monthFormatted + "/" + dateSeparated[2];

    return date ? dateResult : "";
  }

  async getDocumentType(token: string | null) {
    const response = await this.$http.get(this.endpoint.documentType, {
      headers: { Authorization: token },
    });
    this.document = response;
  }

  public async getApproversMode() {
    try {
      this.modes = await this.$http.get(this.endpoint.approverModes);
      this.modeSelected = 3;
    } catch (e) {}
  }

  async getPaymentMethod(token: string | null) {
    const response = await this.$http.get(this.endpoint.paymentMethod, {
      headers: { Authorization: token },
    });
    this.paymentForm = response;
  }

  async getPaymentType(token: string | null) {
    const response = await this.$http.get(this.endpoint.paymentType, {
      headers: { Authorization: token },
    });
    this.paymentType = response;
  }

  async getCompanies(token: string | null) {
    const response = await this.$http.get(this.endpoint.company, {
      headers: { Authorization: token },
    });
    this.company = await Promise.all(
      response.filter((company: any) => company.enabled === true),
    );
  }

  public setCompanyFundAdministrator() {
    const fundAdministrator = this.company.find(
      (item) => item.id === this.companySelected.id,
    );
    if (fundAdministrator?.fundAdministratorId !== null) {
      this.companySelectedFundAdministrator = fundAdministrator as Company;
      this.setDocumentTypeForFundAdministrator();
    } else {
      if (this.paymentFormSelected.id === 36) {
        this.paymentFormSelected = {
          name: "",
          siengeValue: "",
          id: 0,
        };
        this.documentSelected = {
          name: "",
          siengeValue: "",
          abbreviation: "",
          id: 0,
        };
      }
      this.handleBlockPaymentMethod();
      this.handleBlockDocumentType();
    }
  }

  public async getApprovers(id: number) {
    this.approver = await this.solicitationsAction.getSolicitationsData(
      this.$http,
      this.endpoint.companyApprover + `/${id}`,
      this.loadingCopy,
    );
  }

  async getCompaniesNFe(token: string | null) {
    const response = await this.$http.get(this.endpoint.company, {
      headers: { Authorization: token },
    });
    this.companyNFe = response;
  }

  async getCreditores(token: string | null) {
    const response = await this.$http.get(this.endpoint.creditor, {
      headers: { Authorization: token },
    });
    this.creditor = response;
    this.creditor.forEach((item) => {
      if (item.siengeValue === null) {
        item["disabled"] = true;
      }
    });
  }

  async getCostCenters(id: number) {
    const token = await this.authService.getTokenForMenuAsync();
    const response = await this.$http.get(
      this.endpoint.costCenterByCompany + `/${id}`,
      {
        headers: { Authorization: token },
      },
    );
    this.costCenter = response;
  }

  async getFinancialBudget(token: string | null) {
    const response = await this.$http.get(this.endpoint.financialPlain, {
      headers: { Authorization: token },
    });
    this.financialBudget = response;
  }

  async getSector(token: string | null) {
    const response = await this.$http.get(this.endpoint.userSector, {
      headers: { Authorization: token },
    });
    this.sector = response;
  }

  setCurrency(value: string) {
    if (value === "R$   " || value === "BRL  ") {
      this.currency = "BRL";
    }
    if (
      value === "$    " ||
      value === "usa  " ||
      value === "uad  " ||
      value === "USD  " ||
      value === "USD$ " ||
      value === "US   " ||
      value === "U$   " ||
      value === "US$  "
    ) {
      this.currency = "USD";
    }
    if (
      value === "c$   " ||
      value === "C$   " ||
      value === "cad$ " ||
      value === "CAD$ " ||
      value === "can$ " ||
      value === "Can$ " ||
      value === "CAN$ " ||
      value === "CAD  " ||
      value === "cad  "
    ) {
      this.currency = "CAD";
    }
    if (value === "£    " || value === "GBP  " || value === "GDP  ") {
      this.currency = "GBP";
    }
    if (value === "EUR  ") {
      this.currency = "EUR";
    }
  }

  public add() {
    let newCostCenter = new CostCentersPayment();
    newCostCenter.value = this.getComplementValue();
    this.costCentersPayment.push(newCostCenter);
  }

  public getComplementValue() {
    const originalValue = parseFloat(this.grossValue);

    let allAddValues = 0;
    this.costCentersPayment.forEach(
      (x) => (allAddValues += parseFloat(x.value)),
    );

    if (originalValue - allAddValues <= 0) return "0";
    else
      return parseFloat((originalValue - allAddValues).toString()).toFixed(2);
  }

  remove(item: CostCentersPayment) {
    this.costCentersPayment.splice(this.costCentersPayment.indexOf(item), 1);
  }

  async mounted() {
    // console.log('mounting. . .');
    this.loadingCopy = true;
    this.loadingApprover = true;
    if (this.$route.name === "EditRequest") this.loadingEdit = true;
    const token = await this.authService.getTokenForMenuAsync();

    this.getDocumentType(token);
    this.getPaymentMethod(token);
    this.getCompanies(token);
    this.getCompaniesNFe(token);
    this.getCreditores(token);
    this.getFinancialBudget(token);
    this.getPaymentType(token);
    this.getSector(token);
    this.getApproversMode();
    // this.$route.name === 'EditRequest'
    // ? this.getRequestById()
    // : this.copyRequest()

    this.$route.name === "EditRequest"
      ? await this.getRequestById()
      : await this.copyRequest();
    this.handleBlockCreditor();
  }

  returnPaymentTypeId(payment: string) {
    if (payment === "TRANSFERÊNCIA PARA OUTROS BANCOS") {
      return 1;
    }
    if (payment === "BOLETO BANCARIO") {
      return 2;
    }
    if (payment === "CONCESSIONÁRIAS (AGUA, LUZ, TEL)") {
      return 3;
    }
    if (payment === "DARF NORMAL") {
      return 4;
    }
    if (payment === "IMPOSTO COM CÓDIGO DE BARRAS") {
      return 5;
    }
    if (payment === "TRANSFERÊNCIA PARA CONTA BRADESCO") {
      return 6;
    }
    if (payment === "PAGAMENTO NO EXTERIOR") {
      return 7;
    }
    if (payment === "CONCESSIONÁRIAS PAGA NO ITAU (AGUA, LUZ, TEL)") {
      return 8;
    }
    if (payment === "DÉBITO AUTOMÁTICO") {
      return 9;
    }
  }

  formatDate(date: any) {
    return date ? moment(date).format("MM/DD/YYYY") : "";
  }

  back() {
    this.$router.go(-1);
  }
}
