



























































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { Watch, Prop } from 'vue-property-decorator';
import Endpoint from '@/utils/endpoint';
import Header from '@/@types/header';
import VueSkeletonLoader from 'skeleton-loader-vue';
import FundAdministratorHeader from '@/components/headers/fundAdministrator-header';
import FundAdministratorHeaderPT from '@/components/headers/pt-br/fundAdministrator-header-pt';
import FundAdministrator, {
  fundAdministratorInitialValue,
} from '@/@types/fundAdministrator';
import FeedbackInterface from '@/@types/feedback';
import Company from '@/@types/companies';
import { AuthService } from '../../services/authService';
import ErrorResponse from '@/@types/ErrorResponse';

@Component({ components: { VueSkeletonLoader } })
export default class ViewFundAdministrator extends Vue {
  @Prop(String) currentLanguage: string = 'en';
  @Prop(String) readonly id: string | undefined;

  @Getter('getUserLanguage', { namespace: 'auth' }) userLanguage!: any;
  @Action('setUserLanguage', { namespace: 'auth' }) setUserLanguage: any;

  @Getter('getFeedback', { namespace: 'auth' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'auth' }) public setFeedback: any;

  @Getter('getFundAdministratorData', { namespace: 'auth' })
  public fundAdministratorData!: FundAdministrator;
  @Action('setFundAdministratorData', { namespace: 'auth' })
  public setFundAdministratorData: any;

  headers: Header[] = FundAdministratorHeader.header; //idioma
  headersPT: Header[] = FundAdministratorHeaderPT.header; //idioma
  administratorId: FundAdministrator = fundAdministratorInitialValue;
  search = null;
  loading = false;
  showSkeleton = true;

  private endpoint = new Endpoint();
  authService = new AuthService();

  //   async getCompanies() {
  //     const token = await this.authService.getTokenForMenuAsync();
  //     this.loading = true;
  //     const response = await this.$http.get(this.endpoint.company, {
  //       headers: { Authorization: token },
  //     });
  //     const items = response;
  //     this.items = items;
  //     this.loading = false;
  //   }

  async mounted() {
    await this.getFundAdministratorById();
  }

  public async getFundAdministratorById() {
    this.showSkeleton = true;
    try {
      const token = await this.authService.getTokenForMenuAsync();
      const response = await this.$http.get(
        this.endpoint.fundAdministrators + `/${this.id}`,
        {
          headers: { Authorization: token },
        }
      );
      this.administratorId = response;
    } catch (error) {
      const errorData = error as ErrorResponse;
      console.log(this.administratorId);
      this.setFeedback({
        feedback: true,
        color: 'red',
        text:
          errorData.response.status === 400
            ? `${errorData.response.data}`
            : 'Ocorreu um erro',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 4000);
    }
    this.showSkeleton = false;
  }

  back() {
    this.$router.go(-1);
  }

  Edit(administrator: FundAdministrator) {
    this.setFundAdministratorData(administrator);
    this.$router.push({
      name: 'EditFundAdministrator',
      params: { id: administrator.id.toString() },
    });
  }
}
