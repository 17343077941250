import Header from '@/@types/header';
export default class ModalErrorPt {
  public static header: Header[] = [
    {
      text: 'Linha',
      align: 'start',
      sortable: true,
      value: 'linha',
      class: 'item-table-header item-table-header-users',
    },
    {
      text: 'Erro',
      align: 'start',
      sortable: false,
      value: 'error',
      class: 'item-table-header item-table-header-users',
    },
  ];
}