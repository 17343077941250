import Header from '@/@types/header';
export default class CreditorsHeader {
  public static header: Header[] = [
    {
      text: 'Id',
      align: 'start',
      sortable: false,
      value: 'id',
      class: 'item-table-header item-table-header-creditor',
    },
    {
      text: 'Name',
      align: 'start',
      sortable: false,
      value: 'name',
      class: 'item-table-header item-table-header-creditor',
    },
    {
      text: 'Sienge Id',
      align: 'start',
      sortable: false,
      value: 'siengeValue',
      class: 'item-table-header item-table-header-creditor',
    },
    {
      text: 'CNPJ',
      align: 'start',
      sortable: false,
      value: 'cpfCnpj',
      class: 'item-table-header item-table-header-creditor',
    },
  ];
}
