







































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { Watch, Prop } from 'vue-property-decorator';
import Endpoint from '@/utils/endpoint';
import Header from '@/@types/header';
import FundAdministratorHeader from '@/components/headers/fundAdministrator-header';
import FundAdministratorHeaderPT from '@/components/headers/pt-br/fundAdministrator-header-pt';
import FundAdministrator, {
  fundAdministratorInitialValue,
} from '@/@types/fundAdministrator';
import FeedbackInterface from '@/@types/feedback';
import Company from '@/@types/companies';
import { AuthService } from '../../services/authService';

@Component({})
export default class Companies extends Vue {
  @Prop(String) currentLanguage: string = 'en';

  @Getter('getUserLanguage', { namespace: 'auth' }) userLanguage!: any;
  @Action('setUserLanguage', { namespace: 'auth' }) setUserLanguage: any;

  headers: Header[] = FundAdministratorHeader.header; //idioma
  headersPT: Header[] = FundAdministratorHeaderPT.header; //idioma
  administratorList: FundAdministrator[] = [];
  search = null;
  loading = false;
  loadingList = false;

  private endpoint = new Endpoint();
  authService = new AuthService();

  //   async getCompanies() {
  //     const token = await this.authService.getTokenForMenuAsync();
  //     this.loading = true;
  //     const response = await this.$http.get(this.endpoint.company, {
  //       headers: { Authorization: token },
  //     });
  //     const items = response;
  //     this.items = items;
  //     this.loading = false;
  //   }

  async mounted() {
    await this.getFundAdministrator();
  }

  public async getFundAdministrator() {
    try {
      this.loadingList = true;
      const token = await this.authService.getTokenForMenuAsync();
      const response = await this.$http.get(this.endpoint.fundAdministrators, {
        headers: { Authorization: token },
      });
      this.administratorList = response;
      this.loadingList = false;
    } catch (error) {
      this.loadingList = false;
      console.log(this.administratorList);
    }
  }

  View(administrator: FundAdministrator) {
    this.$router.push({
      name: 'ViewFundAdministrator',
      params: { id: administrator.id.toString() },
    });
  }
}
