






















































































import Endpoint from '@/utils/endpoint';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';

import FeedbackInterface from '@/@types/feedback';
import SolicitationId from '@/@types/solicitationId';
import { AuthService } from '../../services/authService';

import ErrorResponse from '@/@types/ErrorResponse';
import moment from 'moment';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({})
export default class ModalExtendExpiration extends Vue {
  @Getter('getSolicitationId', { namespace: 'auth' })
  public solicitation!: SolicitationId;

  @Getter('getModalExtendExpiration', { namespace: 'auth' })
  public modalExtendExpiration!: boolean;
  @Action('setModalExtendExpiration', { namespace: 'auth' })
  public setModalExtendExpiration!: any;

  @Getter('getFeedback', { namespace: 'auth' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'auth' }) public setFeedback: any;

  @Getter('getUserLanguage', { namespace: 'auth' }) public userLanguage!: any;
  @Action('setUserLanguage', { namespace: 'auth' }) public setUserLanguage: any;

  loading = false;    
  public expiration: boolean = false;
  public expirationDate: string = "";
  private endpoint = new Endpoint();
  authService = new AuthService();


  public mounted() {
    // this.currentFile = new Blob();
  }
  public handleFormatDate(date: string) {
    const dateFormatted = moment(date).format('DD/MMMM/YYYY');
    const dateSeparated = dateFormatted.split('/');
    const monthFormatted = dateSeparated[1]?.substring(0, 3);
    const dateResult =
      dateSeparated[0] + '/' + monthFormatted + '/' + dateSeparated[2];

    return date ? dateResult : '';
  }

  async extendExpiration() {
    const token = await this.authService.getTokenForMenuAsync();
    try {
      this.loading = true;      
      await this.$http.patch(
        this.endpoint.extendSolicitationExpiration + '/' + this.solicitation,
        {
          expirationDate: this.expirationDate
        },
        {
          headers: {
            Authorization: token
          },
        }
      );
      this.loading = false;
      this.closeModal();

      this.setFeedback({
        feedback: true,
        color: 'green',
        text:
          this.userLanguage === 'en'
            ? 'Expiration extended'
            : 'Vencimento extendido',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);

      this.$router.push({ path: '/requests' });
    } catch (error) {
      const errorData = error as ErrorResponse;
      this.loading = false;
      console.error(error);
      this.closeModal();

      this.setFeedback({
        feedback: true,
        color: 'red',
        text:
          errorData.response.status !== 400 ||
          errorData.response.data ===
            'Object reference not set to an instance of an object'
            ? 'Ocorreu um erro'
            : `${errorData.response.data}`,
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    }
  }

  closeModal(): void {
    this.expirationDate = '';
    this.setModalExtendExpiration(false);
  }
}
