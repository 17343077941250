import { AuthService } from '../services/authService';
export default class Required {
  authService = new AuthService();
  public requireAuth = async (to: any, from: any, next: any) => {
    const token = await this.authService.getTokenForRequiredAsync();
    if (token !== null) {
      next();
    } else {
      next({ name: 'Login' });
    }
  };
}
