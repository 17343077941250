import Header from '@/@types/header';
export default class SolcitationsHeader {
  public static header: Header[] = [
    {
      text: 'Nome',
      align: 'start',
      sortable: true,
      value: 'name',
      class: 'item-table-header item-table-header-users',
    },
    {
      text: 'Email',
      align: 'start',
      sortable: true,
      value: 'access',
      class: 'item-table-header item-table-header-users',
    },
    {
      text: 'Acessos',
      align: 'start',
      sortable: true,
      value: 'access',
      class: 'item-table-header item-table-header-users',
    },
    {
      text: '',
      align: 'end',
      sortable: false,
      value: 'action',
      class: 'item-table-header item-table-header-users',
    },
  ];
}
