






































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { Watch, Prop } from 'vue-property-decorator';
import Endpoint from '../../utils/endpoint';
import Header from '@/@types/header';
import CostCenterHeader from '@/components/headers/costCenter-header';
import CostCenterHeaderPT from '@/components/headers/pt-br/costCenter-header-pt';
import CostCenter from '@/@types/costCenter';

import { AuthService } from '@/services/authService';

@Component({})
export default class Requests extends Vue {
  @Prop(String) currentLanguage: string = 'en';

  @Getter('getUserLanguage', { namespace: 'auth' }) userLanguage!: any;
  @Action('setUserLanguage', { namespace: 'auth' }) setUserLanguage: any;

  headers: Header[] = CostCenterHeader.header; //idioma
  headersPT: Header[] = CostCenterHeaderPT.header; //idioma
  items: CostCenter[] = [];
  search = null;
  loading = false;

  private endpoint = new Endpoint();
  authService = new AuthService();

  async getCostCenter() {
    const token = await this.authService.getTokenForMenuAsync();
    this.loading = true;
    const response = await this.$http.get(this.endpoint.costCenter, {
      headers: { Authorization: token },
    });
    const items = response;
    this.items = items;
    this.loading = false;
  }

  mounted() {
    this.getCostCenter();
  }
}
