






































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import ErrorResponseImport from "@/@types/ErrorResponseImport";
import Area from "@/@types/auth/areas";
import FeedbackInterface from "@/@types/feedback";
import Header from "@/@types/header";
import RequestPayload from "@/@types/payload";
import { PaymentStatusNumber } from "@/@types/paymentRequest/solicitation";
import SolicitationId from "@/@types/solicitationId";
import SolicitationStatus from "@/@types/solicitationStatus";
import Solicitations from "@/@types/solicitations";
import SolicitaionsHeaderPT from "@/components/headers/pt-br/solicitations-header-pt";
import SolicitaionsHeader from "@/components/headers/solicitations-header";
import ModalApproverId from "@/components/requestActions/modalApproverId.vue";
import ModalImportError from "@/components/requestActions/modalImportError.vue";
import ModalImportRequest from "@/components/requestActions/modalImportRequest.vue";
import formatMoney from "@/utils/formatMoney";
import removeStatusStorage from "@/utils/removeStatusStorage";
import moment from "moment";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { AuthService } from "../../services/authService";
import Endpoint from "../../utils/endpoint";
import statusSource from "@/@types/solicitationStatusSource";
const ALL_STATUS = 0;
const REQUESTED = 1;
const APPROVED = 2;
const DENIED = 3;
const EXPORTED = 4;
const EXPORTED_ERROR = 5;
const DRAFT = 6;
const CANCELLED = 7;
const PAID = 8;
const EMERGENCY_PENDING = 9;
const PENDING = 10;
const BANK_APPROVAL = 12;
const COMPLIANCE_APPROVAL = 13;

@Component({
  components: {
    ModalApproverId,
    ModalImportRequest,
    ModalImportError,
  },
})
export default class Requests extends Vue {
  @Getter("canCreate", { namespace: "auth" }) public canCreate!: boolean;
  @Getter("canUpdate", { namespace: "auth" }) public canUpdate!: boolean;
  @Getter("canRead", { namespace: "auth" }) public canRead!: boolean;
  @Getter("canDelete", { namespace: "auth" }) public canDelete!: boolean;
  @Getter("getAreas", { namespace: "auth" }) public areas!: Area[];

  @Getter("getModalApprove", { namespace: "auth" })
  public modalApprove!: boolean;
  @Getter("getModalDeny", { namespace: "auth" }) public modalDeny!: boolean;
  @Getter("getSolicitationId", { namespace: "auth" })
  public solicitation!: SolicitationId;

  @Action("setModalApprove", { namespace: "auth" })
  public setModalApprove!: any;
  @Action("setModalDeny", { namespace: "auth" }) public setModalDeny!: any;
  @Action("setSolicitationId", { namespace: "auth" })
  public setSolicitationId!: any;

  @Getter("getModalPay", { namespace: "auth" }) public modalPay!: boolean;
  @Action("setModalPay", { namespace: "auth" }) public setModalPay!: any;

  @Getter("getModalExported", { namespace: "auth" })
  public modalExported!: boolean;
  @Action("setModalExported", { namespace: "auth" })
  public setModalExported!: any;

  @Getter("getModalPending", { namespace: "auth" })
  public modalPending!: boolean;
  @Action("setModalPending", { namespace: "auth" })
  public setModalPending!: any;

  @Getter("getModalApproverId", { namespace: "auth" })
  public modalApproverId!: boolean;
  @Action("setModalApproverId", { namespace: "auth" })
  public setModalApproverId!: any;

  @Getter("getModalImportCSV", { namespace: "auth" })
  public modalImportCSV!: boolean;
  @Action("setModalImportCSV", { namespace: "auth" })
  public setModalImportCSV!: any;

  @Getter("getModalImportError", { namespace: "auth" })
  public modalImportError!: boolean;
  @Action("setModalImportError", { namespace: "auth" })
  public setModalImportError!: any;

  @Getter("getStatusPending", { namespace: "auth" })
  public statusPending!: boolean;
  @Action("setStatusPending", { namespace: "auth" })
  public setStatusPending!: any;

  @Getter("getModalCancel", { namespace: "auth" }) public modalCancel!: boolean;
  @Action("setModalCancel", { namespace: "auth" }) public setModalCancel!: any;

  @Getter("getModalBankApproval", { namespace: "auth" })
  public modalBankApproval!: boolean;
  @Action("setModalBankApproval", { namespace: "auth" })
  public setModalBankApproval!: any;

  @Getter("getFeedback", { namespace: "auth" })
  public feedback!: FeedbackInterface;
  @Action("setFeedback", { namespace: "auth" }) public setFeedback: any;

  @Getter("getModalExtendExpiration", { namespace: "auth" })
  public modalExtendExpiration!: boolean;
  @Action("setModalExtendExpiration", { namespace: "auth" })
  public setModalExtendExpiration!: any;

  @Getter("getUserLanguage", { namespace: "auth" }) userLanguage!: "en" | "pt";
  @Action("setUserLanguage", { namespace: "auth" }) setUserLanguage!: void;

  @Prop(String) public currentLanguage: string = "en";
  @Prop(Boolean) public isLogged: boolean | undefined;
  @Prop(Boolean) public canListItens: boolean | undefined;
  @Prop(Boolean) public useLocalFilters: boolean | undefined;

  @Watch("shortcutIsClicked")
  public handleGoToinner() {
    this.allowGoInner = false;
    if (this.shortcutIsClicked) {
      this.allowGoInner = false;
    } else {
      this.allowGoInner = true;
    }
  }

  @Watch("selected")
  @Watch("selectedEN")
  public setStatusFilter(newValue: SolicitationStatus) {
    this.filter = { ...this.filter, status: newValue.number ?? 0 };
  }

  @Watch("selectedSource")
  @Watch("selectedSourceEN")
  public setStatusFilterSource(newValue: statusSource) {
    this.filter = { ...this.filter, source: newValue.number };
  }
  @Watch("dateSince")
  public setDateSince(newValue: string) {
    this.filter = { ...this.filter, dateSince: newValue };
  }
  @Watch("dateUntil")
  public setDateUntil(newValue: string) {
    this.filter = { ...this.filter, dateUntil: newValue };
  }
  @Watch("filter")
  public getItemsForFilter() {
    this.ListItens();
  }

  // @Watch("selectedEN")
  // public changeStatusLanguage() {
  //   if (this.userLanguage === "en") {
  //     const identifyStatus = this.selectedEN!.number;
  //     const newStatus: SolicitationStatus = this.statusItems.find(
  //       (status: SolicitationStatus) => status.number === identifyStatus
  //     ) as SolicitationStatus;
  //     this.selected = { status: newStatus.status, number: newStatus.number };
  //   }
  // }

  // @Watch("selected")
  // public changeStatusLanguagePT() {
  //   if (this.userLanguage === "pt") {
  //     const identifyStatus = this.selected!.number;
  //     const newStatus: SolicitationStatus = this.statusItemsEN.find(
  //       status => status.number === identifyStatus
  //     ) as SolicitationStatus;
  //     this.selectedEN = {
  //       status: newStatus.status,
  //       number: newStatus.number,
  //     };
  //   }
  // }

  @Watch("paymentRequestSelected")
  public handleId() {
    const solicitationsToApprove = this.paymentRequestSelected.filter(
      (item) => item.userCanApprove,
    );
    this.solicitationsToApprove = solicitationsToApprove;
    // console.log(solicitationsToApprove);
  }

  get solicitationsThatCanBeApproved() {
    return this.items.map((item) => ({
      ...item,
      isSelectTable: item.userCanApprove === true,
    }));
  }

  public async handleAttachmentCSV() {
    if (this.attachmentCSV.size !== 0) {
      await this.postImportCSV();
      // this.setModalImportError(true);
    }
  }

  public removeStatusStorage = removeStatusStorage;
  public formatMoney = formatMoney;

  public search = "";
  public showFilters = false;
  public data = 0;
  public dateSince: string | null = "";
  public dateSinceAux = "";
  public since = false;
  public dateUntil: string | null = "";
  public filter: {
    dateSince: string;
    dateUntil: string;
    status: number;
    source: any;
  } = {
    dateSince: "",
    dateUntil: "",
    status: 0,
    source: "",
  };
  public until = false;
  public loading = true;
  public loadingApprover = false;
  public loadingImport = false;
  public loadingExported = false;
  public singleSelect = false;
  public options = {};
  public approverId: number[] = [];
  public paymentRequestSelected: RequestPayload[] = [];
  public solicitationsToApprove: RequestPayload[] = [];
  public approverIds = 0;
  public page = 1;
  public pageCount = 0;
  public itemsPerPage = 0;
  public totalItems = 0;
  public attachmentCSV: Blob = new Blob();
  public selected: SolicitationStatus = {
    status: "Todos os status",
    number: 0,
  };
  public selectedEN: SolicitationStatus = { status: "All status", number: 0 };
  public selectedItem = false;
  public statusItems = [
    { status: "Todos os status", number: ALL_STATUS },
    { status: "Solicitada", number: REQUESTED },
    { status: "Aprovada", number: APPROVED },
    { status: "Negada", number: DENIED },
    { status: "Exportada", number: EXPORTED },
    { status: "Erro ao exportar", number: EXPORTED_ERROR },
    { status: "Rascunho", number: DRAFT },
    { status: "Cancelada", number: CANCELLED },
    { status: "Paga", number: PAID },
    { status: "Pendente Emergencial", number: EMERGENCY_PENDING },
    { status: "Pendente", number: PENDING },
    { status: "Aprovação Bancária", number: BANK_APPROVAL },
    { status: "Aprovação Compliance", number: COMPLIANCE_APPROVAL },
  ];
  public statusItemsEN = [
    { status: "All status", number: ALL_STATUS },
    { status: "Requested", number: REQUESTED },
    { status: "Approved", number: APPROVED },
    { status: "Denied", number: DENIED },
    { status: "Exported", number: EXPORTED },
    { status: "Exported error", number: EXPORTED_ERROR },
    { status: "Draft", number: DRAFT },
    { status: "Canceled", number: CANCELLED },
    { status: "Paid", number: PAID },
    { status: "Emergency Pending", number: EMERGENCY_PENDING },
    { status: "Pending", number: PENDING },
    { status: "Bank Approval", number: BANK_APPROVAL },
    { status: "Compliance Approval", number: COMPLIANCE_APPROVAL },
  ];
  public statusItemsData = {
    en: {
      1: "Requested",
      2: "Approved",
      3: "Denied",
      4: "Exported",
      5: "Error",
      6: "Draft",
      7: "Canceled",
      8: "Paid",
      9: "Emergency Pending",
      10: "Pending",
      12: "Bank Approval",
      13: "Compliance Approval",
    },
    pt: {
      1: "Solicitada",
      2: "Aprovada",
      3: "Negada",
      4: "Exportada",
      5: "Erro",
      6: "Rascunho",
      7: "Cancelada",
      8: "Paga",
      9: "Pendente Emergencial",
      10: "Pendência",
      12: "Aprovação Bancária",
      13: "Aprovação Compliance",
    },
  };
  public perPageSelect = 8;
  public perPage = [8, 16, 32];

  public importList: any[] = [];
  public successImport = false;

  public allowGoInner = true;
  public shortcutIsClicked = false;

  private endpoint = new Endpoint();
  public authService = new AuthService();

  public headers: Header[] = SolicitaionsHeader.header; //idioma
  public headersPT: Header[] = SolicitaionsHeaderPT.header; //idioma
  public items: RequestPayload[] = [];

  public selectItem(item: boolean) {
    this.selectedItem = item;
  }

  public unSelectItem(item: object) {
    this.selectedItem = false;
  }

  sourceBy: any = {
    Vendor: {
      img: "Vendor_Logo",
      label: "Vendor",
    },
    Wire: {
      img: "IlliquidsLogo",
      label: "Wire",
    },
    Finti: {
      img: "FintiLogo",
      label: "Finti",
    },
  };

  public getUrlFor(imgName: string): any {
    var images = require.context("../../assets", false, /\.svg$/);
    return images("./" + imgName + ".svg");
  }

  public async getSolicitations(
    status?: number | null | undefined,
    sinceDate?: string | null | undefined,
    untilDate?: string | null | undefined,
    source?: string,
  ) {
    this.loading = true;
    sinceDate = sinceDate ? moment(sinceDate).format("YYYY-MM-DD") : "";
    untilDate = untilDate ? moment(untilDate).format("YYYY-MM-DD") : "";
    this.statusStorage(untilDate, sinceDate, status);

    const params = {
      status: status,
      source: source,
    };
    if (sinceDate !== "")
      !!sinceDate && Object.assign(params, { ["date.from"]: sinceDate });
    !!untilDate && Object.assign(params, { ["date.to"]: untilDate });

    try {
      const token = await this.authService.getTokenForMenuAsync();
      const response = await this.$http.get<RequestPayload[]>(
        this.endpoint.solicitation,
        {
          params,
          headers: { Authorization: token },
        },
      );
      this.items = response;
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.items = [];
    } finally {
      this.paymentRequestSelected = [];
    }
  }

  public async getDownloadCSV(
    status: number | null,
    sinceDate: string | null,
    untilDate: string | null,
  ) {
    sinceDate = sinceDate ? moment(sinceDate).format("YYYY-MM-DD") : "";
    untilDate = untilDate ? moment(untilDate).format("YYYY-MM-DD") : "";

    this.statusStorage(untilDate, sinceDate, status);

    const params = {
      status: status,
      SearchValue: this.search,
    };
    if (sinceDate !== "")
      !!sinceDate && Object.assign(params, { ["date.from"]: sinceDate });
    !!untilDate && Object.assign(params, { ["date.to"]: untilDate });

    try {
      const token = await this.authService.getTokenForMenuAsync();
      this.loading = true;
      const response = await this.$http.get(this.endpoint.downloadCSV, {
        params,
        headers: { Authorization: token },
      });
      var csv = response;
      csv += "\n";
      var hiddenElements = document.createElement("a");
      hiddenElements.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      hiddenElements.download = "paymentRequest.csv";
      hiddenElements.target = "_blank";
      hiddenElements.click();
      // let data = response;
      // let blob = new Blob([data], { type: 'text/plain;charset=utf-8;' });
      // const link = window.document.createElement('a');
      // link.href = window.URL.createObjectURL(blob);
      // link.download = 'export.txt';
      // link.click();
      // window.URL.revokeObjectURL(link.href);
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  }

  public downloadCSVTemplate() {
    var anchor = document.createElement("a");
    anchor.setAttribute("href", "/files/Template-Import-payments.xlsx");
    anchor.setAttribute("download", "");
    document.body.appendChild(anchor);
    anchor.click();
    (anchor.parentNode as HTMLAnchorElement).removeChild(anchor);
  }

  public async postImportCSV() {
    try {
      this.loadingImport = true;
      this.setModalImportCSV(true);
      // this.setModalImportCSV(true);
      const formData = this.setPaymentVoucher();
      const token = await this.authService.getTokenForMenuAsync();
      const response = await this.$http.post(
        this.endpoint.importCSV,
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        },
      );
      this.loadingImport = false;
      this.successImport = true;
      this.reloadPage();
    } catch (error) {
      const errorData = error as ErrorResponseImport;
      this.loadingImport = false;
      this.importList = errorData.response.data;
      this.setModalImportError(true);
    } finally {
      this.data++;
    }
  }

  public setPaymentVoucher() {
    const formData = new FormData();

    formData.append("file", this.attachmentCSV);

    return formData;
  }

  public approverLoteModal() {
    this.setModalApproverId(true);
  }

  public async approverLote() {
    try {
      const toApprove = this.paymentRequestSelected
        .filter((item) => item.userCanApprove)
        .map((item) => item.id);
      const token = await this.authService.getTokenForMenuAsync();
      this.loadingApprover = true;
      const response = await this.$http.put(
        this.endpoint.approverId,
        toApprove,
        {
          headers: { Authorization: token },
        },
      );
      this.loadingApprover = false;
      this.ListItens();
    } catch (error) {
      this.loadingApprover = false;
    }
  }

  public reloadPage() {
    this.ListItens();
  }

  public setDateInterval() {
    const today = new Date();
    const sinceDate = moment(today).subtract(2, "months");
    const untilDate = moment(today, "DD/MM/YYYY");

    this.dateSince = sinceDate.toISOString();
    this.dateUntil = untilDate.toISOString();
    this.filter = {
      ...this.filter,
      dateSince: this.dateSince,
      dateUntil: this.dateUntil,
    };
  }

  public ListItens() {
    this.getSolicitations(
      this.filter.status,
      this.filter.dateSince,
      this.filter.dateUntil,
      this.filter.source,
    );
  }

  setStatusData(item: PaymentStatusNumber): string | undefined {
    return this.statusItemsData[this.userLanguage][item];
  }

  public setStatusClass(item: number) {
    switch (item) {
      case 1:
        return "requested";
      case 2:
        return "aproved";
      case 3:
        return "denied";
      case 4:
        return "exported";
      case 5:
        return "fault";
      case 6:
        return "draft";
      case 7:
        return "draft";
      case 8:
        return "payed";
      case 9:
        return "emergency";
      case 10:
        return "pending";
      case 12:
        return "bankApproval";
      case 13:
        return "bankApproval";
      default:
        return "draft";
    }
  }

  public formatMoneyByCurrency(
    value: number,
    currency: string,
  ): string | undefined {
    if (currency === "USD  ") {
      const formater = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });

      return formater.format(value);
    }

    if (currency === "BRL  ") {
      const formater = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      });

      return formater.format(value);
    }

    if (currency === "EUR  ") {
      const formater = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "EUR",
      });

      return formater.format(value);
    }

    if (currency === "GBP  ") {
      const formater = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "GBP",
      });

      return formater.format(value);
    }

    if (currency === "CAD  ") {
      const formater = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "CAD",
      });

      return formater.format(value);
    }

    if (currency === "CAD  ") {
      const formater = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "CAD",
      });

      return formater.format(value);
    }
  }

  public goToInner(item: Solicitations) {
    this.$router.push({ path: `/request/${item.id}` });
  }

  public openInNewTab(item: Solicitations) {
    const requestLink = this.$router.resolve({ path: `/request/${item.id}` });
    window.open(requestLink.href, "_blank");
  }

  public statusStorage(
    dateTo: string,
    dateFrom: string,
    status: number | null | undefined,
  ) {
    this.$cookies.set("dateTo", dateTo);
    this.$cookies.set("dateFrom", dateFrom);
    this.$cookies.set("status", status);
  }

  public getStatusFromStorage(): void {
    const setStatusPt = this.statusItems.find(
      (status: SolicitationStatus) =>
        status.number === parseInt(this.$cookies.get("status")),
    ) as SolicitationStatus;

    const setStatusEn = this.statusItemsEN.find(
      (status: SolicitationStatus) =>
        status.number === parseInt(this.$cookies.get("status")),
    ) as SolicitationStatus;

    this.selected = { status: setStatusPt.status, number: setStatusPt.number };
    this.selectedEN = {
      status: setStatusEn.status,
      number: setStatusEn.number,
    };
    this.dateUntil = this.$cookies.get("dateTo");
    this.dateSince = this.$cookies.get("dateFrom");
  }

  public async copy(item: Solicitations) {
    this.allowGoInner = false;
    this.shortcutIsClicked = true;
    this.$router.push({ path: `/request/copy/${item.id}` });
    this.shortcutIsClicked = true;
  }

  public editRequest(item: Solicitations) {
    this.allowGoInner = false;
    this.shortcutIsClicked = true;
    this.$router.push({ path: `/request/edit/${item.id}` });
    this.shortcutIsClicked = true;
  }

  public async denySolicitation(item: Solicitations) {
    this.setModalDeny(true);
    this.setSolicitationId(item.id);
    setTimeout(async () => {
      await this.getSolicitations();
    }, 8000);
  }

  public async extendSolicitation(item: Solicitations) {
    this.setModalExtendExpiration(true);
    this.setSolicitationId(item.id);
    // setTimeout(async() => {
    //   await this.getSolicitations();
    // }, 8000)
  }

  public async cancelSolicitation(item: Solicitations) {
    this.setModalCancel(true);
    this.setSolicitationId(item.id);
  }

  public async approveSolicitation(item: Solicitations) {
    this.setModalApprove(true);
    this.setSolicitationId(item.id);
  }

  public async exportedSolicitation(item: Solicitations) {
    this.setModalExported(true);
    this.setSolicitationId(item.id);
  }

  public async paySolicitation(item: Solicitations) {
    this.setModalPay(true);
    this.setSolicitationId(item.id);
  }

  public async bankApprovalSolicitation(item: Solicitations) {
    this.setModalBankApproval(true);
    this.setSolicitationId(item.id);
  }

  public pendingSolicitation(item: Solicitations) {
    this.setModalPending(true);
    this.setSolicitationId(item.id);
    this.setStatusPending(item.status);
  }

  public formatDate(date: any) {
    return date ? moment(date).format("MM-DD-YYYY") : "";
  }

  public handleFormatDate(date: string) {
    const dateFormatted = moment(date).format("DD/MMMM/YYYY");
    const dateSeparated = dateFormatted.split("/");
    const monthFormatted = dateSeparated[1]?.substring(0, 3);
    const dateResult =
      dateSeparated[0] + "/" + monthFormatted + "/" + dateSeparated[2];

    return date ? dateResult : "";
  }

  public isExpiredDate(date: string): boolean {
    const expiredDate = new Date(date);
    const today = new Date();
    return expiredDate < today && !this.areSameDates(expiredDate, today);
  }

  public areSameDates(date: Date, otherDate: Date): boolean {
    return (
      date.getDate() === otherDate.getDate() &&
      date.getMonth() === otherDate.getMonth() &&
      date.getFullYear() === otherDate.getFullYear()
    );
  }

  public removeStatus() {
    this.removeStatusStorage();
  }

  public mounted() {
    // console.log("useLocalFilters", this.useLocalFilters);
    this.setDateInterval();
    if (this.canListItens) {
      this.getStatusFromStorage();
      localStorage.setItem("SolicitatioCanRead", this.canRead.toString());
      // this.ListItens();
    }
  }
  public selectedSource: statusSource = {
    status: "Todas as fontes",
    number: "",
  };
  public selectedSourceEN: statusSource = {
    status: "All sources",
    number: "",
  };

  public statusSource = [
    {
      status: "Todas as fontes",
      number: "",
    },
    {
      status: "Finti",
      number: "Finti",
    },
    {
      status: "Vendor",
      number: "Vendor",
    },
    {
      status: "Wire",
      number: "Wire",
    },
  ];
  public statusSourceEN = [
    {
      status: "All sources",
      number: "",
    },
    {
      status: "Finti",
      number: "Finti",
    },
    {
      status: "Vendor",
      number: "Vendor",
    },
    {
      status: "Wire",
      number: "Wire",
    },
  ];
  closeModalExported() {
    this.setModalExported(false);
  }
}
