




















import ModalBankApproval from '@/components/requestActions/modalBankApproval.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import Feedback from './actions/Feedback.vue';
import JsonFeedback from './actions/JsonFeedback.vue';
import ModalConfirmNewCreditor from './actions/modalConfirmCreditor.vue';
import ModalNewCreditor from './creditor/modalNewCreditor.vue';
import ModalEmergencyApproval from './requestActions/EmergencyApprovalModal.vue';
import ModalAddStatement from './requestActions/modalAddStatement.vue';
import ModalApprove from './requestActions/modalApprove.vue';
import ModalCancel from './requestActions/modalCancel.vue';
import ModalDeny from './requestActions/modalDeny.vue';
import ModalExported from './requestActions/modalExported.vue';
import ModalExtendExpiration from './requestActions/modalExtendExpiration.vue';
import ModalPay from './requestActions/modalPay.vue';
import ModalPending from './requestActions/modalPending.vue';



@Component({
  components: {
    modalDeny: ModalDeny,
    modalCancel: ModalCancel,
    modalApprove: ModalApprove,
    modalEmergencyApproval: ModalEmergencyApproval,
    modalPay: ModalPay,
    ModalPending: ModalPending,
    modalAddStatement: ModalAddStatement,
    feedback: Feedback,
    JsonFeedback: JsonFeedback,
    modalNewCreditor: ModalNewCreditor,
    modalConfirmNewCreditor: ModalConfirmNewCreditor,
    ModalExported: ModalExported,
    ModalBankApproval,
    ModalExtendExpiration
  },
})
export default class ApplicationModals extends Vue {}
