function formatMoney(valueToFormat: number): string {
  const formater = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formater.format(valueToFormat);
}

export default formatMoney;
