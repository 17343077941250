






































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { Watch, Prop } from 'vue-property-decorator';
import Endpoint from '../../utils/endpoint';
import Header from '@/@types/header';
import FinancialPlainHeader from '@/components/headers/financialPlain-header';
import FinancialPlainHeaderPT from '@/components/headers/pt-br/financialPlain-header-pt';

import FinancialPlainInterface from '@/@types/financialPlain';

import { AuthService } from '@/services/authService';

@Component({})
export default class FinancialPlain extends Vue {
  @Prop(String) currentLanguage: string = 'en';

  @Getter('getUserLanguage', { namespace: 'auth' }) userLanguage!: any;
  @Action('setUserLanguage', { namespace: 'auth' }) setUserLanguage: any;

  headers: Header[] = FinancialPlainHeader.header; //idioma
  headersPT: Header[] = FinancialPlainHeaderPT.header; //idioma
  items: FinancialPlainInterface[] = [];
  search = null;
  loading = false;

  private endpoint = new Endpoint();
  authService = new AuthService();

  async getFinancialPlain() {
    const token = await this.authService.getTokenForMenuAsync();
    this.loading = true;
    const response = await this.$http.get(this.endpoint.financialPlain, {
      headers: { Authorization: token },
    });
    const items = response;
    this.items = items;
    this.loading = false;
  }

  mounted() {
    this.getFinancialPlain();
  }

  // formatDate(date: any) {
  //   const formattedDate = moment(date).format("MM/DD/YYYY");
  //   return formattedDate;
  // }
}
