



















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { Watch, Prop } from 'vue-property-decorator';
import Endpoint from '@/utils/endpoint';

import { AuthService } from '../../services/authService';
import SolicitationId from '@/@types/solicitationId';
import FeedbackInterface from '@/@types/feedback';
import Header from '@/@types/header';
import ModalError from '@/components/headers/pt-br/modalError-headers';

// import SweetalertIcon from 'vue-sweetalert-icons';

import qs from 'qs';

const namespace = 'add';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({ components: {} })
export default class modalImportRequest extends Vue {
  public headersPT: Header[] = ModalError.header; //idioma

  @Getter('getSolicitationId', { namespace: 'auth' })
  public solicitation!: SolicitationId;

  @Getter('getModalAttachmentError', { namespace: 'auth' })
  public modalAttachmentError!: boolean;
  @Action('setModalAttachmentError', { namespace: 'auth' })
  public setModalAttachmentError!: any;

  @Getter('getFeedback', { namespace: 'auth' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'auth' }) public setFeedback: any;

  @Getter('getUserLanguage', { namespace: 'auth' }) public userLanguage!: any;
  @Action('setUserLanguage', { namespace: 'auth' }) public setUserLanguage: any;

  @Prop(String) messageError?: string;

  loading = false;

  private endpoint = new Endpoint();
  authService = new AuthService();

  closeModal(): void {
    this.setModalAttachmentError(false);
  }
}
