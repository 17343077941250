var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0",attrs:{"color":"#F3F6F9","height":"100%"}},[_c('v-card-title',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',{staticClass:"mb-3"},[_c('span',{staticClass:"creditors-title mr-2"},[_vm._v(_vm._s(_vm.$t('creditor.title')))]),_c('v-spacer'),_c('v-btn',{staticClass:"new-creditor text-capitalize mr-4 elevation-0",attrs:{"width":"224px","outlined":"","color":"#023868"},on:{"click":_vm.openNewCreditorModal}},[_vm._v(_vm._s(_vm.$t('newCreditorSienge.newCreditorBySienge')))]),_c('div',{staticClass:"title-actions d-flex align-center"},[_c('v-btn',{staticClass:"new-creditor text-capitalize mr-3",attrs:{"to":"/creditors/new","width":"204px","color":"#023868"}},[_vm._v(" "+_vm._s(_vm.$t('creditor.newCreditor'))+" ")]),_c('v-text-field',{staticClass:"mr-2",attrs:{"outlined":"","dense":"","hide-details":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-card',{staticClass:"elevation-0"},[(_vm.userLanguage === 'en')?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"search":_vm.search,"no-results-text":"No creditors found","no-data-text":"No creditors found","fixed-header":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{on:{"mouseover":function($event){return _vm.selectItem(item)},"mouseleave":function($event){return _vm.unSelectItem()}}},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.siengeValue))]),_c('td',[_vm._v(_vm._s(_vm.formatDataCpfCnpj(item.cpfCnpj)))]),_c('div',{staticClass:"hover-elements"},[(item === _vm.selectedItem && item.siengeValue === null)?_c('div',{staticClass:"exported-hover d-flex align-center pa-3"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"icon-wrapper clickable success-icon d-flex mr-2",on:{"click":function($event){return _vm.editCompaniesFundAdministrator(item)}}},'div',attrs,false),on),[_c('v-icon',{attrs:{"color":"#19B500"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('companie.edit')))])])],1):_vm._e()])])]}}],null,false,517515523)}):_vm._e(),(_vm.userLanguage === 'pt')?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersPT,"items":_vm.items,"loading":_vm.loading,"search":_vm.search,"footer-props":{ itemsPerPageText: 'Itens por página' },"fixed-header":"","no-results-text":"Nenhum credor encontrado","no-data-text":"Nenhum credor encontrado"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{on:{"mouseover":function($event){return _vm.selectItem(item)},"mouseleave":function($event){return _vm.unSelectItem()}}},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.siengeValue))]),_c('td',[_vm._v(_vm._s(item.cpfCnpj))]),_c('div',{staticClass:"hover-elements"},[(item === _vm.selectedItem)?_c('div',{staticClass:"exported-hover d-flex align-center pa-3"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"icon-wrapper clickable success-icon d-flex mr-2",on:{"click":function($event){return _vm.editCompaniesFundAdministrator(item)}}},'div',attrs,false),on),[_c('v-icon',{attrs:{"color":"#19B500"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('companie.edit')))])])],1):_vm._e()])])]}}],null,false,1663185014)}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }