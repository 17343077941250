import Header from '@/@types/header';

export default class financialPlainHeader {
  public static header: Header[] = [
    {
      text: 'Id',
      align: 'start',
      sortable: false,
      value: 'id',
      class: 'item-table-header item-table-header-financial-plain',
    },
    {
      text: 'Nome',
      align: 'start',
      sortable: true,
      value: 'name',
      class: 'item-table-header item-table-header-financial-plain',
    },
    {
      text: 'Empresas',
      align: 'start',
      sortable: true,
      value: 'companies',
      class: 'item-table-header item-table-header-financial-plain',
    },
    {
      text: 'Sienge',
      align: 'start',
      sortable: true,
      value: 'siengeValue',
      class: 'item-table-header item-table-header-financial-plain',
    },
  ];
}
