import StatementPayment from '@/@types/statement/statementPayment';
import { StatementPaymentStateInterface } from './types';

export default {
  setStatementPayment(
    state: StatementPaymentStateInterface,
    statementPayment: StatementPayment
  ) {
    state.statementPayment = statementPayment;
  }
};
