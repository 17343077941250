var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","grid-list-xs":""}},[_c('v-dialog',{attrs:{"scrollable":"","max-width":"681px","persistent":""},model:{value:(_vm.modalImportError),callback:function ($$v) {_vm.modalImportError=$$v},expression:"modalImportError"}},[_c('v-card',{staticClass:"modalImport"},[_c('v-card-title',{staticClass:"title py-8 p-10 pb-7"},[_c('v-row',{attrs:{"align":"right"}},[_c('div',{staticClass:"close-modal"},[_c('v-icon',{attrs:{"size":"30","color":"#3B414A"},on:{"click":_vm.closeModal}},[_vm._v("mdi-close")])],1)])],1),_c('div',{staticClass:"loadingProgress"},[_c('div',{staticClass:"progressLoading"},[_c('v-icon',{staticClass:"checkSuccess",attrs:{"color":"#DF0000","size":"70"}},[_vm._v(" mdi-close-circle-outline ")])],1)]),_c('div',{staticClass:"sizeLabelSuccess"},[_c('div',{staticClass:"errorLabel pt-4"},[_c('span',{staticClass:"labelImportError"},[_vm._v(" "+_vm._s(_vm.$t('modalImportError.titleError'))+" ")])])]),_c('v-card-text',{staticClass:"sizeDataTable"},[(_vm.userLanguage === 'pt' && !_vm.IsACostCenter)?_c('v-data-table',{attrs:{"headers":_vm.headersPT,"items":_vm.importList,"loading":_vm.loading,"no-results-text":"No error found","no-data-text":"No error found","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-small",staticStyle:{"min-width":"100px","color":"#34383f"}},[_vm._v(" "+_vm._s(item.rowPosition)+" ")]),_c('td',[_vm._v(_vm._s(_vm.getNameAttachmentUrl(item.message)))])])]}}],null,false,3292523485)}):_vm._e(),(_vm.userLanguage === 'en' && !_vm.IsACostCenter)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.importList,"loading":_vm.loading,"no-results-text":"No error found","no-data-text":"No error found","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-small",staticStyle:{"min-width":"100px","color":"#34383f"}},[_vm._v(" "+_vm._s(item.rowPosition)+" ")]),_c('td',[_vm._v(_vm._s(_vm.getNameAttachmentUrl(item.message)))])])]}}],null,false,3292523485)}):_vm._e(),(_vm.userLanguage === 'pt' && _vm.IsACostCenter)?_c('v-data-table',{attrs:{"headers":_vm.headersPT,"items":_vm.errorsImport,"loading":_vm.loading,"no-results-text":"No error found","no-data-text":"No error found","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-small",staticStyle:{"min-width":"100px","color":"#34383f"}},[_vm._v(" "+_vm._s(item.position)+" ")]),_c('td',[_vm._v(_vm._s(item.error))])])]}}],null,false,1905839430)}):_vm._e(),(_vm.userLanguage === 'en' && _vm.IsACostCenter)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.errorsImport,"loading":_vm.loading,"no-results-text":"No error found","no-data-text":"No error found","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-small",staticStyle:{"min-width":"100px","color":"#34383f"}},[_vm._v(" "+_vm._s(item.position)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.error)+" ")])])]}}],null,false,2835686950)}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }