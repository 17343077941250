import Header from '@/@types/header';

export default class fundAdministratorHeaders {
  public static header: Header[] = [
    {
        text: 'Id',
        align: 'start',
        sortable: false,
        value: 'id',
        class: 'item-table-header item-table-header-company',
    },
    {
      text: 'Nome',
      align: 'start',
      sortable: false,
      value: 'name',
      class: 'item-table-header item-table-header-company',
    },
    {
      text: 'Nome Curto',
      align: 'start',
      sortable: false,
      value: 'shortName',
      class: 'item-table-header item-table-header-company',
    },
    {
      text: 'Email',
      align: 'start',
      sortable: false,
      value: 'email',
      class: 'item-table-header item-table-header-company',
    },
    {
        text: 'Identificador',
        align: 'start',
        sortable: false,
        value: 'cnpj',
        class: 'item-table-header item-table-header-company',
      },
  ];
}
