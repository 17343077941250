


































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import Endpoint from '@/utils/endpoint';
import qs from 'qs';
import ErrorResponse from '@/@types/ErrorResponse';
import FeedbackInterface from '@/@types/feedback';

import { AuthService } from '../../services/authService';
import NewCreditor from '@/@types/newCreditor';

const namespace = 'add';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({})
export default class ModalNewCreditor extends Vue {
  @Getter('getModalNewCreditor', { namespace: 'auth' })
  public modalNewCreditor!: boolean;
  @Action('setModalNewCreditor', { namespace: 'auth' })
  public setModalNewCreditor!: any;

  @Action('setModalConfirmNewCreditor', { namespace: 'auth' })
  public setModalConfirmNewCreditor!: any;

  @Getter('getNewCreditorsSienge', { namespace: 'auth' })
  public newCreditorsSienge!: NewCreditor;
  @Action('setNewCreditorsSienge', { namespace: 'auth' })
  public setNewCreditorsSienge!: any;

  @Getter('getFeedback', { namespace: 'auth' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'auth' }) public setFeedback: any;

  requiredRules: Array<any> = [
    (v: any): boolean | string => {
      if (v) {
        if (typeof v === 'string' && v.trim().length === 0)
          return 'Required field';
        return true;
      }
      if (v === false) return true;
      if (v === 0) return true;

      return 'Required Field';
    },
  ];

  loading = false;
  loadingBtnCredor = false;
  creditorSienge = '';
  creditors: NewCreditor[] = [];
  showViewCredor = false;

  private endpoint = new Endpoint();
  authService = new AuthService();

  public addNewCreditor() {
    const creditor = new NewCreditor();
    this.creditors.push(creditor);
  }

  public removeCreditor(id: number) {
    this.creditors.splice(id, 1);
  }

  public async getCreditorsData() {
    const siengeId = this.creditors.map(item => item.siengeValue);
    const token = await this.authService.getTokenForMenuAsync();
    const response = await this.$http.get(this.endpoint.creditorBySienge, {
      params: {
        siengeIds: siengeId,
      },
      paramsSerializer: params => qs.stringify(params),
    });
    if (response[0] === null) {
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: this.$t('newCreditorSienge.noCredorFound'),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 4000);
      this.loadingBtnCredor = false;
    } else {
      this.showViewCredor = true;
    }
  }

  public async addCreditors() {
    await this.getCreditorsData();
    if (this.showViewCredor) {
      this.setNewCreditorsSienge(this.creditors.map(item => item.siengeValue));
      this.closeModal();
      this.setModalConfirmNewCreditor(true);
    }
    this.showViewCredor = false;
    // this.closeModal();
  }

  public closeModal(): void {
    this.creditors.length = 0;
    this.addNewCreditor();
    this.setModalNewCreditor(false);
  }

  public mounted() {
    this.addNewCreditor();
  }
}
