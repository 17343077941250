export type JsonFeedback = {
    show: boolean;
    color: string;
    text: string;
  }
  
  const jsonFeedbackInitialValue: JsonFeedback = {
    show: false,
    color: '',
    text: '',
  };
  
  export { jsonFeedbackInitialValue };
  