import Header from '@/@types/header';

export default class fundAdministratorHeaders {
  public static header: Header[] = [
    {
        text: 'Id',
        align: 'start',
        sortable: false,
        value: 'id',
        class: 'item-table-header item-table-header-company',
    },
    {
      text: 'Name',
      align: 'start',
      sortable: false,
      value: 'name',
      class: 'item-table-header item-table-header-company',
    },
    {
      text: 'Short Name',
      align: 'start',
      sortable: false,
      value: 'shortName',
      class: 'item-table-header item-table-header-company',
    },
    {
      text: 'E-mail',
      align: 'start',
      sortable: false,
      value: 'contactEmail',
      class: 'item-table-header item-table-header-company',
    },
    {
        text: 'Identifier',
        align: 'start',
        sortable: false,
        value: 'identifier',
        class: 'item-table-header item-table-header-company',
      },
      {
        text: 'External Administrator',
        align: 'start',
        sortable: false,
        value: 'externalAdm',
        class: 'item-table-header item-table-header-company',
      },
  ];
}
