














































import Endpoint from '@/utils/endpoint';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';

import FeedbackInterface from '@/@types/feedback';
import SolicitationId from '@/@types/solicitationId';
import { AuthService } from '../../services/authService';

import ErrorResponse from '@/@types/ErrorResponse';

const namespace = 'add';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({})
export default class modalDeny extends Vue {
  @Getter('getSolicitationId', { namespace: 'auth' })
  public solicitation!: SolicitationId;

  @Getter('getModalCancel', { namespace: 'auth' }) public modalCancel!: boolean;
  @Action('setModalCancel', { namespace: 'auth' }) public setModalCancel!: any;

  @Getter('getFeedback', { namespace: 'auth' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'auth' }) public setFeedback: any;

  @Getter('getUserLanguage', { namespace: 'auth' }) public userLanguage!: any;
  @Action('setUserLanguage', { namespace: 'auth' }) public setUserLanguage: any;

  loading = false;

  private endpoint = new Endpoint();
  authService = new AuthService();

  async cancelSolicitation() {
    const token = await this.authService.getTokenForMenuAsync();

    try {
      this.loading = true;
      await this.$http.delete(
        this.endpoint.cancelSolicitation + `/${this.solicitation}`,
        {
          headers: { Authorization: token },
        }
      );
      this.setFeedback({
        feedback: true,
        color: 'green',
        text:
          this.userLanguage === 'en'
            ? 'Request canceled'
            : 'Solicitação cancelada',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
      this.$router.push({ path: '/requests' });
    } catch (error) {
      const errorData = error as ErrorResponse;
      this.setFeedback({
        feedback: true,
        color: 'red',
        text:
          errorData.response.status === 400
            ? `${errorData.response.data}`
            : 'Ocorreu um erro',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    }
    this.setModalCancel(false);
    this.loading = false;
  }

  closeModal(): void {
    this.setModalCancel(false);
  }
}
