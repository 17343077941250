










































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { Watch, Prop } from 'vue-property-decorator';
import Endpoint from '@/utils/endpoint';

import { AuthService } from '../../services/authService';
import SolicitationId from '@/@types/solicitationId';
import FeedbackInterface from '@/@types/feedback';

// import SweetalertIcon from 'vue-sweetalert-icons';

import qs from 'qs';

const namespace = 'add';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({})
export default class modalImportRequest extends Vue {
  @Getter('getSolicitationId', { namespace: 'auth' })
  public solicitation!: SolicitationId;

  @Getter('getModalImportCSV', { namespace: 'auth' })
  public modalImportCSV!: boolean;
  @Action('setModalImportCSV', { namespace: 'auth' })
  public setModalImportCSV!: any;

  @Getter('getFeedback', { namespace: 'auth' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'auth' }) public setFeedback: any;

  @Getter('getUserLanguage', { namespace: 'auth' }) public userLanguage!: any;
  @Action('setUserLanguage', { namespace: 'auth' }) public setUserLanguage: any;

  @Prop(Boolean) loading!: boolean;
  @Prop(Boolean) successImport!: boolean;
  @Prop(Boolean) loadingImportCost!: boolean;

  @Watch('loading')
  public handleLoading() {
    if (this.loading === false && this.successImport === false) {
      this.closeModal();
    }
  }

  @Watch('loadingImportCost')
  public handleLoadingCost() {
    if (this.loadingImportCost === false) {
      this.closeModal();
    }
  }

  vouchername = '';

  private endpoint = new Endpoint();
  authService = new AuthService();

  closeModal(): void {
    this.setModalImportCSV(false);
  }
}
