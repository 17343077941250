import { companiesNewInitialValue } from '@/@types/companiesNew';
import { creditorInicitalValue } from '@/@types/creditors';
import { feedbackInitialValue } from '@/@types/feedback';
import { fundAdministratorInitialValue } from '@/@types/fundAdministrator';
import { jsonFeedbackInitialValue } from '@/@types/jsonFeedback';
import NewCreditor from '@/@types/newCreditor';
import { solicitationIdInitialValue } from '@/@types/solicitationId';

export default {
  user: null,
  error: false,
  solicitationid: solicitationIdInitialValue,
  modalDeny: false,
  modalCancel: false,
  modalApprove: false,
  modalPay: false,
  modalAddStatement: false,
  modalExtendExpiration: false,
  modalEmergencyApproval: false,
  modalBankApproval: false,
  feedback: feedbackInitialValue,
  jsonFeedback: jsonFeedbackInitialValue,
  userLanguage: '',
  modalNewCreditor: false,
  modalConfirmNewCreditor: false,
  newCreditorsSienge: NewCreditor,
  fundAdministratorData: fundAdministratorInitialValue,
  companiesData: companiesNewInitialValue,
  modalPending: false,
  statusPending: 0,
  modalApproverId: false,
  modalImportRequest: false,
  modalImportError: false,
  modalAttachmentError: false,
  modalExported: false,
  creditorsData: creditorInicitalValue,
};
