




















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import ErrorResponse from "@/@types/ErrorResponse";
import SelectApprover, { EmptyApprover, Approver } from "@/@types/approver";
import User from "@/@types/auth/user";
import Company, { companyInitialValue } from "@/@types/companies";
import CostCenter from "@/@types/costCenter";
import CostCenterImport from "@/@types/costCenterImport";
import Creditor from "@/@types/creditors";
import ErrorImport from "@/@types/errorsImport";
import FeedbackInterface from "@/@types/feedback";
import FinancialBudget from "@/@types/financialPlain";
import RequestPayload from "@/@types/payload";
import CostCenters from "@/@types/paymentRequest/costCenters";
import CostCentersPayment from "@/@types/paymentRequest/costCentersPayment";
import DocumentType from "@/@types/paymentRequest/documentType";
import FinancialPlain from "@/@types/paymentRequest/financialPlainSelected";
import PaymentMethod from "@/@types/paymentRequest/paymentMethod";
import Feedback from "@/components/actions/Feedback.vue";
import ModalAttachmentError from "@/components/requestActions/modalAttachmentError.vue";
import ModalImportError from "@/components/requestActions/modalImportError.vue";
import ModalImportRequest from "@/components/requestActions/modalImportRequest.vue";
import Endpoint from "@/utils/endpoint";
import SolicitationsAction from "@/utils/solicitationActions";
import moment from "moment";
import { Money } from "v-money";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { mask } from "vue-the-mask";
import { Action, Getter } from "vuex-class";
import { AuthService } from "../../services/authService";
import axios from "axios";

// interface CostCenter {
//   costCenter: string;
//   financialBudget: string;
//   value: number;
// }

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({
  directives: { mask },
  components: {
    Feedback,
    Money,
    ModalAttachmentError,
    ModalImportError,
    ModalImportRequest,
  },
})
export default class InnerRequest extends Vue {
  @Getter("getUser", { namespace: "auth" }) public user!: User;
  @Action("setUser", { namespace: "auth" }) public setUser: any;

  @Getter("getFeedback", { namespace: "auth" })
  public feedback!: FeedbackInterface;
  @Action("setFeedback", { namespace: "auth" }) public setFeedback: any;

  @Getter("getModalEmergencyApproval", { namespace: "auth" })
  public modalEmergencyApproval!: boolean;
  @Action("setModalEmergencyApproval", { namespace: "auth" })
  public setModalEmergencyApproval!: any;

  @Getter("getModalAttachmentError", { namespace: "auth" })
  public modalAttachmentError!: boolean;
  @Action("setModalAttachmentError", { namespace: "auth" })
  public setModalAttachmentError!: any;

  @Getter("getModalImportError", { namespace: "auth" })
  public modalImportError!: boolean;
  @Action("setModalImportError", { namespace: "auth" })
  public setModalImportError!: any;

  @Getter("getModalImportCSV", { namespace: "auth" })
  public modalImportCSV!: boolean;
  @Action("setModalImportCSV", { namespace: "auth" })
  public setModalImportCSV!: any;

  @Getter("getUserLanguage", { namespace: "auth" }) userLanguage!: any;
  @Action("setUserLanguage", { namespace: "auth" }) setUserLanguage: any;

  @Prop(Number) readonly id: number | undefined;

  @Watch("companySelected")
  call() {
    this.paymentFormSelected = {
      name: "",
      siengeValue: "",
      id: 0,
    };
    this.documentSelected = {
      name: "",
      siengeValue: "",
      abbreviation: "",
      id: 0,
    };
    this.approverSelected = [];
    // this.approverSelected = {
    //   approverName: '',
    //   departmentName: '',
    //   id: 0,
    // };
    this.secondApproverSelected = {
      approverName: "",
      departmentName: "",
      id: 0,
    };
    this.modeSelected = 3;
    this.getCostCenters(this.companySelected.id);
    this.getApprovers(this.companySelected.id);
    this.costCentersPayment = [];
    this.add();

    this.setDocumentTypeForFundAdministrator();
    this.handleBlockPaymentMethod();
    this.handleBlockDocumentType();

    this.creditorSelected = {
      name: "",
      cpfCnpj: "",
      bank: "",
      agency: "",
      account: "",
      agencyDigit: "",
      accountDigit: "",
      id: 0,
    };
    this.handleBlockCreditor();
  }

  @Watch("expirationDate")
  verifyData() {
    this.verifyEmergencialPayment();
  }

  @Watch("currentFile")
  setFile() {
    this.setFileUploadName();
    if (this.currentFile.size > 0) {
      this.showAttachmentsError = false;
    }
  }

  @Watch("grossValue")
  public setFirstValue() {
    if (this.costCentersPayment.length == 1)
      this.costCentersPayment[0].value = this.grossValue;
  }

  @Watch("costCenterXLS")
  public handleCostCenterXLS() {
    if (this.costCenterXLS.size !== 0) {
      this.postCostCenterImport();
    }
  }

  requiredRules: Array<any> = [
    (v: any): boolean | string => {
      if (v) {
        if (typeof v === "string" && v.trim().length === 0)
          return "Required field";
        return true;
      }
      if (v === false) return true;
      if (v === 0) return true;

      return "Required Field";
    },
  ];

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  disableDraft = false;
  blockSaveRequest = true;
  blockSaveDraft = true;
  showAttachmentsError = false;
  idToSave = 0;
  modes: { id: number; type: string }[] = [];

  loadingDraft = false;
  loadingSave = false;
  loadingCompany = false;
  loadingNFe = false;
  loadingCreditor = false;
  loadingDocumentType = false;
  loadingPaymentMethod = false;
  loadingPaymentType = false;
  loadingCostCenter = false;
  loadingFinancialBudget = false;
  loadingApprover = false;
  loadingImport = false;
  response = {};
  requestData = {};
  Observation: CostCenters[] = [];

  messageError = "";

  company = [];
  companySelected: Company = companyInitialValue;
  searchCompany = null;
  companyNFe = [];
  companyNFeSelected = { name: "", cnpj: "", id: 0 };
  creditor: Creditor[] = [];
  creditorSelected = {
    name: "",
    cpfCnpj: "",
    bank: "",
    agency: "",
    account: "",
    agencyDigit: "",
    accountDigit: "",
    id: 0,
  };
  document: DocumentType[] = [];
  documentSelected: DocumentType = {
    name: "",
    siengeValue: "",
    abbreviation: "",
    id: 0,
  };
  paymentForm: PaymentMethod[] = [];
  paymentFormSelected: PaymentMethod = {
    name: "",
    siengeValue: "",
    id: 0,
  };
  documentNumber = "";
  grossValue = "";
  currencyItems = ["GBP", "BRL", "EUR", "USD", "CAD"];
  currency = "USD";
  paymentType = [];
  paymentTypeSelected = {
    name: "",
    siengeValue: "",
    id: 0,
  };
  bankName = "";
  bankAgency = "";
  bankAccount = "";
  bankDigit = "";
  barCode = "";
  contributor = { name: "", cpfCnpj: "", id: 0 };
  revenueCode = "";
  emission = false;
  emissionDate = "";
  expiration = false;
  expirationDate = "";
  observations = "";
  voucherEmail = "";
  recursiveRequest = [
    { text: "Sim", value: true },
    { text: "Não", value: false },
  ];
  recursiveRequestSelected = { text: "Não", value: false };
  costCenter: CostCenter[] = [];
  costCenterSelected = {
    name: "",
    siengeValue: "",
    companyName: "",
    enabled: false,
    id: 0,
  };
  costCenterXLS: Blob = new Blob();
  costCenterImport: Blob[] = [];
  costCenterImportData: CostCenterImport[] = [];
  costCenterDataError: string[] = [];
  errorsImport: ErrorImport[] = [];
  IsACostCenter = false;
  data = 0;
  financialBudget: FinancialPlain[] = [];
  financialBudgetSelected = { name: "", siengeValue: "", id: 0 };
  value = "";

  costCentersPayment: CostCentersPayment[] = [];
  requester = "";
  sector = "";
  loadingSector = false;
  origin = "";

  approver: SelectApprover[] = [];
  approverSelected: number[] = [];
  modeSelected = 1;
  secondApproverSelected = EmptyApprover;
  complianceApproval = false;
  paymentSelected = "";
  referenceDate = "";
  reference = false;
  currentFile: Blob = new Blob();
  attachments: Blob[] = [];
  attachmentsName: any[] = [];
  base64: string = "";

  public real = {
    decimal: ",",
    thousands: ".",
    precision: 2,
  };

  dolar = {
    decimal: ".",
    thousands: ",",
    millions: ",",
    precision: 2,
  };

  libra = {
    decimal: ".",
    thousands: ",",
    precision: 2,
  };

  euro = {
    decimal: ".",
    thousands: ",",
    precision: 2,
  };

  canadianDollar = {
    decimal: ".",
    thousands: ",",
    millions: ",",
    precision: 2,
  };

  public toRequest() {
    this.$router.replace({
      path: "/requests",
    });
  }

  private endpoint = new Endpoint();
  public authService = new AuthService();
  public solicitationsAction = new SolicitationsAction();

  public async saveRequest() {
    if (!this.form.validate()) {
      if (this.currentFile.size === 0) {
        this.showAttachmentsError = true;
      }
      return;
    }

    const emissionDate = moment(this.emissionDate).format("YYYY-MM-DD");
    const expirationDate = moment(this.expirationDate).format("YYYY-MM-DD");
    const referenceDate =
      this.referenceDate === ""
        ? null
        : moment(this.referenceDate).format("YYYY-MM-DD");
    const paymentType =
      this.paymentTypeSelected.id === 0 ? null : this.paymentTypeSelected.id;
    const taxPayer = this.contributor.id === 0 ? null : this.contributor.id;

    const formData = new FormData();

    this.attachments.forEach((x) => formData.append("file", x));

    const params: RequestPayload = {
      id: this.idToSave,
      companyPaymentId: this.companySelected.id,
      companyNfe: this.companySelected.name,
      companyNfeCnpj: this.companySelected.cnpj,
      creditorId: this.creditorSelected.id,
      documentTypeId: this.documentSelected.id,
      paymentMethodId: this.paymentFormSelected.id,
      paymentTypeId: paymentType,
      documentNumber: this.documentNumber,
      grossValue: this.grossValue.toString(),
      currency: this.currency,
      emissionDate: emissionDate,
      expirationDate: expirationDate,
      observation: this.observations,
      thirdPartEmail: this.voucherEmail,
      recursive: false,
      costCenters: [] = this.costCentersPayment.map((item) => ({
        costCenterId: item.costCenterSelected.id,
        financialBudgetId: item.financialBudgetSelected.id,
        value: item.value.toString(),
      })),
      status: 1,
      taxPayerId: taxPayer,
      taxPayerCnpj: this.contributor.cpfCnpj,
      federalRevenueCode: this.revenueCode,
      darfReferenceDate: referenceDate,
      editableRow: this.barCode,
      bank: this.creditorSelected.bank,
      agency: this.creditorSelected.agency,
      account: this.creditorSelected.account,
      agencyDigit: this.creditorSelected.agencyDigit,
      accountDigit: this.creditorSelected.accountDigit,
      approverId: 0,
      approversId: this.approverSelected,
      approvalModeId: this.modeSelected,
      requesterId: 0,
      complianceApprovalRequired: this.complianceApproval,
      secondApproverId: undefined,
    };

    if (this.companySelected.requiresSecondApprover)
      params.secondApproverId = this.secondApproverSelected.id;
    if (this.currentFile.size !== 0) {
      try {
        this.loadingSave = true;
        const token = await this.authService.getTokenForMenuAsync();
        const response = await this.$http.post(
          this.endpoint.solicitation,
          params,
          {
            headers: {
              Authorization: token,
            },
          },
        );
        this.sendFiles(response);
      } catch (error) {
        const errorData = error as ErrorResponse;
        this.loadingSave = false;
        this.setFeedback({
          feedback: true,
          color: "red",
          text:
            errorData.response.status === 400
              ? `${errorData.response.data}`
              : "Ocorreu um erro",
        });
        setTimeout((): void => {
          this.setFeedback({
            feedback: false,
          });
        }, 4000);
      }
    } else {
      this.showAttachmentsError = true;
    }
  }

  public downloadCSVTemplate() {
    var anchor = document.createElement("a");
    anchor.setAttribute("href", "/files/Template-import-cost-centers.xlsx");
    anchor.setAttribute("download", "");
    document.body.appendChild(anchor);
    anchor.click();
    (anchor.parentNode as HTMLAnchorElement).removeChild(anchor);
  }

  public async postCostCenterImport() {
    try {
      this.loadingImport = true;
      this.setModalImportCSV(true);
      const formData = this.setFilesToUploadCostCenter();
      const token = await this.authService.getTokenForMenuAsync();
      const response = await this.$http.post(
        this.endpoint.costCenterImport +
          `/${this.companySelected.id}/fill-cost-centers`,
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        },
      );
      this.costCenterImportData = response;
      for (var i = 0; i < this.costCenterImportData.length; i++) {
        if (!this.costCenterImportData[i].success) {
          this.setErrorsImport(this.costCenterImportData[i]);
        } else {
          this.add();
          this.costCentersPayment.map((item, index) => {
            item.costCenterSelected = this.costCenter.find(
              (x) => x.id === this.costCenterImportData[index].costCenterId,
            ) as CostCenter;
            item.financialBudgetSelected = this.financialBudget.find(
              (x: FinancialPlain) =>
                x.id === this.costCenterImportData[index].financialBudgetId,
            ) as FinancialBudget;
            item.value = this.costCenterImportData[index].value;
          });
        }
      }

      this.loadingImport = false;

      if (this.errorsImport.length > 0) {
        this.IsACostCenter = true;
        this.setModalImportError(true);
      }
    } catch (error) {
      this.loadingImport = false;
      console.log("Error cost ", error);
    } finally {
      this.data++;
      this.costCentersPayment.pop();
    }

    const totalAmmount = parseFloat(this.grossValue);
    if (totalAmmount == 0) {
      var teste = 0;
      this.costCentersPayment.forEach(
        (x) => (teste += parseFloat(x.value.replace(",", ""))),
      );
      this.grossValue = parseFloat(teste.toString()).toFixed(2);
    }
  }

  public setErrorsImport(item: CostCenterImport) {
    this.errorsImport = [
      ...this.errorsImport,
      ...item.errors.map((error) => ({
        position: item.documentLine,
        error,
      })),
    ];
  }

  // public setFilesToUploadCostCenter() {
  //   if (this.costCenterXLS.size !== 0) {
  //     const formData = new FormData();
  //     this.costCenterImport.forEach(x => formData.append('file', x));

  //     return formData;
  //   } else {
  //     return;
  //   }
  // }

  types: any = {
    txt: { icon: "mdi-text-box", color: "blue-grey lighten-1" },
    pdf: { icon: "mdi-file-pdf-box", color: "red darken-3" },
    docx: { icon: "mdi-file-word", color: "cyan darken-2" },
    xlsx: { icon: "mdi-file-excel", color: "green darken-1" },
    csv: { icon: "mdi-file-excel", color: "green darken-1" },
    pptx: { icon: "mdi-file-word", color: "green darken-1" },
    key: { icon: "mdi-file-key", color: "yellow accent-2" },
    png: { icon: "mdi-file-png-box", color: "blue" },
    jpeg: { icon: "mdi-file-jpg-box", color: "blue" },
    default: { icon: "mdi-file-image", color: "light-blue " },
  };

  mappedFiles(file: any) {
    const type = this.types[file.name.split(".")[1]] || this.types["default"];

    return {
      name: file.name,
      icon: type.icon,
      color: type.color,
    };
  }

  public setFilesToUploadCostCenter() {
    const formData = new FormData();

    formData.append("file", this.costCenterXLS);

    return formData;
  }

  public verifyEmergencialPayment() {
    const expirationDate = moment(this.expirationDate).format("YYYY-MM-DD");
    const emergenceDays = 8; //TODO GetEmergenceDays from token
    const limitEmergenceDate = moment()
      .add(emergenceDays, "days")
      .format("YYYY-MM-DD");

    if (
      expirationDate <= limitEmergenceDate &&
      this.companySelected.fundAdministratorName === null
    ) {
      this.setModalEmergencyApproval(true);
    }
  }

  public setDocumentTypeForFundAdministrator() {
    const especificDocumentType = this.document.find(
      (item) => item.name === "INTERNO",
    ) as DocumentType;

    const especificPaymentMethod = this.paymentForm.find(
      (item) => item.id === 36,
    ) as PaymentMethod;
    if (
      this.companySelected.fundAdministratorName !== "" &&
      this.companySelected.fundAdministratorExternalAdm
    ) {
      this.documentSelected = especificDocumentType;
      this.paymentFormSelected = especificPaymentMethod;
    }
  }

  public handleBlockDocumentType() {
    const especificDocumentType = this.document.find(
      (item) => item.name === "INTERNO",
    ) as PaymentMethod;

    especificDocumentType["disabled"] = true;
  }

  public handleBlockPaymentMethod() {
    const especificPaymentMethod = this.paymentForm.find(
      (item) => item.id === 36,
    ) as PaymentMethod;

    especificPaymentMethod["disabled"] = true;
  }

  public handleBlockCreditor() {
    const especificCreditor = this.creditor.filter(
      (item) => item.siengeValue === null,
    ) as Creditor[];

    especificCreditor.forEach((item) => (item["disabled"] = true));

    if (this.companySelected.siengeId === null) {
      especificCreditor.forEach((item) => (item["disabled"] = false));
    }
  }

  public async saveDraft() {
    if (!this.form.validate()) {
      return;
    }

    const emissionDate = moment(this.emissionDate).format("YYYY-MM-DD");
    const expirationDate = moment(this.expirationDate).format("YYYY-MM-DD");
    const referenceDate =
      this.referenceDate === ""
        ? null
        : moment(this.referenceDate).format("YYYY-MM-DD");
    const paymentType =
      this.paymentTypeSelected.id === 0 ? null : this.paymentTypeSelected.id;
    const taxPayer = this.contributor.id === 0 ? null : this.contributor.id;

    const params: RequestPayload = {
      id: this.idToSave,
      companyPaymentId: this.companySelected.id,
      companyNfe: this.companySelected.name,
      companyNfeCnpj: this.companySelected.cnpj,
      creditorId: this.creditorSelected.id,
      documentTypeId: this.documentSelected.id,
      paymentMethodId: this.paymentFormSelected.id,
      paymentTypeId: paymentType,
      documentNumber: this.documentNumber,
      grossValue: this.grossValue.toString(),
      currency: this.currency,
      emissionDate: emissionDate,
      expirationDate: expirationDate,
      observation: this.observations,
      thirdPartEmail: this.voucherEmail,
      recursive: false,
      costCenters: [] = this.costCentersPayment.map((item) => {
        return {
          costCenterId: item.costCenterSelected.id,
          financialBudgetId: item.financialBudgetSelected.id,
          value: item.value.toString(),
        };
      }),
      status: 6,
      taxPayerId: taxPayer,
      taxPayerCnpj: this.contributor.cpfCnpj,
      federalRevenueCode: this.revenueCode,
      darfReferenceDate: referenceDate,
      editableRow: this.barCode,
      bank: this.creditorSelected.bank,
      agency: this.creditorSelected.agency,
      account: this.creditorSelected.account,
      agencyDigit: this.creditorSelected.agencyDigit,
      accountDigit: this.creditorSelected.accountDigit,
      approverId: 0,
      approversId: this.approverSelected,
      approvalModeId: this.modeSelected,
      requesterId: 0,
      complianceApprovalRequired: this.complianceApproval,
      secondApproverId: undefined,
    };
    if (this.companySelected.requiresSecondApprover)
      params.secondApproverId = this.secondApproverSelected.id;
    try {
      this.loadingDraft = true;
      const token = await this.authService.getTokenForMenuAsync();
      const response = await this.$http.post(this.endpoint.saveDraft, params, {
        headers: { Authorization: token },
      });
      if (this.currentFile.size !== 0) {
        this.sendFiles(response);
      } else {
        this.loadingDraft = false;
        this.setFeedback({
          feedback: true,
          color: "green",
          text: this.userLanguage === "en" ? "Draft saved" : "Rascunho salvo",
        });
        setTimeout((): void => {
          this.setFeedback({
            feedback: false,
          });
        }, 5000);
        this.toRequest();
      }
    } catch (error) {
      const errorData = error as ErrorResponse;
      this.loadingDraft = false;
      console.log(error);
      this.setFeedback({
        feedback: true,
        color: "red",
        text:
          errorData.response.status === 400
            ? `${errorData.response.data}`
            : "Ocorreu um erro",
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    }
  }

  public formatDataCpfCnpj(item: string) {
    if (!item.includes(".")) {
      var cpfValido = /^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}))$/;
      if (cpfValido.test(item) == false) {
        item = item.replace(/\D/g, ""); //Remove tudo o que não é dígito

        if (item.length == 11) {
          item = item.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
          item = item.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
          //de novo (para o segundo bloco de números)
          item = item.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
        } else {
          console.log("CPF invalido");
        }
      }
    } else {
      return item;
    }
  }

  // public handlePayload(payload: RequestPayload ) {
  //   const formData = new FormData();
  //   Object.keys(payload).forEach((key: string) => {
  //     if(Array.isArray(payload[key as keyof RequestPayload])) {
  //       const array = payload[key as keyof RequestPayload] as Array<Blob | MinimalCostCenter>
  //       array.forEach(element => element instanceof Blob ? formData.append(key, element) : formData.append(key, JSON.stringify(element)));
  //       return
  //     }
  //     formData.append(key, JSON.stringify(payload[key as keyof RequestPayload]))
  //   })
  //   console.log(formData);
  //   return formData;
  // }

  public getArchive() {
    if (this.currentFile.size === 0) {
      return;
    } else {
      this.attachments.push(this.currentFile);
      this.setFilesToUpload();
    }
  }

  public setFilesToUpload() {
    if (this.currentFile.size !== 0) {
      const formData = new FormData();
      this.attachments.forEach((x) => formData.append("file", x));

      return formData;
    } else {
      return;
    }
  }

  public setFileUploadName() {
    // @ts-ignore
    const fileInput = document.querySelector("#new-attachment").value;
    const fileName = fileInput.split(/(\\|\/)/g).pop();

    this.attachmentsName.push({ name: fileName });
  }

  public async sendFiles(id: number) {
    this.idToSave = id;
    const formData = this.setFilesToUpload();
    try {
      const response = await this.$http.post(
        this.endpoint.uploadFile + "?id=" + id,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      this.setFeedback({
        feedback: true,
        color: "green",
        text:
          this.userLanguage === "en" ? "Request sent" : "Solicitação enviada",
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);

      this.toRequest();
      this.loadingSave = false;
      this.loadingDraft = false;
    } catch (error) {
      const errorData = error as ErrorResponse;
      this.loadingSave = false;
      this.loadingDraft = false;
      console.log(error);
      if (errorData.response.status === 400) {
        this.messageError = errorData.response.data;
        this.setModalAttachmentError(true);
      } else {
        this.setFeedback({
          feedback: true,
          color: "red",
          text: "Ocorreu um erro",
        });
        setTimeout((): void => {
          this.setFeedback({
            feedback: false,
          });
        }, 5000);
      }
    }
  }

  public removeAttachments(item: Blob, index: any) {
    this.attachments.splice(index, 1);
    this.attachmentsName.splice(this.attachmentsName.indexOf(item), 1);
  }

  public handleFormatDate(date: string) {
    const dateFormatted = moment(date).format("DD/MMMM/YYYY");
    const dateSeparated = dateFormatted.split("/");
    const monthFormatted = dateSeparated[1]?.substring(0, 3);
    const dateResult =
      dateSeparated[0] + "/" + monthFormatted + "/" + dateSeparated[2];

    return date ? dateResult : "";
  }

  public async getDocumentTypes() {
    this.document = await this.solicitationsAction.getSolicitationsData(
      this.$http,
      this.endpoint.documentType,
      this.loadingDocumentType,
    );
  }

  public async getPaymentMethods() {
    this.paymentForm = await this.solicitationsAction.getSolicitationsData(
      this.$http,
      this.endpoint.paymentMethod,
      this.loadingPaymentMethod,
    );
  }

  public async getPaymentTypes() {
    this.paymentType = await this.solicitationsAction.getSolicitationsData(
      this.$http,
      this.endpoint.paymentType,
      this.loadingPaymentType,
    );
  }

  public async getCompanies() {
    const companies = await this.solicitationsAction.getSolicitationsData(
      this.$http,
      this.endpoint.company,
      this.loadingCompany,
    );
    this.company = await Promise.all(
      companies.filter((company: any) => company.enabled === true),
    );
  }

  public async getApprovers(id: number) {
    this.approver = await this.solicitationsAction.getSolicitationsData(
      this.$http,
      this.endpoint.companyApprover + `/${id}`,
      this.loadingApprover,
    );
  }

  public async getCompaniesNFe() {
    this.companyNFe = await this.solicitationsAction.getSolicitationsData(
      this.$http,
      this.endpoint.company,
      this.loadingNFe,
    );
  }

  public async getCreditors() {
    this.creditor = await this.solicitationsAction.getSolicitationsData(
      this.$http,
      this.endpoint.creditor,
      this.loadingCreditor,
    );
  }

  public async getCostCenters(id: number) {
    this.costCenter = await this.solicitationsAction.getSolicitationsData(
      this.$http,
      this.endpoint.costCenterByCompany + `/${id}`,
      this.loadingCostCenter,
    );
  }

  public async getFinancialBudgets() {
    const token = await this.authService.getTokenForMenuAsync();
    const financialBudgets = await this.$http.get(
      this.endpoint.financialPlain,
      {
        headers: { Authorization: token },
      },
    );
    this.financialBudget = financialBudgets;
  }

  public async getApproversMode() {
    try {
      this.modes = await this.$http.get(this.endpoint.approverModes);
      this.modeSelected = 3;
    } catch (e) {}
  }

  public async getSector() {
    this.sector = await this.solicitationsAction.getSolicitationsData(
      this.$http,
      this.endpoint.userSector,
      this.loadingSector,
    );
  }

  public setCurrency(value: any) {
    if (value === "R$   " || value === "BRL  ") {
      this.currency = "BRL";
    }
    if (
      value === "$    " ||
      value === "usa  " ||
      value === "uad  " ||
      value === "USD  " ||
      value === "USD$ " ||
      value === "US   " ||
      value === "U$   " ||
      value === "US$  "
    ) {
      this.currency = "USD";
    }
    if (
      value === "c$   " ||
      value === "C$   " ||
      value === "cad$ " ||
      value === "CAD$ " ||
      value === "can$ " ||
      value === "Can$ " ||
      value === "CAN$ " ||
      value === "CAD  " ||
      value === "cad  "
    ) {
      this.currency = "CAD";
    }
    if (value === "£    " || value === "GBP  " || value === "GDP  ") {
      this.currency = "GBP";
    }
    if (value === "EUR  ") {
      this.currency = "EUR";
    }
  }

  public add() {
    const newCostCenter = new CostCentersPayment();
    newCostCenter.value = this.getComplementValue();
    this.costCentersPayment.push(newCostCenter);
  }

  public getComplementValue() {
    const originalValue = parseFloat(this.grossValue);

    let allAddValues = 0;
    this.costCentersPayment.forEach(
      (x) => (allAddValues += parseFloat(x.value)),
    );

    if (originalValue - allAddValues <= 0) {
      return "0";
    } else {
      return parseFloat((originalValue - allAddValues).toString()).toFixed(2);
    }
  }

  public remove(item: CostCentersPayment) {
    this.costCentersPayment.splice(this.costCentersPayment.indexOf(item), 1);
  }

  public async mounted() {
    const creditorsPromise = this.getCreditors();
    this.getApproversMode();
    this.getDocumentTypes();
    this.getPaymentMethods();
    this.getCompanies();
    this.getCompaniesNFe();
    await creditorsPromise;
    this.handleBlockCreditor();
    this.getFinancialBudgets();
    this.getPaymentTypes();
    this.getSector();
    // this.downloadFile();
    this.requester = this.user.name;
  }

  public returnPaymentTypeId(payment: string) {
    if (payment === "TRANSFERÊNCIA PARA OUTROS BANCOS") {
      return 1;
    }
    if (payment === "BOLETO BANCARIO") {
      return 2;
    }
    if (payment === "CONCESSIONÁRIAS (AGUA, LUZ, TEL)") {
      return 3;
    }
    if (payment === "DARF NORMAL") {
      return 4;
    }
    if (payment === "IMPOSTO COM CÓDIGO DE BARRAS") {
      return 5;
    }
    if (payment === "TRANSFERÊNCIA PARA CONTA BRADESCO") {
      return 6;
    }
    if (payment === "PAGAMENTO NO EXTERIOR") {
      return 7;
    }
    if (payment === "CONCESSIONÁRIAS PAGA NO ITAU (AGUA, LUZ, TEL)") {
      return 8;
    }
    if (payment === "DÉBITO AUTOMÁTICO") {
      return 9;
    }
  }

  public formatDate(date: any) {
    return date ? moment(date).format("MM/DD/YYYY") : "";
  }

  public back() {
    this.$router.replace({
      path: "/requests",
    });
  }
}
