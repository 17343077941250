import UsersCompanies, { usersCompaniesInitialValue } from './usersCompanies';
import Access, { accessInitialValue } from './access';
import Companies, { companyInitialValue } from './companies';

export default class UserAccess {
  company: UsersCompanies = usersCompaniesInitialValue;
  companyId: number = 0;
  companySelected: Companies = companyInitialValue;
  accessType: Access = accessInitialValue;
  accessTypeSelected: Access = accessInitialValue;
}
