
























































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { Watch } from 'vue-property-decorator';
import Endpoint from '@/utils/endpoint';
import ErrorResponse from '@/@types/ErrorResponse';
import FeedbackInterface from '@/@types/feedback';

import qs from 'qs';

import { AuthService } from '../../services/authService';
import NewCreditor from '@/@types/newCreditor';

const namespace = 'add';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({})
export default class ModalConfirmCreditor extends Vue {
  @Getter('getModalConfirmNewCreditor', { namespace: 'auth' })
  public modalConfirmNewCreditor!: boolean;
  @Action('setModalConfirmNewCreditor', { namespace: 'auth' })
  public setModalConfirmNewCreditor!: any;

  @Getter('getFeedback', { namespace: 'auth' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'auth' }) public setFeedback: any;

  @Getter('getNewCreditorsSienge', { namespace: 'auth' })
  public newCreditorsSienge!: NewCreditor;
  @Action('setNewCreditorsSienge', { namespace: 'auth' })
  public setNewCreditorsSienge!: any;

  @Watch('modalConfirmNewCreditor')
  public handleGetCreditors() {
    this.getCreditorsData();
  }

  requiredRules: Array<any> = [
    (v: any): boolean | string => {
      if (v) {
        if (typeof v === 'string' && v.trim().length === 0)
          return 'Required field';
        return true;
      }
      if (v === false) return true;
      if (v === 0) return true;

      return 'Required Field';
    },
  ];

  loading = false;
  showSkeleton = false;
  showCredorBySienge = false;
  showTextNotFoundCredor = false;
  creditorSienge = '';
  creditors: NewCreditor[] = [];

  private endpoint = new Endpoint();
  authService = new AuthService();

  public async getCreditorsData() {
    this.showCredorBySienge = true;
    try {
      const token = await this.authService.getTokenForMenuAsync();
      const response = await this.$http.get(this.endpoint.creditorBySienge, {
        params: {
          siengeIds: this.newCreditorsSienge,
        },
        paramsSerializer: params => qs.stringify(params),
      });
      this.creditors = response;

      console.log('creditors', this.creditors);
    } catch (error) {
      console.log('Error no get de creditors', error);

      this.showCredorBySienge = false;
    }
    this.showCredorBySienge = false;
  }

  public async addNewCreditors() {
    // const response = await this.$http.post(
    //   this.endpoint.creditor + '/sienge',
    //   this.creditors
    // );

    // const params = {
    //   id: id,
    //   name: this.nameCreditor,
    //   identifier: this.removeCpfCnpjMask(),
    // };
    try {
      const params = this.creditors.map(item => item.data);

      const token = await this.authService.getTokenForMenuAsync();
      await this.$http.post(this.endpoint.creditor, params, {
        headers: {
          Authorization: token,
        },
      });
      this.setFeedback({
        feedback: true,
        color: 'green',
        text: this.$t('newCreditorSienge.credorSaved'),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 4000);
    } catch (error) {
      const errorData = error as ErrorResponse;
      console.log('Error no put do companies');
      this.setFeedback({
        feedback: true,
        color: 'red',
        text:
          errorData.response.status === 400
            ? `${errorData.response.data}`
            : 'Ocorreu um erro',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 4000);
    }
    this.closeModal();
  }

  public addNewCreditor() {
    const creditor = new NewCreditor();
    this.creditors.push(creditor);
  }

  public removeCreditor(id: number) {
    console.log(id);
    this.creditors.splice(id, 1);
  }

  public closeModal(): void {
    this.setModalConfirmNewCreditor(false);
  }
}
