








































































































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { Watch, Prop } from 'vue-property-decorator';
import Endpoint from '@/utils/endpoint';
import Header from '@/@types/header';
import FundAdministratorHeader from '@/components/headers/fundAdministrator-header';
import FundAdministratorHeaderPT from '@/components/headers/pt-br/fundAdministrator-header-pt';
import FundAdministrator, {
  fundAdministratorInitialValue,
} from '@/@types/fundAdministrator';
import FeedbackInterface from '@/@types/feedback';
import Company from '@/@types/companies';
import { AuthService } from '../../services/authService';
import ErrorResponse from '@/@types/ErrorResponse';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({})
export default class Companies extends Vue {
  @Prop(String) currentLanguage: string = 'en';
  @Prop(String) readonly id: string | undefined;

  @Getter('getUserLanguage', { namespace: 'auth' }) userLanguage!: any;
  @Action('setUserLanguage', { namespace: 'auth' }) setUserLanguage: any;

  @Getter('getFeedback', { namespace: 'auth' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'auth' }) public setFeedback: any;

  @Getter('getFundAdministratorData', { namespace: 'auth' })
  public fundAdministratorData!: FundAdministrator;
  @Action('setFundAdministratorData', { namespace: 'auth' })
  public setFundAdministratorData: any;

  headers: Header[] = FundAdministratorHeader.header; //idioma
  headersPT: Header[] = FundAdministratorHeaderPT.header; //idioma
  fundId: FundAdministrator = fundAdministratorInitialValue;
  search = null;
  loading = false;
  loadingData = false;
  isEditFundAdministrator = false;
  nameFundAdministrator = '';
  shortNameFundAdministrator = '';
  identifierFundAdministrator = '';
  emailFundAdministrator = '';
  mailGroupLeste = '';
  templatePTFundAdministrator = '';
  templateENFundAdministrator = '';
  externalAdm = true;
  businessUnity = '';

  private endpoint = new Endpoint();
  authService = new AuthService();

  // requiredRules: Array<any> = [
  //   (v: any): boolean | string => {
  //     if (v) {
  //       if (typeof v === 'string' && v.trim().length === 0)
  //         return 'Required field';
  //       return true;
  //     }
  //     if (v === false) return true;
  //     if (v === 0) return true;

  //     return this.$t('newSolicitation.required');
  //   },
  // ];

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  //   async getCompanies() {
  //     const token = await this.authService.getTokenForMenuAsync();
  //     this.loading = true;
  //     const response = await this.$http.get(this.endpoint.company, {
  //       headers: { Authorization: token },
  //     });
  //     const items = response;
  //     this.items = items;
  //     this.loading = false;
  //   }

  async mounted() {
    if (this.$route.name === 'EditFundAdministrator') {
      this.loading = true;
      this.setFundAdministratorItems(this.fundAdministratorData);
      this.isEditFundAdministrator = true;
    }
  }

  public async saveFundAdministrator() {
    if (!this.form.validate()) {
      return;
    }
    this.loadingData = true;
    const params = {
      name: this.nameFundAdministrator,
      shortName: this.shortNameFundAdministrator,
      identifier: this.identifierFundAdministrator,
      mailGroupLeste: this.mailGroupLeste !== '' ? this.mailGroupLeste : null,
      contactEmail: this.emailFundAdministrator,
      mailTemplatePt: this.templatePTFundAdministrator,
      mailTemplateEn: this.templateENFundAdministrator,
      externalAdm: this.externalAdm,
      businessUnity: this.businessUnity,
    };
    try {
      const token = await this.authService.getTokenForMenuAsync();
      this.$http.post(this.endpoint.fundAdministrators, params, {
        headers: {
          Authorization: token,
        },
      });
    } catch (error) {
      const errorData = error as ErrorResponse;
      console.log('Error no put do fund administrator');
      this.setFeedback({
        feedback: true,
        color: 'red',
        text:
          errorData.response.status === 400
            ? `${errorData.response.data}`
            : 'Ocorreu um erro',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 4000);
    } finally {
      this.loadingData = false;
      this.back();
    }
  }

  get rowNoteFieldPT() {
    if (this.templatePTFundAdministrator === null) return '1';
    let number = '1';
    if (this.templatePTFundAdministrator.length >= 160) number = '2';
    if (this.templatePTFundAdministrator.length >= 330) number = '3';
    if (this.templatePTFundAdministrator.length >= 500) number = '4';
    if (this.templatePTFundAdministrator.length >= 670) number = '5';
    return number;
  }

  get rowNoteFieldEN() {
    if (this.templateENFundAdministrator === null) return '1';
    let number = '1';
    if (this.templateENFundAdministrator.length >= 160) number = '2';
    if (this.templateENFundAdministrator.length >= 330) number = '3';
    if (this.templateENFundAdministrator.length >= 500) number = '4';
    if (this.templateENFundAdministrator.length >= 670) number = '5';
    return number;
  }

  public async savePutFundAdministrator() {
    if (!this.form.validate()) {
      return;
    }
    const params = {
      id: this.fundAdministratorData.id,
      name: this.nameFundAdministrator,
      shortName: this.shortNameFundAdministrator,
      identifier: this.identifierFundAdministrator,
      contactEmail: this.emailFundAdministrator,
      mailGroupLeste: this.mailGroupLeste !== '' ? this.mailGroupLeste : null,
      mailTemplatePt: this.templatePTFundAdministrator,
      mailTemplateEn: this.templateENFundAdministrator,
      externalAdm: this.externalAdm,
      businessUnity: this.businessUnity,
    };
    try {
      this.loadingData = true;
      const token = await this.authService.getTokenForMenuAsync();
      this.$http.put(this.endpoint.fundAdministrators, params, {
        headers: {
          Authorization: token,
        },
      });
      this.loadingData = false;
      this.back();
    } catch (error) {
      const errorData = error as ErrorResponse;
      this.loadingData = false;
      this.setFeedback({
        feedback: true,
        color: 'red',
        text:
          errorData.response.status === 400
            ? `${errorData.response.data}`
            : 'Ocorreu um erro',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 4000);
      console.log('Error no put do fund administrator');
    }
  }

  public async setSaveOrSavePutFundAdministrator() {
    if (this.isEditFundAdministrator) {
      this.savePutFundAdministrator();
    } else {
      this.saveFundAdministrator();
    }
  }

  setFundAdministratorItems(item: FundAdministrator) {
    this.nameFundAdministrator = item.name;
    this.shortNameFundAdministrator = item.shortName;
    this.identifierFundAdministrator = item.identifier;
    this.emailFundAdministrator = item.contactEmail;
    this.mailGroupLeste = item.mailGroupLeste;
    this.templatePTFundAdministrator = item.mailTemplatePt;
    this.templateENFundAdministrator = item.mailTemplateEn;
    this.externalAdm = item.externalAdm;
    this.businessUnity = item.businessUnity;
    this.loading = false;
  }

  back() {
    this.$router.replace({
      path: '/administrator',
    });
  }

  public toFundAdministrator() {
    this.$router.replace({
      path: '/administrator',
    });
  }
}
