import Area from '@/@types/auth/areas';
import CurrentRoute from '@/@types/auth/currentRoute';
import CompaniesNew from '@/@types/companiesNew';
import Creditor from '@/@types/creditors';
import Feedback from '@/@types/feedback';
import FundAdministrator from '@/@types/fundAdministrator';
import { JsonFeedback } from '@/@types/jsonFeedback';
import NewCreditor from '@/@types/newCreditor';
import SolicitationId from '@/@types/solicitationId';
import User from '../../@types/auth/user';
import { UserState } from './types';

export default {
  setUser(state: UserState, user: User) {
    state.user = user;
  },
  setAreas(state: UserState, areas: Area[]) {
    state.areas = areas;
  },
  setCurrentRoute(state: UserState, currentRoute: CurrentRoute) {
    state.currentRoute = currentRoute;
  },

  setModalDeny(state: UserState, show: boolean) {
    state.modalDeny = show;
  },

  setModalCancel(state: UserState, show: boolean) {
    state.modalCancel = show;
  },
  setModalApprove(state: UserState, show: boolean) {
    state.modalApprove = show;
  },
  setModalPay(state: UserState, show: boolean) {
    state.modalPay = show;
  },
  setModalAddStatement(state: UserState, show: boolean) {
    state.modalAddStatement = show;
  },
  setModalExtendExpiration(state: UserState, show: boolean) {
    state.modalExtendExpiration = show;
  },
  setModalEmergencyApproval(state: UserState, show: boolean) {
    state.modalEmergencyApproval = show;
  },
  setModalBankApproval(state: UserState, show: boolean) {
    state.modalBankApproval = show;
  },
  setFeedback(state: UserState, feedback: Feedback) {
    state.feedback = feedback;
  },
  setSolicitationId(state: UserState, solicitationId: SolicitationId) {
    state.solicitationid = solicitationId;
  },

  setUserLanguage(state: UserState, userLanguage: any) {
    state.userLanguage = userLanguage;
  },

  setModalNewCreditor(state: UserState, show: boolean) {
    state.modalNewCreditor = show;
  },
  setModalConfirmNewCreditor(state: UserState, show: boolean) {
    state.modalConfirmNewCreditor = show;
  },
  setNewCreditorsSienge(state: UserState, sienge: NewCreditor) {
    state.newCreditorsSienge = sienge;
  },
  setFundAdministratorData(state: UserState, sienge: FundAdministrator) {
    state.fundAdministratorData = sienge;
  },
  setCompaniesData(state: UserState, sienge: CompaniesNew) {
    state.companiesData = sienge;
  },
  setModalPending(state: UserState, sienge: boolean) {
    state.modalPending = sienge;
  },
  setModalApproverId(state: UserState, sienge: boolean) {
    state.modalApproverId = sienge;
  },
  setModalImportCSV(state: UserState, sienge: boolean) {
    state.modalImportRequest = sienge;
  },
  setModalImportError(state: UserState, sienge: boolean) {
    state.modalImportError = sienge;
  },
  setModalAttachmentError(state: UserState, sienge: boolean) {
    state.modalAttachmentError = sienge;
  },
  setModalExported(state: UserState, sienge: boolean) {
    state.modalExported = sienge;
  },
  setCreditorData(state: UserState, sienge: Creditor) {
    state.creditorsData = sienge;
  },
  setJsonFeedback(state: UserState, feedback: JsonFeedback) {    
    state.jsonFeedback = feedback;
  },
};
