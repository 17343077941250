import Header from '@/@types/header';

export default class companyHeaders {
  public static header: Header[] = [
    {
      text: 'Id',
      align: 'start',
      sortable: false,
      value: 'id',
      class: 'item-table-header item-table-header-company',
    },
    {
      text: 'Nome',
      align: 'start',
      sortable: false,
      value: 'name',
      class: 'item-table-header item-table-header-company',
    },
    {
      text: 'Sienge Id',
      align: 'start',
      sortable: false,
      value: 'siengeId',
      class: 'item-table-header item-table-header-company',
    },
    {
      text: 'Administrador do Fundo',
      align: 'start',
      sortable: false,
      value: 'fundAdministratorName',
      class: 'item-table-header item-table-header-company',
    },
    {
      text: 'CNPJ',
      align: 'start',
      sortable: false,
      value: 'cnpj',
      class: 'item-table-header item-table-header-company',
    },
    {
      text: 'Aprovadores Requeridos',
      align: 'center',
      sortable: true,
      value: 'requiredApprovers',
      class: 'item-table-header item-table-header-company',
    },
  ];
}
