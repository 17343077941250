
























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { Watch, Prop } from 'vue-property-decorator';
import Endpoint from '@/utils/endpoint';

import { AuthService } from '../../services/authService';
import SolicitationId from '@/@types/solicitationId';
import FeedbackInterface from '@/@types/feedback';

import RequestPayload, { MinimalCostCenter } from '@/@types/payload';

import qs from 'qs';

const namespace = 'add';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({})
export default class modalDeny extends Vue {
  @Getter('getSolicitationId', { namespace: 'auth' })
  public solicitation!: SolicitationId;

  @Getter('getModalApproverId', { namespace: 'auth' })
  public modalApproverId!: boolean;
  @Action('setModalApproverId', { namespace: 'auth' })
  public setModalApproverId!: any;

  @Getter('getFeedback', { namespace: 'auth' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'auth' }) public setFeedback: any;

  @Getter('getUserLanguage', { namespace: 'auth' }) public userLanguage!: any;
  @Action('setUserLanguage', { namespace: 'auth' }) public setUserLanguage: any;

  @Prop(Array) paymentRequestSelected!: RequestPayload[];

  @Prop(Boolean) loading!: boolean;

  @Watch('loading')
  public handleCloseModal() {
    if (this.loading === false) {
      this.closeModal();
    }
  }

  currentFile: Blob = new Blob();
  vouchername = '';

  private endpoint = new Endpoint();
  authService = new AuthService();

  public approverIdModal() {
    this.$emit('approverIdModal');
  }

  get getSizeSolicitations() {
    return this.paymentRequestSelected.filter(item => item.userCanApprove)
      .length;
  }

  closeModal(): void {
    this.setModalApproverId(false);
  }
}
