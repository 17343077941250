import Header from "@/@types/header";
export default class SolcitationsHeader {
  public static header: Header[] = [
    {
      text: "Sienge",
      align: "start",
      sortable: false,
      value: "siengeValue",
      class: "item-table-header item-table-header-solicitations",
    },
    {
      text: "Id",
      align: "start",
      sortable: false,
      value: "id",
      class: "item-table-header item-table-header-solicitations",
    },
    {
      text: "Empresa Pagamento",
      align: "start",
      sortable: true,
      value: "companyName",
      class: "item-table-header item-table-header-solicitations",
    },
    {
      text: "Inclusão",
      align: "start",
      sortable: true,
      value: "emissionDate",
      class: "item-table-header item-table-header-solicitations",
    },
    {
      text: "Vencimento",
      align: "start",
      sortable: true,
      value: "expirationDate",
      class: "item-table-header item-table-header-solicitations",
    },
    {
      text: "Credor",
      align: "start",
      sortable: true,
      value: "creditorName",
      class: "item-table-header item-table-header-solicitations",
    },
    {
      text: "Solicitante",
      align: "start",
      sortable: true,
      value: "requesterName",
      class: "item-table-header item-table-header-solicitations",
    },
    {
      text: "Valor",
      align: "start",
      sortable: true,
      value: "value",
      class: "item-table-header item-table-header-solicitations",
    },
    {
      text: "Fonte",
      align: "center",
      sortable: true,
      value: "fonte",
      class: "item-table-header item-table-header-solicitations",
    },
    {
      text: "Status",
      align: "start",
      sortable: true,
      value: "status",
      class: "item-table-header item-table-header-solicitations",
    },
  ];
}
