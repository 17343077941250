var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"app"}},[(_vm.user !== null)?_c('v-navigation-drawer',{attrs:{"mini-variant":_vm.mini,"app":"","permanent":"","color":"#013767","width":"220px","expand-on-hover":""},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item',{staticClass:"pt-6 px-6 mb-4",attrs:{"two-line":""}},[_c('v-list-item-content',{staticClass:"text-center"},[_c('v-list-item-title',{staticClass:"text-lg-h6 menu-item pt-0 mb-4"},[_c('img',{attrs:{"src":require("./assets/FINTI-logo.svg"),"alt":"Logo FINTI","width":"90%"}})])],1)],1),(!_vm.mini && _vm.showHeadRegisterMenus)?_c('v-subheader',{staticClass:"menu-subheader"},[(_vm.userLanguage === 'en')?_c('span',{staticClass:"menu-item-text"},[_vm._v("Register")]):_c('span',{staticClass:"menu-item-text"},[_vm._v("Cadastro")])]):_vm._e(),(_vm.canReadCreditor)?_c('v-list-item',{staticClass:"menu-list",class:{ menuActive: this.$route.name === 'Creditors' },staticStyle:{"height":"14px !important"},on:{"click":function($event){return _vm.replaceRouter('creditors')}}},[_c('v-list-item-action',{staticClass:"mr-1"},[(_vm.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"menu-item-icon",class:{ menuCollapsed: _vm.mini },attrs:{"src":require("./assets/Creditors-logo.svg"),"alt":"Investiments Icon"}},'img',attrs,false),on))]}}],null,false,1731837848)},[(_vm.userLanguage === 'en')?_c('span',{staticClass:"menu-item-text"},[_vm._v("Creditors")]):_c('span',{staticClass:"menu-item-text"},[_vm._v("Credores")])]):_vm._e(),(!_vm.mini)?_c('img',{staticClass:"menu-item-icon",class:{ menuCollapsed: _vm.mini },attrs:{"src":require("./assets/Creditors-logo.svg"),"alt":"Investiments Icon"}}):_vm._e()],1),_c('v-list-item-title',{staticClass:"menu-item route-action"},[(_vm.userLanguage === 'en')?_c('span',{staticClass:"menu-item-text"},[_vm._v("Creditors")]):_c('span',{staticClass:"menu-item-text"},[_vm._v("Credores")])])],1):_vm._e(),(_vm.canReadCompany)?_c('v-list-item',{staticClass:"menu-list",class:{ menuActive: this.$route.name === 'Companies' },staticStyle:{"height":"14px !important"},on:{"click":function($event){return _vm.replaceRouter('companies')}}},[_c('v-list-item-action',{staticClass:"mr-1"},[(_vm.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"menu-item-icon",class:{ menuCollapsed: _vm.mini },attrs:{"src":require("./assets/Company-logo.svg"),"alt":"Investiments Icon"}},'img',attrs,false),on))]}}],null,false,3569576700)},[(_vm.userLanguage === 'en')?_c('span',{staticClass:"menu-item-text"},[_vm._v("Companies")]):_c('span',{staticClass:"menu-item-text"},[_vm._v("Empresas")])]):_vm._e(),(!_vm.mini)?_c('img',{staticClass:"menu-item-icon",class:{ menuCollapsed: _vm.mini },attrs:{"src":require("./assets/Company-logo.svg"),"alt":"Investiments Icon"}}):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"menu-item route-action"},[(_vm.userLanguage === 'en')?_c('span',{staticClass:"menu-item-text"},[_vm._v("Companies")]):_c('span',{staticClass:"menu-item-text"},[_vm._v("Empresas")])])],1)],1):_vm._e(),(_vm.canReadCostCenter)?_c('v-list-item',{staticClass:"menu-list",class:{ menuActive: this.$route.name === 'CostCenter' },staticStyle:{"height":"14px !important"},on:{"click":function($event){return _vm.replaceRouter('cost')}}},[_c('v-list-item-action',{staticClass:"mr-1"},[(_vm.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"menu-item-icon",class:{ menuCollapsed: _vm.mini },attrs:{"src":require("./assets/Cost-logo.svg"),"alt":"Investiments Icon"}},'img',attrs,false),on))]}}],null,false,1860500368)},[(_vm.userLanguage === 'en')?_c('span',{staticClass:"menu-item-text"},[_vm._v("Cost centers")]):_c('span',{staticClass:"menu-item-text"},[_vm._v("Centros de custo")])]):_vm._e(),(!_vm.mini)?_c('img',{staticClass:"menu-item-icon",class:{ menuCollapsed: _vm.mini },attrs:{"src":require("./assets/Cost-logo.svg"),"alt":"Notifications Icon"}}):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"menu-item route-action"},[(_vm.userLanguage === 'en')?_c('span',{staticClass:"menu-item-text"},[_vm._v("Cost centers")]):_c('span',{staticClass:"menu-item-text"},[_vm._v("Centros de custo")])])],1)],1):_vm._e(),(_vm.canReadFinancialBudget)?_c('v-list-item',{staticClass:"menu-list",class:{
          menuActive: this.$route.name === 'FinancialPlain',
        },staticStyle:{"height":"14px !important"},on:{"click":function($event){return _vm.replaceRouter('financial')}}},[_c('v-list-item-action',{staticClass:"mr-1"},[(_vm.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"menu-item-icon",class:{ menuCollapsed: _vm.mini },attrs:{"src":require("./assets/Financial-logo.svg"),"alt":"Investiments Icon"}},'img',attrs,false),on))]}}],null,false,2436503314)},[(_vm.userLanguage === 'en')?_c('span',{staticClass:"menu-item-text"},[_vm._v("Financial plan")]):_c('span',{staticClass:"menu-item-text"},[_vm._v("Plano financeiro")])]):_vm._e(),(!_vm.mini)?_c('img',{staticClass:"menu-item-icon",class:{ menuCollapsed: _vm.mini },attrs:{"src":require("./assets/Financial-logo.svg"),"alt":"Notifications Icon"}}):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"menu-item route-action"},[(_vm.userLanguage === 'en')?_c('span',{staticClass:"menu-item-text"},[_vm._v("Financial plan")]):_c('span',{staticClass:"menu-item-text"},[_vm._v("Plano financeiro")])])],1)],1):_vm._e(),(_vm.canReadFundAdministrator)?_c('v-list-item',{staticClass:"menu-list",class:{
          menuActive: this.$route.name === 'FundAdministrator',
          'mb-4': !_vm.mini,
        },staticStyle:{"height":"14px !important"},on:{"click":function($event){return _vm.replaceRouter('administrator')}}},[_c('v-list-item-action',{staticClass:"mr-1"},[(_vm.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"menu-item-icon-fund ml-1",class:{ menuCollapsed: _vm.mini },attrs:{"alt":"Investiments Icon","size":"18"}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-cash-outline ")])]}}],null,false,1836222944)},[(_vm.userLanguage === 'en')?_c('span',{staticClass:"menu-item-text"},[_vm._v("Fund Administrator")]):_c('span',{staticClass:"menu-item-text"},[_vm._v("Administrador de Fundos")])]):_vm._e(),(!_vm.mini)?_c('v-icon',{staticClass:"menu-item-icon-fund ml-1",class:{ menuCollapsed: _vm.mini },attrs:{"size":"18","alt":"Notifications Icon"}},[_vm._v("mdi-account-cash-outline")]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"menu-item route-action"},[(_vm.userLanguage === 'en')?_c('span',{staticClass:"menu-item-text"},[_vm._v("Fund Administrator")]):_c('span',{staticClass:"menu-item-text"},[_vm._v("Administrador de Fundos")])])],1)],1):_vm._e(),(!_vm.mini && _vm.showHeadTitlesMenu)?_c('v-subheader',{staticClass:"menu-subheader"},[(_vm.userLanguage === 'en')?_c('span',{staticClass:"menu-item-text"},[_vm._v("Titles")]):_c('span',{staticClass:"menu-item-text"},[_vm._v("Títulos")])]):_vm._e(),(_vm.canReadPaymentRequest)?_c('v-list-item',{staticClass:"menu-list",class:{
          menuActive:
            this.$route.name === 'Payment Request' ||
            this.$route.name === 'EditRequest' ||
            this.$route.name === 'CopyRequest' ||
            this.$route.name === 'viewRequest' ||
            this.$route.name === 'NewRequest',
        },staticStyle:{"height":"14px !important"},on:{"click":function($event){return _vm.replaceRouter('requests')}}},[_c('v-list-item-action',{staticClass:"mr-1"},[(_vm.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"menu-item-icon",class:{ menuCollapsed: _vm.mini },attrs:{"src":require("./assets/Requests-logo.svg"),"alt":"Investiments Icon"}},'img',attrs,false),on))]}}],null,false,228116985)},[(_vm.userLanguage === 'en')?_c('span',{staticClass:"menu-item-text"},[_vm._v("Payment request")]):_c('span',{staticClass:"menu-item-text"},[_vm._v("Solicitações")])]):_vm._e(),(!_vm.mini)?_c('img',{staticClass:"menu-item-icon",class:{ menuCollapsed: _vm.mini },attrs:{"src":require("./assets/Requests-logo.svg"),"alt":"Notifications Icon"}}):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"menu-item route-action"},[(_vm.userLanguage === 'en')?_c('span',{staticClass:"menu-item-text"},[_vm._v("Payment request")]):_c('span',{staticClass:"menu-item-text"},[_vm._v("Solicitações")])])],1)],1):_vm._e(),(!_vm.mini && _vm.showHeadConfigurationsMenu)?_c('v-subheader',{staticClass:"menu-subheader"},[(_vm.userLanguage === 'en')?_c('span',{staticClass:"menu-item-text"},[_vm._v("Configuration")]):_c('span',{staticClass:"menu-item-text"},[_vm._v("Configuração")])]):_vm._e(),(_vm.canReadUser)?_c('v-list-item',{staticClass:"menu-list",class:{
          menuActive:
            this.$route.name === 'Users' ||
            this.$router.name === 'InnerUsers',
        },staticStyle:{"height":"14px !important"},on:{"click":function($event){return _vm.replaceRouter('users')}}},[_c('v-list-item-action',{staticClass:"mr-1"},[(_vm.mini)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"menu-item-icon",class:{ menuCollapsed: _vm.mini },attrs:{"src":require("./assets/Users-logo.svg"),"alt":"Investiments Icon"}},'img',attrs,false),on))]}}],null,false,663801977)},[(_vm.userLanguage === 'en')?_c('span',{staticClass:"menu-item-text"},[_vm._v("Users")]):_c('span',{staticClass:"menu-item-text"},[_vm._v("Usuários")])]):_vm._e(),(!_vm.mini)?_c('img',{staticClass:"menu-item-icon",class:{ menuCollapsed: _vm.mini },attrs:{"src":require("./assets/Users-logo.svg"),"alt":"Notifications Icon"}}):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"menu-item route-action"},[(_vm.userLanguage === 'en')?_c('span',{staticClass:"menu-item-text"},[_vm._v("Users")]):_c('span',{staticClass:"menu-item-text"},[_vm._v("Usuários")])])],1)],1):_vm._e()],1)],1):_vm._e(),_c('v-content',{staticStyle:{"padding-left":"56px"}},[(_vm.isLogged && !_vm.drawer)?_c('div',{staticClass:"d-flex justify-space-between align-center mt-5"},[_c('v-app-bar-nav-icon',{staticClass:"ml-3"})],1):_vm._e(),_c('v-app-bar',{staticClass:"header-menu elevation-0",class:{ newWidth: !_vm.mini },attrs:{"color":"#FFFFFF"}},[_c('span',{staticClass:"finti-title",class:{ titleOpen: _vm.mini }},[_vm._v("FINTI")]),_c('v-spacer'),_c('v-avatar',{staticClass:"change-language mr-4",attrs:{"size":"28","color":"#07457B"},on:{"click":function($event){return _vm.changeLanguage(_vm.userLanguage)}}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.userLanguage))])]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"user-dropdown text-capitalize d-flex align-center"},'div',attrs,false),on),[_c('v-avatar',{staticClass:"user-image mr-3",attrs:{"size":"36"}},[_c('img',{attrs:{"src":_vm.imgUrl,"alt":"John"}})]),_c('p',{staticClass:"user-name mb-0 mr-2"},[_vm._v(_vm._s(_vm.user.name))]),_c('v-icon',{attrs:{"color":"#91A7BF"}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.logout}},[_c('v-list-item-title',[_vm._v("Logout")])],1)],1)],1)],1),_c('router-view',{class:{ newMargin: !_vm.mini },staticStyle:{"margin-top":"56px"},attrs:{"isOpen":_vm.mini,"canListItens":this.canReadPaymentRequest}})],1),(_vm.$route.name !== 'Login')?_c('div',[_c('ApplicationModals')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }