import Vue from 'vue';
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

function removeStatusStorage() {
  Vue.$cookies.remove('dateTo');
  Vue.$cookies.remove('dateFrom');
  Vue.$cookies.remove('status');
}

export default removeStatusStorage;
