import Area from '@/@types/auth/areas';
import CurrentRoute from '@/@types/auth/currentRoute';
import User from '@/@types/auth/user';
import CompaniesNew from '@/@types/companiesNew';
import Creditor from '@/@types/creditors';
import Feedback from '@/@types/feedback';
import FundAdministrator from '@/@types/fundAdministrator';
import { JsonFeedback } from '@/@types/jsonFeedback';
import NewCreditor from '@/@types/newCreditor';
import SolicitationId from '@/@types/solicitationId';
import { ActionContext } from 'vuex';
import { UserState } from './types';

export default {
  setUser(store: ActionContext<UserState, any>, user: User): void {
    store.commit("setUser", user);
  },

  setAreas(store: ActionContext<UserState, any>, areas: Area[]): void {
    store.commit("setAreas", areas);
  },
  setCurrentRoute(
    store: ActionContext<UserState, any>,
    currentRoute: CurrentRoute
  ): void {
    store.commit("setCurrentRoute", currentRoute);
  },

  disconnectToken(store: ActionContext<UserState, any>): void {
    store.commit("setUser", null);
  },

  setModalDeny(store: ActionContext<UserState, any>, show: boolean) {
    store.commit("setModalDeny", show);
  },
  setModalCancel(store: ActionContext<UserState, any>, show: boolean) {
    store.commit("setModalCancel", show);
  },
  setModalApprove(store: ActionContext<UserState, any>, show: boolean) {
    store.commit("setModalApprove", show);
  },
  setModalPay(store: ActionContext<UserState, any>, show: boolean) {
    store.commit("setModalPay", show);
  },
  setModalAddStatement(store: ActionContext<UserState, any>, show: boolean) {
    store.commit("setModalAddStatement", show);
  },
  setModalExtendExpiration(store: ActionContext<UserState, any>, show: boolean){
    store.commit("setModalExtendExpiration", show);
  },
  setModalEmergencyApproval(
    store: ActionContext<UserState, any>,
    show: boolean
  ) {
    store.commit("setModalEmergencyApproval", show);
  },
  setModalBankApproval(store: ActionContext<UserState, any>, show: boolean) {
    store.commit('setModalBankApproval', show);
  },
  setFeedback(store: ActionContext<UserState, any>, feedback: Feedback) {
    store.commit("setFeedback", feedback);
  },
  setSolicitationId(
    store: ActionContext<UserState, any>,
    solicitationId: SolicitationId
  ) {
    store.commit("setSolicitationId", solicitationId);
  },

  setUserLanguage(store: ActionContext<UserState, any>, userLanguage: String) {
    store.commit("setUserLanguage", userLanguage);
  },
  setModalNewCreditor(store: ActionContext<UserState, any>, show: boolean) {
    store.commit("setModalNewCreditor", show);
  },
  setModalConfirmNewCreditor(
    store: ActionContext<UserState, any>,
    show: boolean
  ) {
    store.commit("setModalConfirmNewCreditor", show);
  },
  setNewCreditorsSienge(
    store: ActionContext<UserState, any>,
    sienge: NewCreditor
  ) {
    store.commit("setNewCreditorsSienge", sienge);
  },
  setFundAdministratorData(
    store: ActionContext<UserState, any>,
    solicitationId: FundAdministrator
  ) {
    store.commit("setFundAdministratorData", solicitationId);
  },
  setCompaniesData(
    store: ActionContext<UserState, any>,
    solicitationId: CompaniesNew
  ) {
    store.commit("setCompaniesData", solicitationId);
  },
  setModalPending(store: ActionContext<UserState, any>, show: boolean) {
    store.commit('setModalPending', show);
  },
  setStatusPending(store: ActionContext<UserState, any>, show: number) {
    store.commit('setStatusPending', show);
  },
  setModalApproverId(store: ActionContext<UserState, any>, show: boolean) {
    store.commit('setModalApproverId', show);
  },
  setModalImportCSV(store: ActionContext<UserState, any>, show: boolean) {
    store.commit('setModalImportCSV', show);
  },
  setModalImportError(store: ActionContext<UserState, any>, show: boolean) {
    store.commit('setModalImportError', show);
  },
  setModalAttachmentError(store: ActionContext<UserState, any>, show: boolean) {
    store.commit('setModalAttachmentError', show);
  },
  setModalExported(store: ActionContext<UserState, any>, show: boolean) {
    store.commit('setModalExported', show);
  },
  setCreditorData(
    store: ActionContext<UserState, any>,
    solicitationId: Creditor
  ) {
    store.commit("setCreditorData", solicitationId);
  },
  setJsonFeedback(store: ActionContext<UserState, any>, feedback: JsonFeedback): void {
    store.commit('setJsonFeedback', feedback);
  },
};
