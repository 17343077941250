import i18n from '@/plugins/i18n';
import VueSkeletonLoader from 'skeleton-loader-vue';
import money from 'v-money';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import vueDebounce from 'vue-debounce';
import VueTheMask from 'vue-the-mask';
import App from './App.vue';
import Http from './plugins/http';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.use(money);
Vue.use(new Http());
Vue.use(VueTheMask);
Vue.component('vue-skeleton-loader', VueSkeletonLoader);
Vue.use(vueDebounce, {
  listenTo: 'input',
  defaultTime: '1000ms',
});
Vue.use(VueCookies);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');
