export default interface StatementPaymentInterface {
    paymentRequestId: number,
    paymentSiengeId: string,
    installmentNumber: number,
    paymentDate: string,
    bankAuthentication: string,
    netPaidAmount: number,
    accountPaymentCompany: string,
    paymentJson: any,
    grossAmount: number,
    interestAmount: number,
    fineAmount: number,
    discountAmount: number,
    taxAmount: number,
    monetaryCorrectionAmount: number
}

const statementPaymentInitialValue: StatementPaymentInterface = {
    paymentRequestId: 0,
    paymentSiengeId: "",
    installmentNumber: 0,
    paymentDate: "",
    bankAuthentication: "",
    netPaidAmount: 0,
    accountPaymentCompany: "",
    paymentJson: undefined,
    grossAmount: 0,
    interestAmount: 0,
    fineAmount: 0,
    discountAmount: 0,
    taxAmount: 0,
    monetaryCorrectionAmount: 0
  };
  
  export { statementPaymentInitialValue };
