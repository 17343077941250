export default interface constCenterSelected {
  name: string;
  siengeValue: string;
  companyName: string;
  enabled: boolean;
  id: number;
}

const constCenterSelectedInitialValue: constCenterSelected = {
  name: '',
  siengeValue: '',
  companyName: '',
  enabled: true || false,
  id: 0,
};

export { constCenterSelectedInitialValue };
