
















































































































































































































































































































































































































































































import FeedbackInterface from "@/@types/feedback";
import { JsonFeedback as JsonFeedbackInterface } from "@/@types/jsonFeedback";
import ApplicationModals from "@/components/ApplicationModals.vue";
import i18n from "@/plugins/i18n";
import removeStatusStorage from "@/utils/removeStatusStorage";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import "vue-json-pretty/lib/styles.css";
import { Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import User from "./@types/auth/user";
import { AuthService } from "./services/authService";
import Auth from "./utils/auth";
import Endpoint from "./utils/endpoint";

@Component({
  components: {
    ApplicationModals,
  },
})
export default class App extends Vue {
  @Getter("getIsLogged", { namespace: "auth" }) isLogged!: boolean;
  @Getter("getUser", { namespace: "auth" }) user!: User;
  @Getter("getAreas", { namespace: "auth" }) public getAreas!: any[];
  @Getter("getFeedback", { namespace: "auth" }) feedback!: FeedbackInterface;
  @Action("setAreas", { namespace: "auth" }) setAreas: any;
  @Action("setCurrentRoute", { namespace: "auth" }) setCurrentRoute: any;
  @Action("setUser", { namespace: "auth" }) setUser: any;
  @Action("setEntityType", { namespace: "entity" }) setEntityType!: any;
  @Action("setNewEntity", { namespace: "entity" }) setNewEntity!: any;
  @Action("setFeedback", { namespace: "auth" }) setFeedback: any;

  @Getter("getUserLanguage", { namespace: "auth" }) userLanguage!: any;
  @Action("setUserLanguage", { namespace: "auth" }) setUserLanguage: any;

  @Getter("getJsonFeedback", { namespace: "auth" })
  jsonFeedback!: JsonFeedbackInterface;
  @Action("setJsonFeedback", { namespace: "auth" }) setJsonFeedback: any;

  @Watch("$route")
  private route() {
    this.setCurrentRoute({
      parent: this.$route.meta.parent,
      name: this.$route.name,
    });
  }

  public removeStatusStorage = removeStatusStorage;

  mini = false;
  areas = [];
  drawer = null;
  currentLanguage = "en";
  languages = ["pt", "en"];
  authService = new AuthService();
  instance = axios.create();
  gettingMenu = false;
  value = 0;
  imgUrl = "";
  canListPaymentRequest = false;

  //Check MenusPermissions
  canReadCreditor = false;
  canReadCompany = false;
  canReadCostCenter = false;
  canReadFinancialBudget = false;
  canReadFundAdministrator = false;
  canReadPaymentRequest = false;
  canReadUser = false;
  showHeadRegisterMenus = false;
  showHeadTitlesMenu = false;
  showHeadConfigurationsMenu = false;

  private endpoint = new Endpoint();
  interval = {} as number;

  async created() {
    await this.initialize();
  }

  async initialize(): Promise<void> {
    //if (this.$route.name !== 'Logout' && this.$route.name !== 'Login') {
    const userData = this.authService.getTokenData();
    if (userData) {
      this.setUser(userData);
      await this.validateMenus();

      //await this.getMenus();
      await this.getGraphToken();

      if (this.$route.name == null) {
        window.location.href = "/";
      }
    } else {
      if (this.$route.name != "Login") {
        this.$router.replace({ name: "Login" });
      }
    }
    //}
  }

  async getMenus(): Promise<void> {
    try {
      this.gettingMenu = true;

      //const resp = await this.$http
      //  .get(`${this.endpoint.auth.getMenus}/${process.env.VUE_APP_ID}
      //  /${this.user.email}`);
      const token = await this.authService.getTokenForMenuAsync();
      const resp = await this.$http.get(
        `${this.endpoint.auth.getMenus}/${this.user.email}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      let data = resp.data;
      if (resp.hasStatusErrors) {
        data = [];
        console.error(`${resp.statusCode.item2} (${resp.statusCode.item1})`);
      }
      this.setAreas(data);
      this.areas = data;
      /**this.canListPaymentRequest = Auth.verifyPermission(
        data,
        'Payment Request',
        'List'
      );*/

      this.gettingMenu = false;
      clearInterval(this.interval);
    } catch (error) {
      console.log("error menus", error);
    }
  }

  async validateMenus() {
    await this.getMenus();

    this.canReadCreditor = this.validateSpecificMenu("Creditors");
    this.canReadCompany = this.validateSpecificMenu("Companies");
    this.canReadCostCenter = this.validateSpecificMenu("Cost Centers");
    this.canReadFinancialBudget = this.validateSpecificMenu("Financial Plan");
    this.canReadFundAdministrator = this.validateSpecificMenu("Financial Plan");
    this.canReadPaymentRequest = this.validateSpecificMenu("Payment Request");
    this.canReadUser = this.validateSpecificMenu("Users");

    (this.showHeadRegisterMenus =
      this.canReadCreditor &&
      this.canReadCompany &&
      this.canReadCostCenter &&
      this.canReadFinancialBudget &&
      this.canReadFundAdministrator),
      (this.showHeadTitlesMenu = this.canReadPaymentRequest);
    this.showHeadConfigurationsMenu = this.canReadUser;
  }

  validateSpecificMenu(menu: string) {
    const result = Auth.verifyPermission(this.areas, menu, "List");
    return result;
  }

  async getGraphToken(): Promise<void> {
    const graphToken = await this.authService.getTokenGraph();
    if (graphToken != null) {
      try {
        const resp = await this.instance({
          method: "get",
          url: "https://graph.microsoft.com/v1.0/me/photos/48x48/$value",
          responseType: "blob",
          headers: { Authorization: `Bearer ${graphToken}` },
        });
        this.imgUrl = URL.createObjectURL(resp.data);
      } catch (error) {
        this.imgUrl = require("./assets/avatar.png");
      }
    } else {
      this.imgUrl = require("./assets/avatar.png");
    }
  }

  public changeLanguage(currentLanguage: string): void {
    if (currentLanguage === "en") {
      this.setUserLanguage("pt");
      i18n.locale = this.userLanguage;
    }
    if (currentLanguage === "pt") {
      this.setUserLanguage("en");
      i18n.locale = this.userLanguage;
    }
  }

  async haveReadPermission(menu: string): Promise<boolean> {
    if (this.areas == null) {
      await this.getMenus();
    }
    const result = Auth.verifyPermission(this.areas, menu, "List");
    return result;
  }

  replaceRouter(router: string): void {
    this.$router.replace({
      path: `/${router}`,
    });
    this.removeStatusStorage();
  }

  public logout(): void {
    sessionStorage.clear();
    localStorage.clear();
    this.authService.logoutAsync();
    window.location.href = "/";
    //TODO?+:Send to Logout page
  }

  mounted() {
    this.interval = setInterval(() => {
      if (this.value === 100) {
        return (this.value = 0);
      }
      this.value += 10;
    }, 500);
    this.mini = true;
    this.setUserLanguage("en");
  }
}
