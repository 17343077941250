





























































import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";
import { AuthService } from "../services/authService";
import Endpoint from "../utils/endpoint";
@Component({})
export default class Login extends Vue {
  authService = new AuthService();
  @Getter("getUser", { namespace: "auth" }) loggedUser: any;
  @Action("setUser", { namespace: "auth" }) setUser: any;
  @Action("setAreas", { namespace: "auth" }) setAreas: any;
  loading = false;
  errorLogin = false;
  isLogged = false;
  private endpoint = new Endpoint();
  // async created() {
  //   let hash = window.location.hash.substr(1);
  //   if (!hash) {
  //     await this.initialize();
  //   } else {
  //     this.authService.handleRedirect(this.setUser);
  //   }
  // }

  async mounted(){
    const hash = window.location.hash.substr(1);
    if (!hash) {
      await this.initialize();
    } else {
      await this.authService.handleRedirect(this.setUser);
    }
    
  }
  async initialize(): Promise<void> {
    try {
      if (this.$route.name === "Login") {
        const userData = this.authService.getTokenData();
        if (userData) {
          await this.setUser(userData);
          window.location.href = "/";
        }
      }
    } catch (error) {
      this.cancelLogin();
    }
  }

  async tryLogin(): Promise<void> {
    try {
      this.authService.loginRedirect();
    } catch (error) {
      this.setUser(null);
      if (error.errorCode === "user_cancelled") {
        sessionStorage.clear();
        localStorage.clear();
      }
    }
  }

  cancelLogin(): void {
    sessionStorage.clear();
    localStorage.clear();
    this.$router.push({ name: "Logout" });
  }
}
