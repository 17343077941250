export default interface SelectApprover {
  approverName: string;
  departmentName: string;
  id: number;
}

export const EmptyApprover: SelectApprover = {
  approverName: "",
  departmentName: "",
  id: 0,
};

export interface Approver {
  approverName: string;
  departmentName: string;
  id: number;
  hasApproved: boolean;
}