














































import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { Watch } from 'vue-property-decorator';
import Endpoint from '@/utils/endpoint';

import { AuthService } from '../../services/authService';
import SolicitationId from '@/@types/solicitationId';
import FeedbackInterface from '@/@types/feedback';

import ErrorResponse from '@/@types/ErrorResponse';

const namespace = 'add';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({})
export default class modalDeny extends Vue {
  @Getter('getSolicitationId', { namespace: 'auth' })
  public solicitation!: SolicitationId;

  @Getter('getModalBankApproval', { namespace: 'auth' })
  public modalBankApproval!: boolean;
  @Action('setModalBankApproval', { namespace: 'auth' })
  public setModalBankApproval!: any;

  @Getter('getFeedback', { namespace: 'auth' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'auth' }) public setFeedback: any;

  @Getter('getUserLanguage', { namespace: 'auth' }) public userLanguage!: any;
  @Action('setUserLanguage', { namespace: 'auth' }) public setUserLanguage: any;

  loading = false;

  private endpoint = new Endpoint();
  authService = new AuthService();

  async bankApprovalSolicitation() {
    const token = await this.authService.getTokenForMenuAsync();

    try {
      this.loading = true;
      const response = await this.$http.put(
        this.endpoint.bankApproval + `/${this.solicitation}`,
        token,
        {
          headers: { Authorization: token },
        }
      );
      this.loading = false;
      this.closeModal();

      this.setFeedback({
        feedback: true,
        color: 'green',
        text:
          this.userLanguage === 'en' ? 'Status Updated' : 'Status Atualizado',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);

      this.$router.push({ path: '/requests' });
    } catch (error) {
      const errorData = error as ErrorResponse;
      this.loading = false;
      console.error(error);
      this.closeModal();

      this.setFeedback({
        feedback: true,
        color: 'red',
        text:
          errorData.response.status === 400
            ? `${errorData.response.data}`
            : 'Ocorreu um erro',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    }
  }

  closeModal(): void {
    this.setModalBankApproval(false);
  }
}
