import Header from '@/@types/header';
export default class ModalError {
  public static header: Header[] = [
    {
      text: 'Row',
      align: 'start',
      sortable: true,
      value: 'position',
      class: 'item-table-header item-table-header-users',
    },
    {
      text: 'Error',
      align: 'start',
      sortable: false,
      value: 'error',
      class: 'item-table-header item-table-header-users',
    },
  ];
}