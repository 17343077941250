var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0",attrs:{"color":"#F3F6F9","height":"100%"}},[_c('div',{staticClass:"d-flex align-center back clickable ml-2 pt-3",on:{"click":_vm.back}},[_c('v-icon',{attrs:{"color":"#013767","size":"18"}},[_vm._v("mdi-chevron-left")]),_c('span',[_vm._v(_vm._s(_vm.$t('newSolicitation.back')))])],1),_c('v-card-title',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',{staticClass:"mb-3"},[(!_vm.isEditFundAdministrator)?_c('span',{staticClass:"companies-title mr-3"},[_vm._v(" "+_vm._s(_vm.$t('newFundAdministrator.newTitle'))+" ")]):_c('span',{staticClass:"companies-title mr-3"},[_vm._v(" "+_vm._s(_vm.$t('newFundAdministrator.editTitle'))+" ")])]),_c('v-card',{staticClass:"elevation-0"},[_c('v-form',{ref:"form"},[_c('v-row',{staticClass:"ml-2"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('div',[_c('label',{staticClass:"field-label",attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t('newFundAdministrator.name'))+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-0",attrs:{"size":"14","color":"#505865","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('newSolicitation.required')))])])],1),_c('v-text-field',{attrs:{"id":"name","outlined":"","dense":"","rules":[
                    _vm.nameFundAdministrator !== '' ||
                      _vm.$t('newSolicitation.required') ],"hide-details":"auto","loading":_vm.loading,"disabled":_vm.loading},model:{value:(_vm.nameFundAdministrator),callback:function ($$v) {_vm.nameFundAdministrator=$$v},expression:"nameFundAdministrator"}})],1)]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6","lg":"3","xl":"3"}},[_c('div',[_c('label',{staticClass:"field-label",attrs:{"for":"shortName"}},[_vm._v(_vm._s(_vm.$t('newFundAdministrator.shortName'))+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-0",attrs:{"size":"14","color":"#505865","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('newSolicitation.required')))])])],1),_c('v-text-field',{staticClass:"mr-4",attrs:{"id":"shortName","outlined":"","dense":"","maxlength":"50","counter":"50","rules":[
                    _vm.shortNameFundAdministrator !== '' ||
                      _vm.$t('newSolicitation.required') ],"hide-details":"auto","loading":_vm.loading,"disabled":_vm.loading},model:{value:(_vm.shortNameFundAdministrator),callback:function ($$v) {_vm.shortNameFundAdministrator=$$v},expression:"shortNameFundAdministrator"}})],1)]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6","lg":"3","xl":"3"}},[_c('div',[_c('label',{staticClass:"field-label",attrs:{"for":"externalAdm"}},[_vm._v(" "+_vm._s(_vm.$t('newFundAdministrator.externalAdm'))+" ")]),_c('v-checkbox',{staticClass:"mt-0",attrs:{"id":"externalAdm","color":"#07457B","hide-details":"auto"},model:{value:(_vm.externalAdm),callback:function ($$v) {_vm.externalAdm=$$v},expression:"externalAdm"}})],1)]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6","lg":"3","xl":"3"}},[_c('div',[_c('label',{staticClass:"field-label",attrs:{"for":"businessUnity"}},[_vm._v(_vm._s(_vm.$t('newFundAdministrator.businessUnity'))+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-0",attrs:{"size":"14","color":"#505865","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('newSolicitation.required')))])])],1),_c('v-text-field',{staticClass:"mr-4",attrs:{"id":"businessUnity","outlined":"","dense":"","maxlength":"50","counter":"50","rules":[
                    _vm.businessUnity !== '' ||
                      _vm.$t('newSolicitation.required') ],"hide-details":"auto","loading":_vm.loading,"disabled":_vm.loading},model:{value:(_vm.businessUnity),callback:function ($$v) {_vm.businessUnity=$$v},expression:"businessUnity"}})],1)])],1),_c('div',[_c('v-row',{staticClass:"ml-2"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('div',[_c('label',{staticClass:"field-label",attrs:{"for":"identifier"}},[_vm._v(_vm._s(_vm.$t('newFundAdministrator.identifier'))+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-0",attrs:{"size":"14","color":"#505865","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('newSolicitation.required')))])])],1),_c('v-text-field',{attrs:{"id":"identifier","outlined":"","dense":"","hide-details":"auto","loading":_vm.loading,"disabled":_vm.loading},model:{value:(_vm.identifierFundAdministrator),callback:function ($$v) {_vm.identifierFundAdministrator=$$v},expression:"identifierFundAdministrator"}})],1)]),_c('v-col',{staticClass:"pt-0 pr-0",attrs:{"cols":"12","md":"6","lg":"3","xl":"3"}},[_c('div',[_c('label',{staticClass:"field-label",attrs:{"for":"contactEmail"}},[_vm._v(" "+_vm._s(_vm.$t('newFundAdministrator.email'))+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-0",attrs:{"size":"14","color":"#505865","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('newSolicitation.required')))])])],1),_c('v-text-field',{staticClass:"mr-4",attrs:{"id":"contactEmail","outlined":"","dense":"","rules":[
                      _vm.emailFundAdministrator !== '' ||
                        _vm.$t('newSolicitation.required') ],"hide-details":"auto","loading":_vm.loading,"disabled":_vm.loading},model:{value:(_vm.emailFundAdministrator),callback:function ($$v) {_vm.emailFundAdministrator=$$v},expression:"emailFundAdministrator"}})],1)]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6","lg":"3","xl":"3"}},[_c('div',[_c('label',{staticClass:"field-label",attrs:{"for":"mailGroupLeste"}},[_vm._v(" "+_vm._s(_vm.$t('newFundAdministrator.mailGroupLeste'))+" ")]),_c('v-text-field',{staticClass:"mr-4",attrs:{"id":"mailGroupLeste","outlined":"","dense":"","hide-details":"auto","loading":_vm.loading,"disabled":_vm.loading},model:{value:(_vm.mailGroupLeste),callback:function ($$v) {_vm.mailGroupLeste=$$v},expression:"mailGroupLeste"}})],1)])],1)],1),_c('v-row',{staticClass:"ml-2"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12","lg":"12","xl":"12"}},[_c('div',[_c('label',{staticClass:"field-label",attrs:{"for":"templatePT"}},[_vm._v(_vm._s(_vm.$t('newFundAdministrator.templatePT'))+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-0",attrs:{"size":"14","color":"#505865","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('newFundAdministrator.requiredTemplate')))])])],1),_c('v-textarea',{staticClass:"mr-4",attrs:{"id":"templatePT","outlined":"","dense":"","hide-details":"auto","rows":_vm.rowNoteFieldPT,"rules":[
                    _vm.templatePTFundAdministrator !== '' ||
                      _vm.templateENFundAdministrator !== '' ||
                      _vm.$t('newFundAdministrator.requiredTemplate') ],"loading":_vm.loading,"disabled":_vm.loading},model:{value:(_vm.templatePTFundAdministrator),callback:function ($$v) {_vm.templatePTFundAdministrator=$$v},expression:"templatePTFundAdministrator"}})],1)])],1),_c('v-row',{staticClass:"ml-2 templateEmailEn mb-5"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12","lg":"12","xl":"12"}},[_c('div',[_c('label',{staticClass:"field-label",attrs:{"for":"templateEN"}},[_vm._v(_vm._s(_vm.$t('newFundAdministrator.templateEN'))+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-0",attrs:{"size":"14","color":"#505865","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('newFundAdministrator.requiredTemplate')))])])],1),_c('v-textarea',{staticClass:"mr-4",attrs:{"id":"templateEN","outlined":"","dense":"","hide-details":"auto","rows":_vm.rowNoteFieldEN,"rules":[
                    _vm.templatePTFundAdministrator !== '' ||
                      _vm.templateENFundAdministrator !== '' ||
                      _vm.$t('newFundAdministrator.requiredTemplate') ],"loading":_vm.loading,"disabled":_vm.loading},model:{value:(_vm.templateENFundAdministrator),callback:function ($$v) {_vm.templateENFundAdministrator=$$v},expression:"templateENFundAdministrator"}})],1)])],1),_c('v-card-actions',{staticClass:"ml-2 my-5"},[_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"action-btn mr-3 px-10",attrs:{"large":"","outlined":"","color":"#BCBCBC"},on:{"click":_vm.toFundAdministrator}},[_vm._v(" "+_vm._s(_vm.$t('newFundAdministrator.cancelFundAdministrator'))+" ")]),_c('v-btn',{staticClass:"action-btn px-12",attrs:{"dark":"","large":"","loading":_vm.loadingData,"color":"#023868"},on:{"click":_vm.setSaveOrSavePutFundAdministrator}},[_vm._v(" "+_vm._s(_vm.$t('newFundAdministrator.saveFundAdministrator'))+" ")])],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }